// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------- WEBSITE ------------------------------------------------------------------

export const WEBSITE_DASHBOARD = '/';
export const WEBSITE_LIVE_CLASS_PAGE = '/liveclass';
export const WEBSITE_WORKSHOP_PAGE = '/workshop';
export const WEBSITE_COURSES_PAGE = '/courses';
export const WEBSITE_COURSE_CATEGORY_PAGE = '/courses/:categoryName';
export const WEBSITE_ABOUT_PAGE = '/about';
export const WEBSITE_FAQ_PAGE = '/faq';
export const WEBSITE_CONTACT_PAGE = '/contact';
export const WEBSITE_PRIVACY_PAGE = '/policy.html';
export const WEBSITE_TERMS_PAGE = '/terms.html';
export const WEBSITE_COURSES_DETAIL_PAGE =
  '/courses/:categoryName/:courseName';
export const WEBSITE_WORKSHOP_DETAIL_PAGE = '/workshop/:workshopDetailId';
export const WEBSITE_BLOG = '/blog';
export const INDIVIDUAL_BLOG = '/blog/:blogId';
export const BLOG_WITH_CATEGORY = '/blog/category/:category';

export const MAKE_WEBSITE_COURSE_DETAIL = (categoryName, courseName) =>
  `/courses/${categoryName}/${courseName}`;
export const MAKE_WEBSITE_COURSE_CATEGORY_PAGE = (categoryName) =>
  `/courses/${categoryName}`;
export const MAKE_WEBSITE_WORKSHOP_DETAIL = (workshopId) =>
  `/workshop/${workshopId}`;
  export const MAKE_INDIVIDUAL_BLOG = (blogId) =>
  `/blog/${blogId}`;
