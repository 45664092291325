import React from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { Box, Text, useBreakpointValue, Image } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';

import sliderBtn from '../assets/dashboard/sliderButton.svg';
import { PlayButton } from 'assets';
import useNavigateToCourseDetail from 'hooks/useNavigateToCourseDetail';

function SmallSlider({ sliderData, isWorkshop, handleOpen }) {
  const sliderCount = useBreakpointValue({ base: 2, md: 3, lg: 5 });
  const [currentSlide, setCurrentSlide] = React.useState(0);
 const navigateTo = useNavigateToCourseDetail();

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: sliderCount,
      spacing: 20,
      initial: 0,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <>
      <Box ref={sliderRef} className="keen-slider">
        {sliderData.map((item, i) => (
          <Box
            className={`keen-slider__slide number-slide${i + 1}`}
            cursor="pointer"
            onClick={() => {
              if (isWorkshop) {
                return handleOpen(item.id);
              }
             return   navigateTo(item.categories_data.identity , item.identity, item.id )
            }}
          >
            <Box h={{ base: '145px', md: '152px' }} borderRadius="8px" pos="relative">
              <Image
                src={
                  item.recommended_thumbnail_image ||
                  item.up_coming_workshop_thumbnail_image
                }
                alt=""
                w="100%"
                h="100%"
                borderRadius="8px"
                objectFit="cover"
                loading='lazy'
              />
              { isWorkshop  && <Image src={PlayButton} alt=""  pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" loading='lazy'/>}
            </Box>
            <Text
              color={
                isWorkshop
                  ? appColors.brandWhite['900']
                  : appColors.brandGrey['900']
              }
              fontSize={fontSizes.sm}
              fontWeight={fontWeights.xl}
              my={1}
              as="h3"
            >
              {item.recommended_short_title || item.identity}
            </Text>
            {isWorkshop && (
              <Text
                color={
                  isWorkshop
                    ? appColors.brandSecondary['100']
                    : appColors.brandGrey['700']
                }
                fontSize={fontSizes.xs}
                fontWeight={fontWeights.sm}
                lineHeight={lineHeights.xs}
              >
                By {item.guru_name}
              </Text>
            )}
           {isWorkshop && <Text
              color={
                isWorkshop
                  ? appColors.brandSecondary['100']
                  : appColors.brandGrey['700']
              }
              fontSize={fontSizes.xs}
              fontWeight={fontWeights.sm}
              lineHeight={lineHeights.xs}
            >
               {/* {item.recommended_short_description || item.start_date} */}
               Held on {item.start_date}
            </Text>}
          </Box>
        ))}
      </Box>
      {/* {sliderBtnVisible && ( */}
        <>
          <Box
            pos="absolute"
            right={{ base: "-1.5em" ,md:"-2.75em"}}
            top={{ base:"52%" ,md:"40%"}}
            zIndex="2"
            cursor="pointer"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            borderRadius="55%"
            backgroundColor={appColors.brandPrimary['50']}
            w={{base: "2em", sm:"2.5em" ,lg:"initial" }}
          >
            <Image
              src={sliderBtn}
              alt="slider-btn"
              opacity={{
                base: currentSlide === sliderData.length - 2 ? '0.4' : '',
                md: currentSlide === sliderData.length - 3 ? '0.4' : '',
                lg: currentSlide === sliderData.length - 5 ? '0.4' : '',
              }}
              w="100%"
              loading='lazy'
            />
          </Box>
          <Box
            pos="absolute"
            left={{ base: "-1.5em" ,md:"-2.75em"}}
            top={{ base:"52%" ,md:"40%"}}
            zIndex="2"
            cursor="pointer"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            borderRadius="55%"
            backgroundColor={appColors.brandPrimary['50']}
            w={{base: "2em", sm:"2.5em" ,lg:"initial" }}
          >
            <Image
              src={sliderBtn}
              alt="slider-btn"
              style={{ transform: 'rotate(180deg)' }}
              opacity={currentSlide === 0 ? '0.4' : ''}
              w="100%"
              loading='lazy'
            />
          </Box>
        </>
      {/* )} */}
    </>
  );
}

export default SmallSlider;
