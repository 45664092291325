import { Box, Link, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import WebRelatedPagesWrapper from "components/WebRelatedPagesWrapper";
import React from "react";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import appColors from "theme/appColors";
// import { useQuery } from 'react-query';
// import { getRequestWeb } from 'api/utils/getRequestWeb';
// import { GET_WEBSITE_ABOUT_FAQ_TERMS } from 'api/urls';
// import { dangerouslySetHtml } from 'components/dangerouslySetHtml';
import { WEBSITE_DASHBOARD } from "navigation/routes";

function Terms() {
  const breadcrumbdata = {
    parent: "Home",
    parentLink: WEBSITE_DASHBOARD,
    currentPage: "Terms & Conditions",
  };
  const pagedata = {
    title: "Terms & Conditions",
  };

  const listItemswithLink = [
    {
      name: "Google Play Services",
      link: "https://policies.google.com/privacy",
    },
    {
      name: " Google Analytics for Firebase",
      link: "https://firebase.google.com/policies/analytics",
    },
    {
      name: "Firebase Crashlytics",
      link: "https://firebase.google.com/support/privacy/",
    },
    {
      name: "Sentry",
      link: "https://sentry.io/privacy/",
    },
    {
      name: "Mixpanel Analytics",
      link: "https://mixpanel.com/legal/privacy-policy/",
    },
  ];

  const orderedList = (listItems) => {
    return (
      <UnorderedList>
        {listItems.map((each) => {
          if (each.link) {
            return (
              <Link href={each.link} isExternal>
                <ListItem>
                  <span style={{ color: "#0060df" }}>{each.name}</span>
                </ListItem>
              </Link>
            );
          } else return <ListItem>{each.name}</ListItem>;
        })}
      </UnorderedList>
    );
  };
  return (
    <WebRelatedPagesWrapper
      breadCrumbContent={breadcrumbdata}
      pageData={pagedata}
    >
      <Box
        py="2em"
        w="fit-content"
        mx="auto"
        maxW={{ md: "70vw", lg: "800px" }}
        px={{ base: "2em", md: "0" }}
      >
        <Box
          fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
          fontWeight={fontWeights.sm}
          lineHeight={{ base: lineHeights.sm, lg: lineHeights.md }}
          color={appColors.brandGrey["700"]}
        >
          <Text my="1em">
            By downloading or using the app, these terms will automatically
            apply to you – you should make sure therefore that you read them
            carefully before using the app. You’re not allowed to copy, or
            modify the app, any part of the app, or our trademarks in any way.
            You’re not allowed to attempt to extract the source code of the app,
            and you also shouldn’t try to translate the app into other
            languages, or make derivative versions. The app itself, and all the
            trade marks, copyright, database rights and other intellectual
            property rights related to it, still belong to Pratibha’s Music
            Academy.
          </Text>
          <Text my="1em">
            Pratibha’s Music Academy is committed to ensuring that the app is as
            useful and efficient as possible. For that reason, we reserve the
            right to make changes to the app or to charge for its services, at
            any time and for any reason. We will never charge you for the app or
            its services without making it very clear to you exactly what you’re
            paying for.
          </Text>
          <Text my="1em">
            The VoxGuru app stores and processes personal data that you have
            provided to us, in order to provide our Service. It’s your
            responsibility to keep your phone and access to the app secure. We
            therefore recommend that you do not jailbreak or root your phone,
            which is the process of removing software restrictions and
            limitations imposed by the official operating system of your device.
            It could make your phone vulnerable to malware/viruses/malicious
            programs, compromise your phone’s security features and it could
            mean that the VoxGuru app won’t work properly or at all.
          </Text>
          <Text my="1em">
            The app does use third party services that declare their own Terms
            and Conditions.
          </Text>
          Link to Terms and Conditions of third party service providers used by
          the app
          {orderedList(listItemswithLink)}
          <Text mt="1em">
            Certain functions of the app will require the app to have an active
            internet connection. If you don’t have access to the required Wi-Fi
            or data bandwidth, Pratibha’s Music Academy does not take
            responsibility for the smooth functioning of the app.
          </Text>
          <Text>
            If you’re using the app outside of an area with Wi-Fi, you should
            remember that your terms of the agreement with your mobile network
            provider will still apply. As a result, you may be charged by your
            mobile provider for the cost of data for the duration of the
            connection while accessing the app, or other third party charges. In
            using the app, you’re accepting responsibility for any such charges,
            including roaming data charges if you use the app outside of your
            home territory (i.e. region or country) without turning off data
            roaming. If you are not the bill payer for the device on which
            you’re using the app, please be aware that we assume that you have
            received permission from the bill payer for using the app.
          </Text>
          <Text my="1em">
            With respect to Pratibha’s Music Academy’s responsibility for your
            use of the app, when you’re using the app, it’s important to bear in
            mind that although we endeavour to ensure that it is updated and
            correct at all times, we do rely on third parties to provide
            information to us so that we can make it available to you.
            Pratibha’s Music Academy accepts no liability for any loss, direct
            or indirect, you experience as a result of relying wholly on this
            functionality of the app.
          </Text>
          <Text my="1em">
            At some point, we may wish to update the app. The app is currently
            available on Android & iOS – the requirements for both systems(and
            for any additional systems we decide to extend the availability of
            the app to) may change, and you’ll need to download the updates if
            you want to keep using the app. Pratibha’s Music Academy does not
            promise that it will always update the app so that it is relevant to
            you and/or works with the Android & iOS version that you have
            installed on your device. However, you promise to always accept
            updates to the application when offered to you, We may also wish to
            stop providing the app, and may terminate use of it at any time
            without giving notice of termination to you. Unless we tell you
            otherwise, upon any termination, (a) the rights and licenses granted
            to you in these terms will end; (b) you must stop using the app, and
            (if needed) delete it from your device.
          </Text>
          {/* <Text my="1em">

With respect to Pratibha P’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Pratibha P accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
</Text>
 
<Text my="1em">

At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for both systems(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Pratibha P does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
</Text> */}
          <Text my="1em" fontWeight={fontWeights.xl}>
            Product and Service Description
          </Text>
          <Text my="1em">
            VoxGuru offers both self paced and live training in music for
            various genres across Carnatic and Voice Culture. Courses are
            accessible via in-app purchases or direct enrolments. Fee structure
            varies based on the course, level of experience and region.
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Pricing Policy
          </Text>
          <Text my="1em">
            We follow a customized pricing based on the student's course, region
            and level.
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Refund policy
          </Text>
          <Text my="1em">
            We do not offer refunds on payments made via the app or direct
            links. In case of technical issues at our end, we shall handle the
            same on a case to case basis.
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Cancel & Return Policy
          </Text>
          <Text my="1em">
            We do not offer cancellation or return of the courses subscribed to
            via the app our direct links.
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Delivery Policy
          </Text>
          <Text my="1em">
            Our services are delivered instantly on the app upon making payment.
            In case of live classes, the delivery will be based on the scheduled
            date of the class.
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Changes to This Terms and Conditions
          </Text>
          <Text my="1em">
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page.
          </Text>
          <Text my="1em">
            These terms and conditions are effective as of 2022-10-01
          </Text>
          <Text my="1em" fontWeight={fontWeights.xl}>
            Contact Us
          </Text>
          <Text my="1em">
            If you have any questions or suggestions about our Terms and
            Conditions, do not hesitate to contact us at support@voxguru.in.
          </Text>
        </Box>
      </Box>
    </WebRelatedPagesWrapper>
  );
}

export default Terms;
