import { VStack, Text, Box, Image, GridItem, Grid } from "@chakra-ui/react";
import { GET_WEBSITE_ABOUT_FAQ_TERMS } from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
import AppLayout from "components/AppLayout";
import BreadCrumb from "components/BreadCrumb";
import { dangerouslySetHtml } from "components/dangerouslySetHtml";
import { WEBSITE_DASHBOARD } from "navigation/routes";
import React from "react";
import { useQuery } from "react-query";
import appColors from "theme/appColors";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";

function About() {
  const { data } = useQuery(GET_WEBSITE_ABOUT_FAQ_TERMS, () =>
    getRequestWeb(GET_WEBSITE_ABOUT_FAQ_TERMS)
  );
  const pageData = data?.data?.data.about_details;

  return (
    <AppLayout>
      {data && (
        <>
          {" "}
          <VStack
            h={{ base: "231px", md: "430px" }}
            pos="relative"
            // background="linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4D313D"
            backgroundColor="#000"
          >
            <BreadCrumb
              parent="Home"
              parentLink={WEBSITE_DASHBOARD}
              currentPage="About"
              textColor={appColors.brandSecondary["100"]}
            />
            <Image
              src={pageData.cover_image}
              alt=""
              w="100vw"
              h="100%"
              objectFit="cover"
              pos="relative"
              bottom="8px"
              opacity="0.45"
              loading="lazy"
            />
            <Text
              pos="absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
              variant="headerThree"
              textAlign="center"
              w={{ base: "80vw", md: "60vw" }}
            >
              {pageData.identity}
            </Text>
          </VStack>
          <Box
            mx="auto"
            maxW={{ md: "70vw", lg: "886px" }}
            px={{ base: "2em", md: "0" }}
            as="main"
            py="3em"
          >
            <Text
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.sm}
              fontSize="23px"
              lineHeight="32px"
            >
              {pageData.short_description}
            </Text>
            <Text
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.xl}
              fontSize={{ base: "23px", md: fontSizes.xl }}
              pt="2em"
              pb="0.5em"
            >
              The beginnings
            </Text>
            <Text
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.sm}
              fontSize={fontSizes.sm}
              lineHeight={lineHeights.sm}
              dangerouslySetInnerHTML={dangerouslySetHtml(pageData.description)}
            />
            {/* {pageData.description}
            </Text> */}
            <Grid
              display="grid"
              gridTemplateColumns="0.8fr 1.2fr"
              gap="1em"
              pt="2em"
            >
              <GridItem h={{ base: "120px", md: "315px" }}>
                <Image
                  src={pageData.thumbnail_image1}
                  alt=""
                  objectFit="cover"
                  h="100%"
                  w="100%"
                  loading="lazy"
                />
              </GridItem>
              <GridItem h={{ base: "120px", md: "315px" }}>
                <Image
                  src={pageData.thumbnail_image2}
                  alt=""
                  objectFit="cover"
                  h="100%"
                  w="100%"
                  loading="lazy"
                />
              </GridItem>
            </Grid>
            <Grid
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gap="1em"
              pt="1em"
              pb="2em"
            >
              <GridItem h={{ base: "120px", md: "315px" }}>
                <Image
                  src={pageData.thumbnail_image3}
                  alt=""
                  objectFit="cover"
                  h="100%"
                  w="100%"
                  loading="lazy"
                />
              </GridItem>
              <GridItem h={{ base: "120px", md: "315px" }}>
                <Image
                  src={pageData.thumbnail_image4}
                  alt=""
                  objectFit="cover"
                  h="100%"
                  w="100%"
                  loading="lazy"
                />
              </GridItem>
              <GridItem h={{ base: "120px", md: "315px" }}>
                <Image
                  src={pageData.thumbnail_image5}
                  alt=""
                  objectFit="cover"
                  h="100%"
                  w="100%"
                  loading="lazy"
                />
              </GridItem>
            </Grid>
          </Box>
        </>
      )}
    </AppLayout>
  );
}

export default About;
