import React from 'react';
import { Flex, Link, Image, Box, useBreakpointValue } from '@chakra-ui/react';
import { APPLE_STORE_LINK, PLAY_STORE_LINK } from 'api/urls/URL';
import useGetCurrentUserOs from 'hooks/useGetCurrentUserOs';
import playstoreicon from '../assets/playstore.svg';
import applestoreicon from '../assets/applestore.svg';

function StoreComponent() {
  const { currentUserOS } = useGetCurrentUserOs();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <>
      {!isDesktop && (
        <Flex
          alignItems="center"
          justifyContent={{ base: 'center', sm: 'start', lg: 'start' }}
          gap="1em"
          my="1em"
        >
          {currentUserOS === 'apple' ? (
            <Box>
              <Link href={APPLE_STORE_LINK}>
                <Image
                  src={applestoreicon}
                  alt=""
                  backgroundColor="white"
                  borderRadius="6px"
                  loading='lazy'
                />
              </Link>
            </Box>
          ) : (
            <Box>
              <Link href={PLAY_STORE_LINK}>
                <Image
                  src={playstoreicon}
                  alt=""
                  backgroundColor="white"
                  borderRadius="6px"
                  loading='lazy'
                />
              </Link>
            </Box>
          )}
        </Flex>
      )}
      <Flex
        alignItems="center"
        justifyContent={{ base: 'center', sm: 'start', lg: 'start' }}
        gap="1em"
        my="1em"
      >
        {isDesktop && (
          <>
            <Box>
              <Link
                href={PLAY_STORE_LINK}
                isExternal
                _focus={{ outline: 'none' }}
              >
                <Image
                  src={playstoreicon}
                  alt=""
                  backgroundColor="white"
                  borderRadius="6px"
                  loading='lazy'
                />
              </Link>
            </Box>
            <Box>
              <Link
                href={APPLE_STORE_LINK}
                isExternal
                _focus={{ outline: 'none' }}
              >
                <Image
                  src={applestoreicon}
                  alt=""
                  backgroundColor="white"
                  borderRadius="6px"
                  loading='lazy'
                />
              </Link>
            </Box>
          </>
        )}
      </Flex>
    </>
  );
}
export default StoreComponent;
