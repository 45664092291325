import { IS_WEBSITE } from 'utils/constants';

const getHostAPIUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production')
    return 'https://api-v2.voxguru.in';


  return 'https://api-staging-v2.voxguru.in';
};


const isWebsite = () => process.env.REACT_APP_USER_ENVIRONMENT === IS_WEBSITE;

export { getHostAPIUrl, isWebsite };
