import React from 'react';
import appColors from 'theme/appColors';
import { Box, Image, Text } from '@chakra-ui/react';
import AppBox from 'components/chakraLayoutOverrides/AppBox';
import { fontSizes } from 'theme/fonts';
import thehindu from '../assets/dashboard/the-hindu.png';
import AppFlex from './chakraLayoutOverrides/AppFlex';
import indianexp from '../assets/dashboard/indian-express.png';
import toi from '../assets/dashboard/times-of-india.png';
import yourstory from '../assets/dashboard/your-story.png';

function FeaturedOn() {
  const featuredOn = [thehindu, indianexp, toi, yourstory];

  return (
    <AppBox
      customStyles={{
        my: '4em',
      }}
    >
      <Text
        textAlign="center"
        mb="2.5em"
        pos="relative"
        w="10em"
        mx="auto"
        fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
        fontWeight="800"
        color={appColors.brandGrey['400']}
        _before={{
          content: '""',
          w: { base: '4em', md: '11em', lg: '15em' },
          h: '0.1em',
          bg: appColors.brandGrey['100'],
          pos: 'absolute',
          left: { base: '-4em', md: '-11em', lg: '-15em' },
          top: '0.7em',
        }}
        _after={{
          content: '""',
          w: { base: '4em', md: '11em', lg: '15em' },
          h: '0.1em',
          bg: appColors.brandGrey['100'],
          pos: 'absolute',
          right: { base: '-4em', md: '-11em', lg: '-15em' },
          top: '0.7em',
        }}
      >
        AS FEATURED ON
      </Text>
      <AppFlex
        customStyles={{
          flexWrap: { base: ' wrap', sm: 'no-wrap' },
          justifyContent: 'space-evenly',
          alignItems: 'center',
          gap: { base: '1em', lg: '1.5em' },
          px: { base: '1em', lg: '4em' },
          pb: { base: '1em', lg: '4em' },
          h: { base: ' 10em', md: 'auto' },
        }}
      >
        {featuredOn.map((each) => (
          <Box>
            <Image
              src={each}
              alt=""
              maxW="205px"
              minW="120px"
              w={{ base: '100px', md: '150px', lg: '200px' }}
              loading="lazy"
            />
          </Box>
        ))}
      </AppFlex>
    </AppBox>
  );
}
export default FeaturedOn;
