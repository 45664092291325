import React, { createRef } from "react";
import {
  Box,
  Image,
  Text,
  Button,
  VStack,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import AppLayout from "components/AppLayout";
import AppBox from "components/chakraLayoutOverrides/AppBox";
import AppText from "components/Typography/AppText";
import AppFlex from "components/chakraLayoutOverrides/AppFlex";
import appColors from "theme/appColors";
import { fontSizes, fontWeights } from "theme/fonts";
import AppTestimonial from "components/AppTestimonial";
import FeaturedOn from "components/FeaturedOn";
import SmallSlider from "components/Slider";
import HeadSliderHome from "components/headSlider";
import { getRequestWeb } from "api/utils/getRequestWeb";
import { GET_SLIDER_VIDEOS, GET_WEBSITE_COURSES_LIST } from "api/urls";
import { useQuery } from "react-query";
import StoreComponent from "components/storeComponent";
import { Link } from "react-router-dom";
import {
  WEBSITE_COURSES_PAGE,
  WEBSITE_LIVE_CLASS_PAGE,
  WEBSITE_WORKSHOP_PAGE,
} from "navigation/routes";
import AppLoader from "components/AppLoader/AppLoader";
import {
  headerTopBg,
  headerBottomBg,
  voxproBottomBg,
  voxproTopBg,
  voxproBg,
  liveguruMobileBg,
  liveguruBg1,
  liveguruBg2,
  purpleArrow,
  WhiteArrow,
  playBtn,
} from "assets/index";
import MetaData from "components/MetaData";
import SocialLink from "components/SocialLink";
import { attributessection } from "./courseData";

function Dashboardhome() {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");

  const [playingVideo, setPlayingVideo] = React.useState({
    id: null,
    isPlaying: false,
  });

  const { data, isLoading } = useQuery(GET_WEBSITE_COURSES_LIST, () =>
    getRequestWeb(GET_WEBSITE_COURSES_LIST)
  );
  const { data: homeData } = useQuery(GET_SLIDER_VIDEOS, () =>
    getRequestWeb(GET_SLIDER_VIDEOS)
  );

  const sliderData = data?.data?.data.courses_data;
  const videoSliderData = homeData?.data?.data.footer_videos;

  const refVals = videoSliderData?.map(() => createRef());

  const videoSlider = videoSliderData
    ? videoSliderData.map((each, i) => ({
        ...each,
        ref: refVals[i],
      }))
    : [];

  // eslint-disable-next-line no-nested-ternary
  const sliderCount = isLargerThan1280 ? 4 : isLargerThan450 ? 2.5 : 1.23;
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: sliderCount,
      spacing: 15,
      initial: 0,
    },
  });

  // function that handle play/pause of the videos in slider
  const handleVideo = (clickedid) => {
    let currentRef;
    videoSlider.map((item) => {
      if (clickedid === item.id) {
        currentRef = item.ref;
      }
      return null;
    });

    if (playingVideo.id === clickedid && playingVideo.isPlaying) {
      currentRef.current.pause();
      currentRef.current.removeAttribute("controls");
      setPlayingVideo({ id: null, isPlaying: false });
    } else if (playingVideo.isPlaying && playingVideo.id !== clickedid) {
      videoSlider.map((item) => {
        if (item.id === playingVideo.id) {
          item.ref.current.pause();
          item.ref.current.removeAttribute("controls");
        }
        return null;
      });
      currentRef.current.play();
      currentRef.current.setAttribute("controls", "");
      setPlayingVideo({ id: clickedid, isPlaying: true });
    } else {
      currentRef.current.play();
      currentRef.current.setAttribute("controls", "");
      setPlayingVideo({ id: clickedid, isPlaying: true });
    }
  };

  return (
    <AppLayout>
      <MetaData
        metaTitle="VoxGuru - Learn Carnatic Music & Voice Culture "
        metaDescription="Master your singing from the comfort of your home with our world class curriculum."
      />
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <>
            <Box
              as="header"
              bg={appColors.brandPrimary["50"]}
              py={8}
              backgroundImage={`url(${headerTopBg}), url(${headerBottomBg})`}
              backgroundRepeat="no-repeat, no-repeat"
              backgroundSize={{
                base: "700px , 370px",
                md: "1000px , 460px",
                lg: "1000px , 650px",
              }}
              backgroundPosition={{
                base: "top -50vw left -120px, bottom 25% right -100px",
                sm: "top -25vw left -120px, bottom 35% right -70px",
                md: "top -23vw left -190px, bottom 50% right -70px",
                lg: "top -10vw left -160px, bottom 90% right -250px",
              }}
            >
              <VStack
                w={{ base: "80%", md: "initial" }}
                mx="auto"
                pos="relative"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  w="60%"
                  my={{ base: "1em", md: "2em", lg: "4em" }}
                >
                  <Text
                    as="h1"
                    textAlign="center"
                    m="0"
                    minW={{ base: "70vw", md: "515" }}
                    mb={4}
                    variant="headerOne"
                  >
                    Your trusted companion and mentor to master Indian Classical
                    Music.
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent={{ base: "center", lg: "start" }}
                    gap="1em"
                    my="1em"
                  >
                    <StoreComponent />
                  </Flex>
                </Flex>
                <Box w={{ base: "90vw", md: "100vw" }}>
                  {homeData?.data?.data?.carousel_images.length >= 1 && (
                    <HeadSliderHome
                      sliderData={homeData.data.data.carousel_images}
                    />
                  )}
                </Box>
              </VStack>
            </Box>

            {/* --- attributes section -------- */}
            <AppFlex
              customStyles={{
                flexDirection: { base: "column", md: "row" },
                justifyContent: "center",
                gap: "1.5em",
                px: { base: "2em", md: "3em" },
                textAlign: "center",
                py: "3em",
                mt: "2em",
              }}
            >
              {attributessection.map((item) => (
                <Link to={item.link}>
                  <AppBox
                    customStyles={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexBasis: "30%",
                      cursor: "pointer",
                      borderRadius: "1em",
                      paddingBottom: "1em",
                    }}
                  >
                    <AppBox
                      customStyles={{
                        display: "grid",
                        placeContent: "center",
                        mb: "0.5em",
                      }}
                    >
                      <Image
                        src={item.img}
                        w={{ base: "100px", md: "170px" }}
                        alt={`${item.title} image`}
                        loading="lazy"
                      />
                    </AppBox>
                    <Box>
                      <AppText
                        customStyles={{
                          color: appColors.brandPrimary["300"],
                          fontSize: fontSizes.xxl,
                          fontWeight: fontWeights.bold,
                        }}
                      >
                        {item.title}
                      </AppText>
                      <AppText
                        customStyles={{
                          my: "0.5em",
                          px: "2em",
                          variant: "descriptionText",
                        }}
                      >
                        {item.description}
                      </AppText>
                      <AppFlex
                        customStyles={{
                          color: appColors.brandSecondary["400"],
                          fontWeight: fontWeights.bold,
                          fontSize: { base: fontSizes.sm, lg: fontSizes.md },
                          width: "fit-content",
                          mx: "auto",
                          cursor: "pointer",
                          mt: "1em",
                          gap: "1em",
                        }}
                      >
                        {item.linkText}
                        <Image
                          src={purpleArrow}
                          alt="arrow"
                          w={{ base: "0.4em", md: "0.45em" }}
                          loading="lazy"
                        />
                      </AppFlex>
                    </Box>
                  </AppBox>
                </Link>
              ))}
            </AppFlex>

            {/* {--------- slider section -------} */}

            <AppBox>
              <VStack
                textAlign="start"
                alignItems="start"
                justifyContent="flex-start"
                w="80%"
                mx="auto"
                mb="4em"
                mt={{ base: "0", md: "4em" }}
                gap={3}
                pos="relative"
              >
                <Text variant="smallHeader">
                  {/* COURSES */}
                  SELF LEARNING
                </Text>
                <Text as="h2" textAlign="start" variant="subHeader" my="0.6em">
                  Learn 300+ hrs of content, at your own pace.
                </Text>
                <Text variant="descriptionText">
                  Explore an extensive catalogue of self-learning courses
                  covering the fundamentals to advanced vocal techniques in a
                  uniqely visual narrative.
                </Text>
                {data && <SmallSlider sliderData={sliderData} />}
                <Link to={WEBSITE_COURSES_PAGE}>
                  <Button
                    mt={{ base: "0", md: "1em" }}
                    variant="exploreButton"
                    style={{
                      display: "flex",
                      gap: "0.8em",
                      alignItems: "center",
                    }}
                    _focus={{ outline: "none", textDecoration: "none" }}
                    p={{ base: "1em", md: "1.5em" }}
                  >
                    Explore All Courses
                    <Image
                      src={purpleArrow}
                      alt="arrow"
                      w={{ base: "0.4em", md: "0.45em" }}
                      loading="lazy"
                    />
                  </Button>
                </Link>
              </VStack>
            </AppBox>

            {/* --- testimonial , featured on ------ */}
            {homeData && <AppTestimonial testimonialData={homeData} />}
            <FeaturedOn />

            {/* ---- live class sec ---- */}
            <AppBox
              customStyles={{
                backgroundColor: appColors.brandPrimary["50"],
                height: { base: "515px", lg: "750px" },
                display: "flex",
                justifyContent: "center",
                alignItems: { base: "start", lg: "center" },
                backgroundImage: {
                  base: `url(${liveguruMobileBg})`,
                  md: `url(${liveguruBg1}) , url(${liveguruBg2})`,
                },
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: {
                  base: "bottom -217px right -50px",
                  md: "bottom 0px left -100px , bottom -100px right -150px",
                  lg: "top -180px left -230px , bottom  -200px right -290px",
                },
                backgroundSize: { md: "400px, 450px", lg: "50vw, 900px" },
                pt: { base: "1em", md: "0em" },
              }}
            >
              <VStack
                p={{ base: "2em 0.5em", md: "4em 1em" }}
                w={{ base: "90%", md: "70%", lg: "50%" }}
                mx="auto"
                gap={{ base: "0.5em", md: "0.5em" }}
              >
                <Text
                  textAlign="center"
                  w="10em"
                  mx="auto"
                  variant="smallHeader"
                >
                  LIVE CLASSES
                </Text>
                <Text
                  as="h2"
                  textAlign="center"
                  variant="subHeader"
                  mt="0"
                  w={{ base: "100%" }}
                >
                  Get a personal coach and learn from anywhere in the world.
                </Text>
                <Text
                  textAlign="center"
                  variant="descriptionText"
                  w={{ base: "95%", sm: "60%", md: "78%" }}
                >
                  Explore an extensive catalogue of self-learning courses
                  covering the fundamentals to advanced vocal techniques in a
                  uniqely visual narrative.
                </Text>
                <Link
                  to={WEBSITE_LIVE_CLASS_PAGE}
                  w="fit-content"
                  _focus={{ outline: "none" }}
                  _hover={{ textDecoration: "none" }}
                >
                  <Button
                    variant="defaultButton"
                    display="flex"
                    mx="auto"
                    p={{ base: "1.1em 1em", lg: "1.5em 1.85em" }}
                    fontWeight={fontWeights.xl}
                    fontSize={fontSizes.md}
                  >
                    Book a live class
                  </Button>
                </Link>
              </VStack>
            </AppBox>

            {/* ----workshop --- */}
            <AppBox
              customStyles={{
                backgroundImage: {
                  base: `url(${voxproBg})`,
                  lg: `url(${voxproTopBg}), url(${voxproBottomBg})`,
                },
                backgroundRepeat: "no-repeat , no-repeat",

                backgroundPosition: {
                  base: "bottom -85px left -70px",
                  md: "top 0 left 0",
                  lg: "left -30px top -50px , bottom -70px right -90px",
                },
                backgroundSize: {
                  base: "365px",
                  sm: "425px",
                  lg: "45vw, 30vw",
                },
                pt: "1em",
                h: { base: "500px", md: "430px", lg: "600px" },
              }}
            >
              <VStack
                p={{ base: "1em", lg: "2em 1em" }}
                w={{ base: "90%", lg: "80%" }}
                mx="auto"
                gap="0.5em"
                h="500px"
                alignItems="end"
                justifyContent={{ lg: "center" }}
              >
                <Text variant="smallHeader" textAlign="end">
                  WORKSHOPS
                </Text>
                <Text textAlign="end" variant="subHeader" as="h2">
                  Get up-close with the masters
                </Text>
                <Text
                  textAlign="end"
                  variant="descriptionText"
                  w={{ base: "80%", md: "50%" }}
                >
                  Explore an extensive catalogue of self-learning courses
                  covering the fundamentals to advanced vocal techniques in a
                  uniqely visual narrative.
                </Text>
                <Box w="100%" display="flex" justifyContent="flex-end">
                  <Link
                    to={WEBSITE_WORKSHOP_PAGE}
                    w="fit-content"
                    _focus={{ outline: "none" }}
                    _hover={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="defaultButton"
                      display="flex"
                      alignItems="center"
                      gap="0.8em"
                      p={{ base: "1em 0.7em", lg: "1.5em 1.85em" }}
                      fontWeight={fontWeights.xl}
                      fontSize={fontSizes.md}
                    >
                      Upcoming workshops
                      <Image src={WhiteArrow} alt="arrow" loading="lazy" />
                    </Button>
                  </Link>
                </Box>
              </VStack>
            </AppBox>
            {/* ------- video slides section --------- */}
            <AppBox
              customStyles={{
                my: "2em",
                pt: "3em",
              }}
            >
              <VStack w="90%" mx="auto">
                <AppText
                  customStyles={{
                    variant: "subHeader",
                    as: "h2",
                  }}
                >
                  Join the tribe!
                </AppText>
                <AppText
                  customStyles={{
                    variant: "descriptionText",
                    w: "80%",
                    textAlign: "center",
                  }}
                >
                  Explore an extensive catalogue of self-learning courses
                  covering the fundamentals to advanced vocal techniques in a
                  uniqely visual narrative.{" "}
                </AppText>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  gap="1em"
                  pb="2em"
                  pt="0.7em"
                >
                  <SocialLink />
                </Flex>
                {homeData && (
                  <Box ref={sliderRef} className="keen-slider">
                    {videoSlider.map((item, i) => (
                      <Box
                        className={`keen-slider__slide number-slide${i + 1}`}
                      >
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                          width="350"
                          height="564"
                          preload="metadata"
                          style={{ borderRadius: "8px" }}
                          ref={item.ref}
                          onClick={(e) => {
                            e.preventDefault();
                            handleVideo(item.id);
                          }}
                        >
                          <source
                            src={`${item.video}#t=0.001`}
                            type="video/mp4"
                          />
                        </video>
                        {playingVideo.id !== item.id && (
                          <Image
                            src={playBtn}
                            alt="play button"
                            w={{ base: "2.5em", md: "4em" }}
                            h={{ base: "2.5em", md: "4em" }}
                            pos="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            cursor="pointer"
                            onClick={() => handleVideo(item.id)}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </VStack>
            </AppBox>
          </>
        )}
      </AppLoader>
    </AppLayout>
  );
}

export default Dashboardhome;
