import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Image,
  Input,
  Button,
  FormControl,
  FormLabel,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';
import { useMutation } from 'react-query';
import { postRequestWeb } from 'api/utils/postRequestWeb';
import { POST_ENROLLMENT_DETAIL } from 'api/urls';
import linkicon from '../assets/dashboard/external-link-icon.svg';
// import AppButton from './Typography/AppButton';
import WebButton from './WebButton';
import useNavigateToCourseDetail from 'hooks/useNavigateToCourseDetail';
import useCountry from 'hooks/useCountry';
import { getPrice } from 'utils/helperFunctions';

function CoursePageModal({
  isOpen,
  onClose,
  isPayment,
  setisPayment,
  bundleDetails,
  categoryName,
  bundleDescription,
  currentCourseImage,
  courseprice,
  // isMobile,
}) {
  const modalSize = useBreakpointValue({ base: 'full', md: 'xl' });
  const paymentSize = useBreakpointValue({ base: 'xl' });
  
 const navigateTo = useNavigateToCourseDetail();
 const {isIndia} = useCountry();

  const [values, setValues] = React.useState({
    identity:"",
    email: "",
    mobilenumber: "",
  })

  const { mutate } = useMutation(
    POST_ENROLLMENT_DETAIL,
    (body) => postRequestWeb(POST_ENROLLMENT_DETAIL, body),{
      onError:(data)=>{
        console.log(data)
      }
    }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const postObj = {
    identity: values.identity,
    email: values.email,
    mobile_number: `+91${values.mobilenumber}`,
  }


  return (
    <>
      {/*  --- modal that shows bundle course and for payment -------- */}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setisPayment(false);
        }}
        size={isPayment ? paymentSize : modalSize}
        isLazy
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb="0">
            {isPayment ? (
              <Flex alignItems="center" gap="0.5em">
                <Image
                  src={currentCourseImage}
                  alt=""
                  w="30px"
                  h="30px"
                  borderRadius="4px"
                  objectFit="cover"
                  loading="lazy"
                />
                <Text
                  fontWeight="600"
                  fontSize={fontSizes.xs}
                  color={appColors.brandGrey['900']}
                >
                  {bundleDetails?.courses_data.identity}
                </Text>
              </Flex>
            ) : (
              <>
                <Text
                  fontWeight={fontWeights.xl}
                  fontSize={{ base: '23px', lg: fontSizes.xl }}
                  color={appColors.brandGrey['700']}
                  mb="0.4em"
                  as="h2"
                >
                  {bundleDetails?.bundle_name.identity}
                </Text>
                <Text
                  fontWeight={fontWeights.xl}
                  fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
                  color={appColors.brandPrimary['300']}
                >
                  {bundleDescription}
                </Text>{' '}
              </>
            )}
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: 'none' }} />
          <ModalBody>
            {isPayment ? (
              <Box pt="1.5em">
                <Box w={{ md: '60%' }} mx="auto">
                  <Text
                    fontWeight={fontWeights.xl}
                    fontSize={{ base: '23px', lg: fontSizes.xl }}
                    color={appColors.brandGrey['700']}
                    as="h2"
                  >
                    Enrollment details
                  </Text>
                  <Text
                    fontWeight={fontWeights.sm}
                    fontSize="11px"
                    color={appColors.brandGrey['700']}
                    lineHeight={lineHeights.xxs}
                    my="1em"
                  >
                    We will be creating an account based on the details here so
                    that you can access the course on the VoxGuru Mobile app. If
                    you are enrolling on behalf of a minor, you may give your
                    own phone number and email below.
                  </Text>
                  <FormControl>
                    <FormLabel
                      fontWeight={fontWeights.sm}
                      fontSize={fontSizes.xs}
                      color={appColors.brandGrey['700']}
                    >
                      Name
                    </FormLabel>
                    <Input
                      type="text"
                      border={`1px solid ${appColors.brandSecondary['200']}`}
                      onChange={handleInputChange}
                      value={values.identity}
                      name="identity"
                      label="identity"
                    />
                    <FormLabel
                      fontWeight={fontWeights.sm}
                      fontSize={fontSizes.xs}
                      color={appColors.brandGrey['700']}
                    >
                      Email
                    </FormLabel>

                    <Input
                      type="email"
                      border={`1px solid ${appColors.brandSecondary['200']}`}
                      onChange={handleInputChange}
                      value={values.email}
                      name="email"
                      label="email"
                    />
                    <FormLabel
                      fontWeight={fontWeights.sm}
                      fontSize={fontSizes.xs}
                      color={appColors.brandGrey['700']}
                    >
                      Mobile number
                    </FormLabel>

                    <Input
                      type="number"
                      border={`1px solid ${appColors.brandSecondary['200']}`}
                      onChange={handleInputChange}
                      value={values.mobilenumber}
                      name="mobilenumber"
                      label="mobilenumber"
                    />
                    <Button
                      variant="defaultButton"
                      w="100%"
                      my="1.5em"
                      p="1.25em"
                      borderRadius="12px"
                      color="white"
                      fontWeight={fontWeights.xl}
                      fontSize={fontSizes.md}
                      onClick={()=>mutate(postObj)}
                    >
                    Pay ₹{courseprice} / year
                    </Button>
                  </FormControl>
                </Box>
              </Box>
            ) : (
              <Box>
                {bundleDetails?.bundled_courses_data.courses_in_bundle_collection.map(
                  (each) => (
                    <Flex
                      gap="1em"
                      borderTop="1px solid rgba(0, 0, 0, 0.1)"
                      py="0.8em"
                    >
                      <Image
                        src={each.course.image_carousel_thumbnail_image}
                        alt=""
                        w={{ base: '98px', lg: '69px' }}
                        mt="0.5em"
                        h={{ base: '98px', lg: '69px' }}
                        objectFit="cover"
                        borderRadius="6px"
                        loading="lazy"
                      />
                      <Box flexBasis="80%" w="20vw">
                        <Text
                          as="h3"
                          fontWeight={{
                            base: fontWeights.semibold,
                            lg: fontWeights.xl,
                          }}
                          fontSize={{
                            base: fontSizes.sm,
                            lg: fontSizes.md,
                          }}
                          color={appColors.brandGrey['900']}
                        >
                          {each.course.identity}
                        </Text>
                        <Text
                          color={appColors.brandGrey['700']}
                          fontWeight={fontWeights.sm}
                          fontSize={{
                            base: fontSizes.xs,
                            lg: fontSizes.sm,
                          }}
                        >
                          {each.course.description}
                        </Text>
                        <Flex alignItems="center" gap="0.35em">
                          <Text
                            onClick={() => {
                              onClose();
                              navigateTo(categoryName, each.course.identity, each.course.id )
                            }}
                            color={appColors.brandSecondary['400']}
                            cursor="pointer"
                            fontWeight={fontWeights.xl}
                            fontSize={fontSizes.xs}
                          >
                            View Course Details{' '}
                          </Text>
                          <Image src={linkicon} alt="" w="13px" loading="lazy" />
                        </Flex>
                      </Box>
                    </Flex>
                  ),
                )}
              </Box>
            )}
          </ModalBody>
          {!isPayment && (
            <Flex
              px="2em"
              py="1em"
              justifyContent="space-between"
              alignItems="center"
              boxShadow="inset 0px 1px 0px #D9D9D9"
              position="sticky"
              bottom="0"
              bg="white"
              borderRadius="inherit"
            >
              <Box>
                <Flex gap="0.5em" alignItems="center">
                  <Text
                    color={appColors.brandGrey['500']}
                    fontWeight={fontWeights.sm}
                    fontSize={fontSizes.sm}
                  >
                    <s>{getPrice(isIndia, bundleDetails.bundled_courses_data.actual_price, "")}</s>
                  </Text>
                  <Text
                    fontWeight={fontWeights.sm}
                    fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                    color={appColors.brandGrey['700']}
                  >
                    {getPrice(isIndia,bundleDetails.bundled_courses_data.discount_price, " / year" )}
                  </Text>
                </Flex>
              </Box>
              {/* <AppButton
                variant="defaultButton"
                fontWeight={fontWeights.bold}
                fontSize={fontSizes.sm}
              >
                {isMobile ?  "Chat with us" : "Talk to us"  }
              </AppButton>{' '} */}
              <WebButton />
            </Flex>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
export default CoursePageModal;
