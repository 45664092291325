import React, { useState, useRef, useEffect, useMemo } from "react";

import AppLayout from "components/AppLayout";
import AppTestimonial from "components/AppTestimonial";
import FeaturedOn from "components/FeaturedOn";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  Image,
  useMediaQuery,
  useBreakpointValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  VStack,
  CloseButton,
  // Link,
} from "@chakra-ui/react";
import { fontWeights, lineHeights, fontSizes } from "theme/fonts";
import appColors from "theme/appColors";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import StoreComponent from "components/storeComponent";
import {
  GET_WEBSITE_COURSES_LIST,
  GET_WEBSITE_COURSES_CATEGORY_LIST,
} from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "components/BreadCrumb";
import AppLoader from "components/AppLoader/AppLoader";
import {
  MAKE_WEBSITE_COURSE_CATEGORY_PAGE,
  WEBSITE_COURSES_PAGE,
  WEBSITE_DASHBOARD,
} from "navigation/routes";
import CoursePageCard from "components/CoursePageCard";
import { useKeenSlider } from "keen-slider/react";
import { headerData } from "./courseData";
import leftButton from "../../assets/dashboard/slider-arrow-left.svg";
// import rightButton from '../../assets/dashboard/sliderButton.svg';
import tabletBg from "../../assets/dashboard/tablet-slider-bg.svg";
import mobileImage from "../../assets/dashboard/landscape-mobile.svg";
// import desktopHeaderBg from '../../assets/dashboard/course-header-desktop-bg.svg';
import desktopHeaderBg from "../../assets/dashboard/courses-desktop-bg.svg";
import "keen-slider/keen-slider.min.css";
import mobileBg from "../../assets/dashboard/courses-mobile-first-bg.svg";
import mobileSecondBg from "../../assets/dashboard/course-mobile-second-bg.svg";
import confidenceTrackingImg from "../../assets/dashboard/Confidence tracking.jpg";
import ModularcontentImg from "../../assets/dashboard/Modular content__.jpg";
import PractiseModulesImg from "../../assets/dashboard/Practice modules_.png";
import visulaLearningImg from "../../assets/dashboard/Visual learning_.jpg";
import { PlayStoreIcon, AppleIcon } from "assets";
import useGetCurrentUserOs from "hooks/useGetCurrentUserOs";
import MetaData from "components/MetaData";
import { APPLE_STORE_LINK, PLAY_STORE_LINK } from "api/urls/URL";

function Courses() {
  const [currentCategory, setCurrentCategory] = useState("All Courses");
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [secondPage, setSecondPage] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isTabOverflow] = useMediaQuery("(min-width: 1250px");
  const [currentCategoryId, setCurrentCategoryId] = useState("");

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [selectedSlide, setSelectedSlide] = React.useState(null);

  const cardCount = useBreakpointValue({ base: 12, md: 20, lg: 12 });
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { categoryName } = useParams();
  const scrollRef = useRef(null);

  const navigate = useNavigate();

  const { data: allCourses, isLoading } = useQuery(
    GET_WEBSITE_COURSES_LIST,
    () => getRequestWeb(GET_WEBSITE_COURSES_LIST)
  );

  const { data: eachCategoryList, refetch } = useQuery(
    GET_WEBSITE_COURSES_CATEGORY_LIST(currentCategoryId),
    () => {
      if (currentCategoryId === "") return;
      return getRequestWeb(
        GET_WEBSITE_COURSES_CATEGORY_LIST(currentCategoryId)
      );
    }
  );

  const allCoursesList = allCourses?.data?.data.courses_data;
  const categoriesList = allCourses?.data?.data.categories_data;

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 1,
      spacing: 15,
    },
    vertical: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const makeDropDownVisible = () => {
      if (
        document.documentElement.scrollTop > 1160 ||
        document.body.scrollTop > 1160
      ) {
        setDropDownVisible(true);
      } else {
        setDropDownVisible(false);
      }
    };
    window.addEventListener("scroll", makeDropDownVisible);

    if (window.innerWidth >= "1220") {
      setSelectedSlide(0);
    }
  }, []);

  useEffect(() => {
    instanceRef.current?.moveToIdx(selectedSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlide]);

  const { currentUserOS } = useGetCurrentUserOs();

  function scrollToSection(refElement) {
    refElement.current.scrollIntoView({ behavior: "smooth" });
  }
  const sliderImages = useMemo(
    () => [
      visulaLearningImg,
      ModularcontentImg,
      confidenceTrackingImg,
      PractiseModulesImg,
    ],
    []
  );
  const categories = categoriesList
    ? [
        {
          id: "",
          identity: "All Courses",
        },
        ...categoriesList,
      ]
    : [];
  const sliderChanged = isDesktop ? currentSlide : selectedSlide;
  const sliderControls = (
    <Flex
      alignItems="center"
      mt="1em"
      gap="1em"
      mx={{ base: "auto", sm: "initial" }}
      w="fit-content"
    >
      <Image
        src={leftButton}
        alt=""
        w={{ base: "32px", lg: "62px" }}
        onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
        style={{ transform: "rotate(-90deg)" }}
        opacity={currentSlide === sliderImages.length - 1 ? "0.4" : ""}
        loading="lazy"
      />
      <Image
        src={leftButton}
        alt=""
        w={{ base: "32px", lg: "62px" }}
        onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
        style={{ transform: "rotate(90deg)" }}
        opacity={currentSlide === 0 ? "0.4" : ""}
        loading="lazy"
      />
    </Flex>
  );

  const breadCrumbText = categoryName
    ?.split("-")
    .map((each) => each.charAt(0).toUpperCase() + each.slice(1))
    .join(" ");

  const mobileSecondPageContent = headerData.filter(
    (each, i) => i === currentSlide
  );

  return (
    <AppLayout>
      <MetaData
        metaTitle="Self-learning courses"
        metaDescription="Learn at your own flexible time and pace with engaging courses"
      />
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <>
            <Box
              as="header"
              backgroundColor={appColors.brandPrimary["50"]}
              px={{ lg: "1em" }}
              backgroundImage={`url(${desktopHeaderBg})`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              pos="relative"
            >
              {(!secondPage || isDesktop) && (
                <BreadCrumb
                  Grandparent="Home"
                  GrandparentLink={WEBSITE_DASHBOARD}
                  parent="Self Learning"
                  parentLink={WEBSITE_COURSES_PAGE}
                  currentPage={
                    breadCrumbText === "All Courses" ? "" : breadCrumbText
                  }
                />
              )}
              <Flex
                gap={{ lg: "2em" }}
                justifyContent={{ lg: "space-between" }}
                pr={{ lg: "2em" }}
                pt={{ md: "1em", lg: "2.5em" }}
              >
                {secondPage && !isDesktop ? (
                  <Box
                    w="100%"
                    pt="3em"
                    backgroundImage={{
                      base: `url(${mobileSecondBg})`,
                      md: `url(${tabletBg})`,
                    }}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    pos="relative"
                  >
                    <CloseButton
                      pos="absolute"
                      bg="white"
                      borderRadius="50%"
                      top="5px"
                      right="10px"
                      _focus={{ outline: "none" }}
                      onClick={() => setSecondPage(false)}
                    />
                    <Box h="600px">
                      <Box
                        textAlign={{ base: "center", sm: "start" }}
                        w="fit-content"
                        mx={{ base: "auto", sm: "initial" }}
                        pl={{ sm: "2em" }}
                      >
                        <Text
                          color={appColors.brandPrimary["300"]}
                          w="10em"
                          mx={{ base: "auto", sm: "initial" }}
                          fontSize={fontSizes.xs}
                          fontWeight={fontWeights.extrabold}
                          as="h1"
                        >
                          FEATURES
                        </Text>
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.md}
                          color={appColors.brandGrey["900"]}
                          as="h2"
                        >
                          {mobileSecondPageContent[0].title}
                        </Text>
                        <Text
                          fontWeight={fontWeights.sm}
                          fontSize={fontSizes.xs}
                          color={appColors.brandGrey["400"]}
                        >
                          {mobileSecondPageContent[0].description}
                        </Text>
                        {sliderControls}
                      </Box>
                      <Box w="100vw" mx="auto" mt="4em" h="100%">
                        <Box
                          w="100%"
                          h="300px"
                          display="flex"
                          alignItems="center"
                          pos="relative"
                        >
                          <Box
                            w="68%"
                            h="39vw"
                            mx="auto"
                            borderRadius="8px"
                            mb="1em"
                          >
                            <Box
                              ref={sliderRef}
                              className="keen-slider"
                              h="100%"
                              zIndex="2"
                            >
                              {sliderImages.map((each, i) => (
                                <Box
                                  className={`keen-slider__slide number-slide${
                                    i + 1
                                  }`}
                                  border="1px solid pink"
                                  borderRadius="8px"
                                >
                                  <Image
                                    src={each}
                                    alt=""
                                    h="100%"
                                    objectFit="cover"
                                    loading="lazy"
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          <Image
                            src={mobileImage}
                            alt=""
                            pb="1em"
                            pos="absolute"
                            zIndex="0"
                            w="90%"
                            top="50%"
                            left=" 50%"
                            transform="translate(-50%, -50%)"
                            loading="lazy"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      backgroundColor="#FFFFFF"
                      boxShadow="inset 0px 1px 0px #D9D9D9"
                      borderBottom="1px solid #D9D9D9"
                      p="1em"
                    >
                      <a
                        href={
                          currentUserOS === "android"
                            ? PLAY_STORE_LINK
                            : APPLE_STORE_LINK
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Flex gap="0.5em">
                          <Image
                            src={
                              currentUserOS === "android"
                                ? PlayStoreIcon
                                : AppleIcon
                            }
                            alt=""
                            w="20px"
                            loading="lazy"
                          />
                          <Text
                            color={appColors.brandSecondary["400"]}
                            fontWeight={fontWeights.xl}
                            fontSize={fontSizes.md}
                            pos="relative"
                            top="3px"
                          >
                            Download App
                          </Text>
                        </Flex>
                      </a>
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <VStack
                      flexBasis={{ base: "100%", lg: "60%" }}
                      alignItems="flex-start"
                      maxWidth={{ lg: "580px" }}
                      gap="0.5em"
                      py="4em"
                      px={{ base: "1em", md: "4em", lg: "2em" }}
                      backgroundImage={{
                        base: `url(${mobileBg})`,
                        md: `url(${tabletBg})`,
                        lg: "",
                      }}
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                    >
                      <Text
                        as="h1"
                        textAlign={{ base: "center", lg: "start" }}
                        variant="headerTwo"
                      >
                        Learning music at your own pace has never been this fun!
                      </Text>
                      <Text
                        variant="descriptionText"
                        textAlign={{ base: "center", lg: "start" }}
                      >
                        Our comprehensively designed courses help you grasp
                        Indian Classical Music with an extensive coverage of
                        fundamentals and practice material.
                      </Text>
                      <Box
                        w="100%"
                        display="grid"
                        gridTemplateColumns={{
                          base: "repeat(2, 1fr)",
                          md: "repeat(4,1fr)",
                          lg: "1fr",
                        }}
                        gap={{ base: "0.5em", md: "0" }}
                      >
                        {headerData.map((each, i) => (
                          <Flex
                            key={each.title}
                            alignItems="center"
                            mb="1em"
                            bg="white"
                            border={
                              sliderChanged === i
                                ? `2px solid ${appColors.brandPrimary["300"]}`
                                : `1px solid ${appColors.brandPrimary["100"]}`
                            }
                            borderRadius="15px"
                            p={{ base: "1em", md: "1em", lg: "1.5em" }}
                            cursor="pointer"
                            gap={{ base: "0.25em", md: "0.5em", lg: "1em" }}
                            w={{ base: "100%", lg: "initial" }}
                            // h={{ baes: '96px', lg: 'initial' }}
                            maxW={{ base: "154px", lg: "initial" }}
                            mx={{ base: "auto", lg: "initial" }}
                            direction={{ base: "column", lg: "row" }}
                            textAlign={{ base: "center", lg: "start" }}
                            color={
                              sliderChanged === i
                                ? appColors.brandPrimary["300"]
                                : appColors.brandGrey["800"]
                            }
                            onClick={() => {
                              setSelectedSlide(i);
                              setSecondPage(true);
                            }}
                          >
                            <Image
                              src={`../../images/${each.img}`}
                              alt=""
                              loading="lazy"
                            />
                            <Box>
                              <Text
                                fontWeight={fontWeights.xl}
                                fontSize={{
                                  base: fontSizes.xs,
                                  lg: fontSizes.sm,
                                }}
                              >
                                {isDesktop ? each.title : each.mobileText}
                              </Text>
                              <Text
                                display={{ base: "none", lg: "initial" }}
                                color={appColors.brandGrey["500"]}
                                fontWeight={fontWeights.sm}
                                fontSize={fontSizes.sm}
                              >
                                {each.description}
                              </Text>
                            </Box>
                          </Flex>
                        ))}
                      </Box>
                      <Box alignSelf="center" pos="relative" bottom="2em">
                        <StoreComponent />
                      </Box>
                    </VStack>
                    {isDesktop && (
                      <VStack
                        flexBasis={{ lg: "50%" }}
                        justifyContent="flex-end"
                        pb="110px"
                        pos="relative"
                        maxW="680px"
                      >
                        <Box
                          border="2px solid black"
                          w="35vw"
                          h="22vw"
                          maxH="310px"
                          minH="280px"
                          borderRadius="8px"
                          mb="1.5em"
                          maxW="500px"
                        >
                          <Box
                            ref={sliderRef}
                            className="keen-slider"
                            h="100%"
                            zIndex="2"
                          >
                            {sliderImages.map((each, i) => (
                              <Box
                                className={`keen-slider__slide number-slide${
                                  i + 1
                                }`}
                                border="1px solid pink"
                                borderRadius="8px"
                              >
                                <Image
                                  src={each}
                                  alt=""
                                  h="100%"
                                  objectFit="cover"
                                  loading="lazy"
                                />
                              </Box>
                            ))}
                          </Box>
                          <Image
                            src={mobileImage}
                            alt=""
                            pb="1em"
                            pos="absolute"
                            bottom="173px"
                            left="8px"
                            zIndex="0"
                            loading="lazy"
                          />
                        </Box>
                        {sliderControls}
                      </VStack>
                    )}
                  </>
                )}
              </Flex>
            </Box>
            <Box py="2em" px="2em">
              <Text
                textAlign={{ base: "start", sm: "center" }}
                lineHeight="32px"
                as="h2"
                color={appColors.brandSecondary["700"]}
                fontWeight={fontWeights.xl}
                fontSize={{ base: "28px", md: "39px" }}
              >
                Over 300+ hrs of content, for everyone.
              </Text>
              <Box pos="relative">
                {isMobile && (
                  <Menu isLazy>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      pos="sticky"
                      top="1"
                      left="24%"
                      display={dropDownVisible ? "initial" : "none"}
                      _focus={{ outline: "none" }}
                      backgroundColor={appColors.brandSecondary["700"]}
                      borderRadius="32px"
                      _hover={{ opacity: "none" }}
                      _active={{
                        opacity: "none",
                      }}
                    >
                      Showing all Courses
                    </MenuButton>
                    <MenuList>
                      {categories?.map((eachTag, i) => (
                        <MenuItem
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.xs}
                          color={appColors.brandSecondary["400"]}
                          _focus={{
                            bg: appColors.brandPrimary["50"],
                            color: appColors.brandSecondary["700"],
                          }}
                          onClick={() => {
                            setTabIndex(i);
                            setCurrentCategory(eachTag.identity);
                            setCurrentCategoryId(eachTag.id);
                            refetch();
                            navigate(
                              MAKE_WEBSITE_COURSE_CATEGORY_PAGE(
                                eachTag.identity
                                  .split(" ")
                                  .join("-")
                                  .toLowerCase()
                              )
                            );
                            scrollToSection(scrollRef);
                          }}
                        >
                          {eachTag.identity}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
                <Tabs
                  variant="soft-rounded"
                  colorScheme="none"
                  onChange={(index) => setTabIndex(index)}
                  index={tabIndex}
                >
                  <TabList
                    overflow="auto"
                    py="2em"
                    px="0"
                    w={isTabOverflow ? "fit-content" : ""}
                    mx={isTabOverflow ? "auto" : ""}
                    gap="0.5em"
                    className="remove-scroll"
                  >
                    {categories?.map((eachTag) => (
                      <Tab
                        key={eachTag.id}
                        width={{ base: "fit-content" }}
                        _focus={{
                          outline: "none",
                        }}
                        p={{ base: "0.5em 1em", md: "0.75em" }}
                        minWidth={{ base: "fit-content", sm: "165px" }}
                        fontSize={fontSizes.sm}
                        fontWeight={fontWeights.xl}
                        border={`1px solid ${appColors.brandSecondary["500"]}`}
                        borderRadius="2em"
                        backgroundColor={
                          currentCategory === eachTag.identity
                            ? appColors.brandSecondary["700"]
                            : ""
                        }
                        color={
                          currentCategory === eachTag.identity
                            ? appColors.brandWhite["900"]
                            : appColors.brandSecondary["500"]
                        }
                        onClick={() => {
                          refetch();

                          const categoryName =
                            eachTag.identity === "All Courses"
                              ? ""
                              : eachTag.identity;

                          setCurrentCategory(eachTag.identity);
                          setCurrentCategoryId(eachTag.id);
                          navigate(
                            MAKE_WEBSITE_COURSE_CATEGORY_PAGE(
                              categoryName.split(" ").join("-").toLowerCase()
                            )
                          );
                        }}
                      >
                        {eachTag.identity}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels ref={scrollRef}>
                    {/*  first tab that contains all courses */}
                    <TabPanel
                      display="grid"
                      mx="auto"
                      gridTemplateColumns={{
                        base: "0.5fr 0.5fr",
                        sm: "0.33fr 0.33fr 0.33fr",
                        md: "0.3fr 0.3fr 0.3fr 0.3fr",
                      }}
                      gap="1em"
                      rowGap="2em"
                      px="0"
                    >
                      {allCoursesList?.map((each, i) => {
                        if (!showMore && i <= cardCount - 1) {
                          return <CoursePageCard prop={each} />;
                        }
                        if (showMore) {
                          return <CoursePageCard prop={each} />;
                        }
                        return null;
                      })}
                    </TabPanel>
                    {/* remaining tabs based on categories */}
                    {eachCategoryList &&
                      categoriesList.map(() => (
                        <TabPanel
                          display="grid"
                          mx="auto"
                          gridTemplateColumns={{
                            base: "0.5fr 0.5fr",
                            sm: "0.33fr 0.33fr 0.33fr",
                            md: "0.3fr 0.3fr 0.3fr 0.3fr",
                          }}
                          gap="1em"
                          rowGap="2em"
                          px="0"
                        >
                          {eachCategoryList?.data?.data.course_data.map(
                            (each, i) => {
                              if (!showMore && i <= cardCount - 1) {
                                return <CoursePageCard prop={each} />;
                              }
                              if (showMore) {
                                return <CoursePageCard prop={each} />;
                              }
                              return null;
                            }
                          )}
                        </TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              </Box>

              {(allCoursesList.length > 12 ||
                (eachCategoryList?.data?.data.course_data.length > 12 &&
                  eachCategoryList === undefined)) && (
                <Box display="grid" placeContent="center" my="1em">
                  <Button
                    variant="exploreButton"
                    fontWeight={fontWeights.xl}
                    display="flex"
                    alignItems="center"
                    gap="0.5em"
                    padding="1.5em"
                    _focus={{ outline: "none" }}
                    onClick={() => {
                      setShowMore((pre) => !pre);
                      if (showMore) {
                        scrollToSection(scrollRef);
                      }
                    }}
                  >
                    {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    {showMore ? "Show Less" : "Show More Courses"}
                  </Button>
                </Box>
              )}
            </Box>
            <AppTestimonial testimonialData={allCourses} />
            <FeaturedOn />
          </>
        )}
      </AppLoader>
    </AppLayout>
  );
}
export default Courses;
