import React from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { Box, Button, useBreakpointValue, Image } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import sliderBtn from '../assets/dashboard/sliderButton.svg';
import useGetCurrentUserOs from 'hooks/useGetCurrentUserOs';
import { APPLE_STORE_LINK, PLAY_STORE_LINK } from 'api/urls/URL';
// import banner1 from '../assets/dashboard/banner1.jpg';
// import banner2 from '../assets/dashboard/banner2.jpg';
// import banner3 from '../assets/dashboard/banner3.jpg';

function HeadSliderHome({ sliderData }) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const { currentUserOS } = useGetCurrentUserOs

  const sliderDots = useBreakpointValue({
    base: '8px',
    lg: '16px',
  });
  const sliderBtnVisible = useBreakpointValue({ base: true, md: false });

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      breakpoints: {
        '(max-width: 769px)': {
          slides: { perView: 1, spacing: 35, origin: 'center' },
        },
      },
      slides: {
        perView: 1.5,
        spacing: 50,
        origin: 'center',
      },
    },
    [
      (slider) => {
        slider.on('created', () => {
          setInterval(() => {
            slider.next();
          }, 4000);
        });
      },
    ],
  );

  // const slidesContent = [
  //   {
  //     id: 0,
  //     img: banner1,
  //   },
  //   {
  //     id: 1,
  //     img: banner2,
  //   },
  //   {
  //     id: 2,
  //     img: banner3,
  //   },
  // ];
  return (
    <>
      <Box className="keen-slider" ref={sliderRef} pb="1em" w="100%">
        {sliderData.map((item, i) => (
          <Box
            h={{ base: 'fit-content', md: '35vw', lg: '38vw' }}
            className={`keen-slider__slide number-slide${i + 1} ${i !== currentSlide ? 'resize' : ' '
              }`}
            borderRadius="16px"
            backgroundColor="pink.50"
          >
            <a href={item?.course_url} target="_blank" rel="noreferrer">

              <Image
                src={item.image}
                alt="banner"
                style={{ height: '100%', width: '100%' }}
                objectFit="cover"
              />
            </a>

          </Box>
        ))}
      </Box>

      <div className="dots">
        {sliderData.map((item, i) => (
          <Button
            className={`dot ${currentSlide === i ? 'active' : ''}`}
            key={item.id}
            onClick={() => instanceRef.current?.moveToIdx(i)}
            _focus={{ outline: 'none' }}
          />
        ))}
      </div>

      {sliderBtnVisible && (
        <><Box
          pos="absolute"
          right={{ base: "-2em", sm: "-3em" }}
          bottom={{ base: '20%', sm: '25%' }}
          zIndex="2"
          cursor="pointer"
          onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
        >
          <Image
            src={sliderBtn}
            alt="slider-btn"
            w={{ base: '3em', sm: '4em' }} />
        </Box><Box
          pos="absolute"
          left={{ base: "-2em", sm: "-3em" }}
          bottom={{ base: '20%', sm: '25%' }}
          zIndex="2"
          cursor="pointer"
          onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
        >
            <Image
              src={sliderBtn}
              alt="slider-btn"
              transform="rotateY(180deg)"
              w={{ base: '3em', sm: '4em' }} />
          </Box></>
      )}

      <style jsx>{`
        .dots {
          display: flex;
          padding: 10px 0;
          justify-content: center;
        }

        .dot {
          border: none;
          width: ${sliderDots};
          height: ${sliderDots};
          background: ${appColors.brandPrimary['100']};
          border-radius: 50%;
          margin: 0 5px;
          padding: 5px;
          cursor: pointer;
          min-width: 0;
        }

        .dot:focus {
          outline: none;
        }

        .dot.active {
          background: ${appColors.brandPrimary['300']};
        }
        @media (min-width: 992px) {
          .resize {
            margin-top: 1.5em;
            height: 34vw;
          }
        }
      `}</style>
    </>
  );
}

export default HeadSliderHome;
