import React from 'react'
import { Image } from '@chakra-ui/react';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from 'api/urls/URL';
import {
    instaIcon,
    facebookIcon,
} from "assets/index";

function SocialLink() {
    const socialLinkData = [{
        name: "instagram logo",
        link: INSTAGRAM_LINK,
        icon: instaIcon,
    },
    {
        name: "facebook logo",
        link: FACEBOOK_LINK,
        icon: facebookIcon,
    },
    ];

    return (
        <>
        {
            socialLinkData.map(each => <a
                href={each.link}
                target="_blank"
                rel="noreferrer"
                key={each.link}
              >
                <Image src={each.icon} alt={each.name} />
            </a> )
        }
        </> 
    )
};

export default SocialLink;