import appColors from 'theme/appColors';
import { fontSizes, fontWeights } from 'theme/fonts';
import { appBorderRadius } from 'theme/sizes';

const AppButtonStyles = {
  variants: {
    solid: {
      backgroundColor: appColors.brandSecondary['500'],
      color: appColors.brandWhite['900'],

      borderRadius: appBorderRadius.sm,
      _hover: {
        backgroundColor: appColors.brandSecondary['500'],
        opacity: 0.8,
        _disabled: {
          backgroundColor: appColors.brandSecondary['500'],
          opacity: 0.4,
        },
      },
    },
    defaultButton: {
      backgroundColor: appColors.brandPrimary['300'],
      color: appColors.brandWhite['900'],
    },
    exploreButton: {
      backgroundColor: appColors.brandWhite['900'],
      color: appColors.brandSecondary['500'],
      border: `2px solid ${appColors.brandSecondary['500']}`,
    },
  },
};

export default AppButtonStyles;
