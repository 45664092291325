import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { BLiveClasses, BSelfLearning } from "assets/index";
import {
  WEBSITE_COURSES_PAGE,
  WEBSITE_LIVE_CLASS_PAGE,
} from "navigation/routes";
import { ChevronRightIcon } from "@chakra-ui/icons";
import appColors from "theme/appColors";
import { Link } from "react-router-dom";

const BlogDetailAside = () => {
  const attributessection = [
    {
      img: BLiveClasses,
      title: "Live Classes",
      description: "Get personal attention from the best professional Gurus.",
      link: WEBSITE_LIVE_CLASS_PAGE,
      linkText: "Book Now",
    },
    {
      img: BSelfLearning,
      title: "Self Learning",
      description:
        "Learn at your own pace with our engaging app based courses.",
      link: WEBSITE_COURSES_PAGE,
      linkText: "Explore Courses",
    },
  ];
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      gap={"20px"}
      width={"100%"}
      maxW={"507px"}
      mb="60px"
    >
      {attributessection.map((obj) => (
        <Link to={obj.link}>
          <Flex
            key={obj.title}
            gap="15px"
            alignItems={"center"}
            border={"1px solid #CDCDCD"}
            borderRadius={"5px"}
            padding={"17px 20px"}
          >
            <Box>
              <Text
                fontSize={["19px", "28px"]}
                fontWeight={700}
                color={appColors.brandPrimary["300"]}
              >
                {obj.title}
              </Text>
              <Text fontSize={["13px", "19px"]} fontWeight={400} mb="5px">
                {obj.description}
              </Text>
              <Button
                padding={"0px 16px 0px 14px"}
                variant="defaultButton"
                rightIcon={<ChevronRightIcon fontSize={"20px"} />}
                fontSize={["13px", "16px"]}
                height={["28px", "32px"]}
              >
                {obj.linkText}
              </Button>
              <b></b>
            </Box>
            <Image src={obj.img} mr={["0px", "16px"]} />
          </Flex>
        </Link>
      ))}
    </Flex>
  );
};

export default BlogDetailAside;
