import React from 'react'

function useCountry() {

    let isIndia = true;
    let timeZone ;

    const date = new Date();
    const dateAsString = date[Symbol.toPrimitive]('string');
     isIndia =  dateAsString.includes("India") || dateAsString.includes("भारतीय");
     timeZone = dateAsString

   return {isIndia , timeZone }
}

export default useCountry