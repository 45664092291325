import React from "react";
import AppLayout from "components/AppLayout";
import AppTestimonial from "components/AppTestimonial";
import FeaturedOn from "components/FeaturedOn";
import {
  Image,
  VStack,
  Flex,
  Stack,
  Box,
  Text,
  useBreakpointValue,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import AppText from "components/Typography/AppText";
import appColors from "theme/appColors";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import ProgramDetailsModal from "components/ProgramDetails";
import { useKeenSlider } from "keen-slider/react";
import BreadCrumb from "components/BreadCrumb";
// import { GOOGLE_FORM, WHATSAPP_URL } from 'api/urls/URL';
import { useQuery } from "react-query";
import { GET_WEBSITE_LIVECLASS_DATA } from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
// import voiceCulture from '../../assets/dashboard/VoiceCulture.svg';
import voiceCultureThumbnail from "../../assets/dashboard/Voice Culture-thumbnail.svg";
import carnatic from "../../assets/dashboard/Carnatic-thumbnail.svg";
import header from "../../assets/dashboard/liveClass-header.png";
import listIcon1 from "../../assets/dashboard/voxguru-list-1.svg";
import listIcon2 from "../../assets/dashboard/voxguru-list-2.svg";
import "keen-slider/keen-slider.min.css";
import arrowleft from "../../assets/dashboard/slider-arrow-left.svg";
import arrowRight from "../../assets/dashboard/sliderButton.svg";
// import hindustaniImg from '../../assets/dashboard/hindustani-img.svg';
import carnaticImg from "../../assets/dashboard/carnatic-img.svg";
import lightMusicImg from "../../assets/dashboard/light-music-img.svg";
import { advantages, liveClassModalData, TabelData } from "./courseData";
import { WEBSITE_DASHBOARD } from "navigation/routes";
import WebButton from "components/WebButton";
import MetaData from "components/MetaData";

function LiveClassPage() {
  const {
    isOpen: isCarnaticOpen,
    onClose: onCarnaticClose,
    onOpen: onCarnaticOpen,
  } = useDisclosure();
  const {
    isOpen: isVoiceCultureOpen,
    onClose: onVoiceCultureClose,
    onOpen: onVoiceCultureOpen,
  } = useDisclosure();
  // const {
  //   isOpen: isLightMusicOpen,
  //   onClose: onLightMusicClose,
  //   onOpen: onLightMusicOpen,
  // } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1.25,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const programs = [
    {
      id: 1,
      img: carnatic,
      title: "Carnatic",
      description:
        "Get closer to your roots with an authentic Carnatic experience designed for singers of all levels. Build a strong foundation and master your skills through voice, ear and Raga training. ",
      link: "",
      explore: onCarnaticOpen,
    },
    {
      id: 2,
      img: voiceCultureThumbnail,
      title: "Voice Culture",
      description:
        "Transform your singing voice with an all round experience of breath training, vocal techniques and daily warmups. Overcome your challenge areas and get practical guidance to find your singing voice.",
      link: "",
      explore: onVoiceCultureOpen,
    },
    // {
    //   id: 3,
    //   img: voiceCulture,
    //   title: 'Light Music',
    //   description:
    //     'Programs are designed based on the level of previous knowledge one has with classical music. You can always change the program after the initial class and as per the feedback from your trainer.',
    //   link: '',
    //   explore: onLightMusicOpen,
    // },
  ];

  const { data } = useQuery(GET_WEBSITE_LIVECLASS_DATA, () =>
    getRequestWeb(GET_WEBSITE_LIVECLASS_DATA)
  );

  return (
    <AppLayout>
      <MetaData
        metaTitle="Live classes"
        metaDescription="Get live sessions with structured curriculum and expert trainers"
      />
      {/* ----header ------- */}
      <Flex
        backgroundColor={appColors.brandPrimary["50"]}
        flexDirection={{ base: "column", lg: "row" }}
        pt="0.5em"
        pb={{ lg: "0.5em" }}
        pos="relative"
      >
        <BreadCrumb
          parent="Home"
          parentLink={WEBSITE_DASHBOARD}
          currentPage="Live Class"
        />
        <VStack
          spacing={6}
          justifyContent="center"
          px={{ base: "2em", md: "3em" }}
          alignItems={{ base: "start", sm: "center", lg: "start" }}
          flexBasis="50%"
          textAlign={{ sm: "center", lg: "start" }}
          w={{ sm: "90%", md: "75%", lg: "initial" }}
          mx="auto"
          pt={{ base: "5em", md: "4em", lg: "initial" }}
        >
          <Text as="h1" variant="headerTwo">
            Book live classes with world class curriculum & expert Gurus
          </Text>
          <Text variant="descriptionText" w={{ lg: "88%" }}>
            Explore our cutting edge curriculum in Carnatic & Voice Culture.
            Learn from expert Gurus at the comfort of home. Interact with
            likeminded singers across the globe.
          </Text>
          {/* <Link
            href={mobileAndTab ? WHATSAPP_URL : GOOGLE_FORM}
            isExternal
            _hover={{ textDecoration: 'none' }}
            _focus={{ outline: 'none' }}
          >
            <AppButton
              variant="defaultButton"
              customStyles={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: fontWeights.xl,
                fontSize: fontSizes.md,
                padding: '1.3em',
                gap: '0.4em',
                borderRadius: '12px',
                _focus: { outline: 'none' },
              }}
            >
              {mobileAndTab && (
                <Image src={whatsappLogo} alt="whatsapp logo" w="1.5em" loading="lazy"/>
              )}
              {mobileAndTab ? 'Book a live Class' : 'Submit a request'}
            </AppButton>
          </Link> */}
          <WebButton content="Join Live Class" onMobile="Join Live Class" />

          {/* {mobileAndTab && (
            <Text fontWeights={fontWeights.xl} fontsize={fontSizes.md}>
              Or,
              <span
                style={{
                  fontWeight: fontWeights.xl,
                  fontSize: fontSizes.md,
                  color: appColors.brandSecondary['400'],
                  cursor: 'pointer',
                }}
              >
                Submit a request
              </span>
            </Text>
          )} */}
        </VStack>
        <Box
          backgroundImage={`url(${header})`}
          backgroundRepeat="no-repeat"
          backgroundPosition={{
            base: "right -80px top 0",
            sm: "top",
            lg: "top",
          }}
          backgroundSize={{ base: "450px", sm: "550px", lg: "initial" }}
          flexBasis={{ lg: "50%" }}
          h={{ base: "250px", sm: "300px", lg: "610px" }}
        />
      </Flex>

      {/* ------- advantages section ------ */}
      <Box
        backgroundColor={appColors.brandSecondary["700"]}
        pt={{ base: "3em", md: "3em", lg: "4em" }}
        pb={{ base: "3em", md: "3em", lg: "7em" }}
        display="grid"
        gridTemplateColumns={{ base: "0.5fr 0.5fr", md: "repeat(4 , 1fr)" }}
      >
        {advantages.map((each) => (
          <VStack
            p={{ base: "0.5em", lg: "1em" }}
            gap={{ base: "0.35em", lg: "0.5em" }}
          >
            <Image
              src={each.img}
              alt=""
              maxWidth={{ base: "59px", lg: "none" }}
              h={{ base: "60px", md: "50px", lg: "106px" }}
              mb={{ lg: "1em" }}
              loading="lazy"
            />
            <AppText
              customStyles={{
                textAlign: "center",
                fontSize: { base: fontSizes.sm, lg: fontSizes.xl },
                lineHeight: { base: lineHeights.sm, lg: lineHeights.xl },
                fontWeight: { base: fontWeights.xl },
                color: appColors.brandWhite["900"],
                w: { base: "85%", lg: "95%" },
              }}
            >
              {each.title}
            </AppText>
            <AppText
              customStyles={{
                textAlign: "center",
                color: appColors.brandSecondary["300"],
                fontSize: { md: fontSizes.xxs, lg: fontSizes.sm },
                lineHeight: { md: lineHeights.xxs, lg: lineHeights.md },
                display: { base: "none", md: "initial" },
                w: { base: "80%", lg: "100%" },
              }}
            >
              {each.description}
            </AppText>
          </VStack>
        ))}
      </Box>

      {/* ----- book program section ------ */}

      <Box py={{ base: "1.5em", md: "2em", lg: "3em" }}>
        <VStack pb="3em">
          <Text as="h2" size="xl" color={appColors.brandSecondary["700"]}>
            Book a Program
          </Text>
          <Text
            fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
            fontWeight={fontWeights.sm}
            lineHeight={{ base: lineHeights.sm, lg: lineHeights.md }}
            color={appColors.brandGrey["700"]}
            textAlign="center"
            w={{ base: "90%", sm: "80%", md: "65%" }}
          >
            Choose from a wide range of programs designed for every type of
            singer. Whether you are a beginner, intermediate or advanced, we
            have the ideal journey to take you to the next level.
          </Text>
        </VStack>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          gap={{ base: "2.5em", lg: "0" }}
          px="1em"
        >
          {programs.map((each) => (
            <VStack
              key={each.title}
              w={{ base: "95%", sm: "70%", md: "55%", lg: "40%" }}
              gap="0.4em"
            >
              <Image
                src={each.img}
                alt=""
                w={{ base: "160px", lg: "initial" }}
                loading="lazy"
              />
              <Text as="h3" color={appColors.brandPrimary["300"]} size="xl">
                {each.title}
              </Text>
              <AppText
                customStyles={{
                  fontSize: { base: fontSizes.xs, lg: fontSizes.sm },
                  lineHeight: { base: lineHeights.xs, lg: lineHeights.sm },
                  fontWeight: fontWeights.sm,
                  color: appColors.brandGrey["700"],
                  textAlign: "center",
                }}
              >
                {each.description}
              </AppText>
              <Button
                variant="exploreButton"
                onClick={each.explore}
                w={{ base: "190px", lg: "212px" }}
                p={{ base: "1.3em", lg: "1.5em" }}
                _focus={{ outline: "none" }}
              >
                Explore Programs
              </Button>
            </VStack>
          ))}
        </Stack>
      </Box>

      {/* ----- table section ------- */}
      <Box my="4em">
        <VStack pb="1.5em">
          <Text as="h2" size="xl" color={appColors.brandSecondary["700"]}>
            The VoxLive Advantage
          </Text>
          <Text
            fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
            fontWeight={fontWeights.sm}
            lineHeight={{ base: lineHeights.sm, lg: lineHeights.md }}
            color={appColors.brandGrey["700"]}
            textAlign="center"
            w={{ base: "90%", sm: "60%" }}
          >
            VoxGuru’s Grade and Certificate courses offer a significant value
            add, compared to conventional one-to-one classes.
          </Text>
        </VStack>
        {isMobile ? (
          <>
            <Box className="keen-slider" ref={sliderRef}>
              {TabelData.map((item, i) => (
                <Box
                  className={`keen-slider__slide number-slide${i}`}
                  p="1em 2em"
                  borderRight={`1px solid ${appColors.brandGrey["100"]}`}
                >
                  <Box style={{ minHeight: "70px" }}>
                    <Text
                      style={{
                        fontSize: "11px",
                        fontWeight: fontWeights.xl,
                        color: appColors.brandGrey["700"],
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <Image src={listIcon2} alt="" loading="lazy" />
                      {item.firstHead}
                    </Text>
                    <Text size="xs" color={appColors.brandGrey["700"]} mt="5px">
                      {item.firstDescription}
                    </Text>
                  </Box>
                  <Box mt="1em">
                    <Text
                      style={{
                        color: appColors.brandPrimary["300"],
                        fontSize: "11px",
                        fontWeight: fontWeights.xl,
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <Image src={listIcon1} alt="" loading="lazy" />
                      {item.secondHead}
                    </Text>
                    <Text size="md" color={appColors.brandGrey["700"]} mt="5px">
                      {item.secondDescription}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              gap="0.5em"
              mt="1em"
              w="fit-content"
              mx="auto"
            >
              <Image
                src={arrowleft}
                alt="arrowLeft"
                w="34px"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                opacity={currentSlide === 0 ? "0.4" : ""}
                loading="lazy"
              />
              <Text w="40px" ml="5px">
                {currentSlide + 1} / {TabelData.length}
              </Text>
              <Image
                src={arrowRight}
                alt="arrowRight"
                w="34px"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                opacity={currentSlide === TabelData.length - 1 ? "0.4" : ""}
                loading="lazy"
              />
            </Flex>
          </>
        ) : (
          <Box
            style={{
              width: "85%",
              border: `1px solid ${appColors.brandPrimary["300"]}`,
              marginInline: "auto",
              borderRadius: "1rem",
            }}
          >
            <table
              style={{
                width: "100%",
              }}
            >
              <tr>
                <td
                  style={{
                    padding: "1em",
                    backgroundColor: appColors.brandGrey["50"],
                    fontSize: "23px",
                    fontWeight: fontWeights.xl,
                    color: appColors.brandGrey["700"],
                    textAlign: "center",
                    borderTopLeftRadius: "1rem",
                    width: "50%",
                  }}
                >
                  Conventional classes
                </td>
                <td
                  style={{
                    backgroundColor: appColors.brandPrimary["300"],
                    textAlign: "center",
                    color: appColors.brandWhite["900"],
                    fontSize: "23px",
                    fontWeight: fontWeights.xl,
                    borderTopRightRadius: "1rem",
                    width: "50%",
                  }}
                >
                  VoxGuru Advantage
                </td>
              </tr>
              {TabelData.map((each) => {
                return (
                  <tr>
                    <th
                      className="tableTextLeft"
                      style={{
                        fontsize: fontSizes.sm,
                        lineheight: lineHeights.sm,
                        color: appColors.brandGrey["700"],
                        textAlign: "start",
                      }}
                    >
                      {each.firstDescription}
                    </th>
                    <th
                      className="tableTextRight"
                      style={{
                        fontsize: fontSizes.sm,
                        lineheight: lineHeights.sm,
                        color: appColors.brandGrey["700"],
                        borderLeft: `1px solid ${appColors.brandPrimary["300"]}`,
                      }}
                    >
                      {each.secondDescription}
                    </th>
                  </tr>
                );
              })}
            </table>
          </Box>
        )}
      </Box>
      {data && <AppTestimonial testimonialData={data} />}
      <FeaturedOn />
      <ProgramDetailsModal
        isOpen={isCarnaticOpen}
        onClose={onCarnaticClose}
        bgImage={carnaticImg}
        data={liveClassModalData[0]}
      />
      <ProgramDetailsModal
        isOpen={isVoiceCultureOpen}
        onClose={onVoiceCultureClose}
        bgImage={lightMusicImg}
        data={liveClassModalData[1]}
      />
      {/* <ProgramDetailsModal
        isOpen={isLightMusicOpen}
        onClose={onLightMusicClose}
        title="Light Music"
        bgImage={lightMusicImg}
      /> */}
    </AppLayout>
  );
}

export default LiveClassPage;
