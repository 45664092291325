import { getHostAPIUrl } from 'appConfig';
import axios from 'axios';

export const postRequestWeb = async (endpoint, body) => {
  const Axios = axios.create({
    baseURL: getHostAPIUrl(),
    timeout: 30000,
    responseType: 'json',
  });

  const data = Axios.post(endpoint, body);
  return data;
};
