import { Box, Link, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import WebRelatedPagesWrapper from "components/WebRelatedPagesWrapper";
import React from "react";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import appColors from "theme/appColors";
import { WEBSITE_DASHBOARD, WEBSITE_TERMS_PAGE } from "navigation/routes";

function PrivayPolicy() {
  const breadcrumbdata = {
    parent: "Home",
    parentLink: WEBSITE_DASHBOARD,
    currentPage: "Privacy & Policy",
  };
  const pagedata = {
    title: "Privacy Policy",
  };

  const listItemswithLink = [
    {
      name: "Google Play Services",
      link: "https://policies.google.com/privacy",
    },
    {
      name: " Google Analytics for Firebase",
      link: "https://firebase.google.com/policies/analytics",
    },
    {
      name: "Firebase Crashlytics",
      link: "https://firebase.google.com/support/privacy/",
    },
    {
      name: "Sentry",
      link: "https://sentry.io/privacy/",
    },
    {
      name: "Mixpanel Analytics",
      link: "https://mixpanel.com/legal/privacy-policy/",
    },
  ];
  const serviceProvider = [
    { name: " To facilitate our Service" },
    { name: "To provide the Service on our behalf" },
    { name: " To perform Service-related services or" },
    { name: " To assist us in analyzing how our Service is used." },
  ];
  const orderedList = (listItems) => {
    return (
      <UnorderedList>
        {listItems.map((each) => {
          if (each.link) {
            return (
              <Link href={each.link} isExternal>
                <ListItem>
                  <span style={{ color: "#0060df" }}>{each.name}</span>
                </ListItem>
              </Link>
            );
          } else return <ListItem>{each.name}</ListItem>;
        })}
      </UnorderedList>
    );
  };

  return (
    <WebRelatedPagesWrapper
      breadCrumbContent={breadcrumbdata}
      pageData={pagedata}
    >
      <Box
        py="2em"
        w="fit-content"
        mx="auto"
        maxW={{ md: "70vw", lg: "800px" }}
        px={{ base: "2em", md: "0" }}
        fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
        fontWeight={fontWeights.sm}
        lineHeight={{ base: lineHeights.sm, lg: lineHeights.md }}
        color={appColors.brandGrey["700"]}
      >
        {/* <Text>
          Pratibha P built the VoxGuru app as a Commercial app. This SERVICE is provided by Pratibha P and is intended for use as is.
        </Text> */}

        <Text my="1em">
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service. If you choose to use our Service, then you
          agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing
          and improving the Service. We will not use or share your information
          with anyone except as described in this Privacy Policy.
        </Text>

        <Text my="1em">
          Our app will never publicly disclose any personal or sensitive data of
          yours related to financial or payment activities or any government
          identification numbers.
        </Text>
        <Text my="1em">
          For more details on Terms and Conditions, please visit the link{" "}
          <Link
            href={WEBSITE_TERMS_PAGE}
            color="#0060df"
            textDecoration="underline"
          >
            here
          </Link>
          .
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Information Collection and Use
        </Text>

        <Text>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to Name, Email, Gender, Age, Music
          Interests. The information that we request will be retained by us and
          used as described in this privacy policy.{" "}
        </Text>
        <Text my="0.5em">
          The app does use third party services that may collect information
          used to identify you. Link to privacy policy of third party service
          providers used by the app
        </Text>

        {orderedList(listItemswithLink)}
        <Text my="1em" fontWeight={fontWeights.xl}>
          Log Data
        </Text>

        <Text>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Cookies
        </Text>

        <Text my="1em">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </Text>
        <Text>
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Service Providers
        </Text>

        <Text my="0.5em">
          We may employ third-party companies and individuals due to the
          following reasons:
        </Text>

        {orderedList(serviceProvider)}
        <Text mt="1em">
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Security
        </Text>

        <Text>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.{" "}
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Links to Other Sites
        </Text>

        <Text>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Changes to This Privacy Policy
        </Text>

        <Text>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </Text>

        <Text my="1em">This policy is effective as of 2021-10-01</Text>

        <Text my="1em" fontWeight={fontWeights.xl}>
          Contact Us
        </Text>

        <Text>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at support@voxguru.in.
        </Text>
      </Box>
    </WebRelatedPagesWrapper>
  );
}

export default PrivayPolicy;
