import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  WEBSITE_DASHBOARD,
  WEBSITE_LIVE_CLASS_PAGE,
  WEBSITE_WORKSHOP_PAGE,
  WEBSITE_COURSES_PAGE,
  WEBSITE_COURSES_DETAIL_PAGE,
  WEBSITE_WORKSHOP_DETAIL_PAGE,
  WEBSITE_COURSE_CATEGORY_PAGE,
  WEBSITE_ABOUT_PAGE,
  WEBSITE_FAQ_PAGE,
  WEBSITE_CONTACT_PAGE,
  WEBSITE_PRIVACY_PAGE,
  WEBSITE_TERMS_PAGE,
  WEBSITE_BLOG,
  BLOG_WITH_CATEGORY,
  INDIVIDUAL_BLOG,
} from "navigation/routes";
import React, { Suspense, useEffect } from "react";
import AppLoader from "components/AppLoader/AppLoader";
import Dashboardhome from "pages/website";
import LiveClassPage from "pages/website/liveclass";
import WorkshopPage from "pages/website/workshop";
import Courses from "pages/website/courses";
import CourseDetail from "pages/website/CourseDetail";
import WorkshopDetail from "pages/website/workshopDetail";
import FAQ from "pages/website/FAQ";
import About from "pages/website/About";
import PrivayPolicy from "pages/website/PrivayPolicy";
import Contact from "pages/website/Contact";
import Terms from "pages/website/Terms";
import PageNotFound from "pages/website/PageNotFound";
import RemoveTrailingSlash from "utils/RemoveTrailingSlash";
import BlogListingPage from "pages/website/Blog";
import DetailedBlog from "pages/website/Blog/DetailedBlog";

function AppSwitch() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectPath =
      "/courses/carnatic/carnatic-voice-culture-mastering-the-voice-through-swaram-&-akaram";
    if (window.location.pathname === redirectPath) {
      navigate(WEBSITE_COURSES_PAGE);
    }
  }, []);

  useEffect(() => {
    // prevent scrolling to top when switching tabs in course page
    const coursePage = location.pathname.includes("courses");
    if (coursePage) return;

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Suspense fallback={<AppLoader />}>
      <RemoveTrailingSlash />
      <Routes>
        <Route path={WEBSITE_DASHBOARD} element={<Dashboardhome />} />
        <Route path={WEBSITE_LIVE_CLASS_PAGE} element={<LiveClassPage />} />
        <Route path={WEBSITE_WORKSHOP_PAGE} element={<WorkshopPage />} />
        <Route path={WEBSITE_COURSES_PAGE} element={<Courses />} />
        <Route path={WEBSITE_COURSE_CATEGORY_PAGE} element={<Courses />} />
        <Route path={WEBSITE_COURSES_DETAIL_PAGE} element={<CourseDetail />} />
        <Route
          path={WEBSITE_WORKSHOP_DETAIL_PAGE}
          element={<WorkshopDetail />}
        />
        <Route path={WEBSITE_BLOG} element={<BlogListingPage />} />
        <Route path={BLOG_WITH_CATEGORY} element={<BlogListingPage />} />
        <Route path={INDIVIDUAL_BLOG} element={<DetailedBlog />} />



        <Route path={WEBSITE_FAQ_PAGE} element={<FAQ />} />
        <Route path={WEBSITE_ABOUT_PAGE} element={<About />} />
        <Route path={WEBSITE_PRIVACY_PAGE} element={<PrivayPolicy />} />
        <Route path={WEBSITE_CONTACT_PAGE} element={<Contact />} />
        <Route path={WEBSITE_TERMS_PAGE} element={<Terms />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}
export default AppSwitch;
