import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  useDisclosure,
  useBreakpointValue,
  Text,
  Flex,
  Image,
  Box,
} from "@chakra-ui/react";
import AppBox from "components/chakraLayoutOverrides/AppBox";
import AppFlex from "components/chakraLayoutOverrides/AppFlex";
import AppText from "components/Typography/AppText";
import AppButton from "components/Typography/AppButton";
import React from "react";
import appColors from "theme/appColors";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import {
  APPLE_STORE_LINK,
  GOOGLE_FORM,
  PLAY_STORE_LINK,
  TRAINER_LOGIN_PAGE,
  WHATSAPP_URL,
} from "api/urls/URL";
import useGetCurrentUserOs from "hooks/useGetCurrentUserOs";
import {
  WEBSITE_LIVE_CLASS_PAGE,
  WEBSITE_WORKSHOP_PAGE,
  WEBSITE_COURSES_PAGE,
  WEBSITE_ABOUT_PAGE,
  WEBSITE_FAQ_PAGE,
  WEBSITE_CONTACT_PAGE,
  WEBSITE_PRIVACY_PAGE,
  WEBSITE_DASHBOARD,
  WEBSITE_BLOG,
} from "navigation/routes";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import menuIcon from "../assets/menu-icon.svg";
import playstorelogo from "../assets/dashboard/play-store-logo.svg";
import applestorelogo from "../assets/dashboard/apple-icon.svg";
import StoreComponent from "./storeComponent";
import linkicon from "../assets/dashboard/external-link-icon.svg";
import SocialLink from "./SocialLink";
import WebButton from "./WebButton";

function AppLayout({ children, noMargin }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { currentUserOS } = useGetCurrentUserOs();
  const isFooterLink = useBreakpointValue({ base: true, lg: false });
  const footerLinks = [
    { name: "About us", link: WEBSITE_ABOUT_PAGE },
    { name: "FAQ", link: WEBSITE_FAQ_PAGE },
    { name: "Privacy & Terms", link: WEBSITE_PRIVACY_PAGE },
    // { name: 'Contact Us', link: WEBSITE_CONTACT_PAGE },
    { name: "Contact Us", link: isMobile ? WHATSAPP_URL : GOOGLE_FORM },
    { name: "Self Learning Catalogue", link: WEBSITE_COURSES_PAGE },
    { name: "Upcoming Workshops", link: WEBSITE_WORKSHOP_PAGE },
    { name: "Join a Live Class", link: WEBSITE_LIVE_CLASS_PAGE },
  ];
  const drawerSecondSection = footerLinks.slice(0, 3);
  const navigate = useNavigate();
  const navData = [
    {
      name: "Home",
      link: WEBSITE_DASHBOARD,
    },
    {
      name: "Live Classes",
      link: WEBSITE_LIVE_CLASS_PAGE,
    },
    {
      name: "Self Learning",
      link: WEBSITE_COURSES_PAGE,
    },
    {
      name: "Workshops",
      link: WEBSITE_WORKSHOP_PAGE,
    },
    {
      name: "Blog",
      link: WEBSITE_BLOG,
    },
  ];

  return (
    <AppBox>
      {/* --------header ----------- */}
      <AppFlex
        customStyles={{
          alignItems: "center",
          justifyContent: { base: "space-between" },
          pl: "2em",
          py: "1em",
          pr: { base: "2em", lg: "2.5em" },
          boxShadow: "inset 0px -1px 0px #D9D9D9",
          position: "sticky",
          top: "0px",
          zIndex: 999,
          background: "#ffffff",
        }}
      >
        <AppBox>
          <Image
            src={logo}
            alt="logo"
            onClick={() => navigate(WEBSITE_DASHBOARD)}
            _hover={{ cursor: "pointer" }}
            loading="lazy"
          />
        </AppBox>
        {isMobile ? (
          <AppBox onClick={onOpen}>
            <img src={menuIcon} alt="menuicon" />
          </AppBox>
        ) : (
          <AppFlex
            customStyles={{
              alignItems: "center",
              gap: { base: "1em", md: "2em" },
              flexShrink: "2",
            }}
          >
            {navData.map((each, i) => {
              if (i > 0) {
                return (
                  <Link to={each.link}>
                    <Text
                      fontSize={fontSizes.md}
                      fontWeight={fontWeights.bold}
                      color={appColors.brandSecondary["500"]}
                      w="fit-content"
                      cursor="pointer"
                    >
                      {each.name}
                    </Text>
                  </Link>
                );
              }
              return null;
            })}
            {/* <a
              href={
                currentUserOS === 'apple' ? APPLE_STORE_LINK : PLAY_STORE_LINK
              }
              _hover={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              <AppButton
                variant="defaultButton"
                customStyles={{ _focus: { outline: 'none' } }}
              >
                Download the App
              </AppButton>
            </a> */}
            <WebButton content="Join Live Class" />
          </AppFlex>
        )}
      </AppFlex>
      {children}
      {/* ------footer ------- */}
      <AppFlex
        customStyles={{
          flexDirection: { base: "column", lg: "row-reverse" },
          p: "1em 2.5em",
          alignItems: "center",
          textAlign: { base: "center", lg: "start" },
          mb: "4em",
          mx: "0em",
          mt: noMargin ? "0px" : "5em",
          justifyContent: { base: "center", lg: "space-between" },
          gap: { lg: "5em" },
          borderTop: `1px solid ${appColors.brandGrey["100"]}`,
          as: "footer",
        }}
      >
        <AppBox customStyles={{ flexBasis: "50%" }}>
          <Flex
            my="2em"
            flexDirection={{ base: "column", sm: "row", lg: "column" }}
            gap="1.2em"
            flexWrap="wrap"
            h={{ base: "initial", lg: "12em" }}
            ml={{ lg: "4em" }}
            alignItems={isFooterLink ? "center" : ""}
          >
            {footerLinks.map((item, i) => {
              if (isFooterLink && i <= 3) {
                return (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: "fit-content" }}
                  >
                    <Text
                      color={appColors.brandSecondary["500"]}
                      fontSize={fontSizes.sm}
                      fontWeight="600"
                      cursor="pointer"
                      width="fit-content"
                    >
                      {item.name}
                    </Text>
                  </a>
                );
              }
              if (!isFooterLink) {
                if (item.name === "Contact Us") {
                  return (
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <Text
                        color={appColors.brandSecondary["500"]}
                        fontSize={fontSizes.sm}
                        fontWeight="600"
                        width="fit-content"
                        cursor="pointer"
                      >
                        {item.name}
                      </Text>
                    </a>
                  );
                }
                return (
                  <Link to={item.link}>
                    <Text
                      color={appColors.brandSecondary["500"]}
                      fontSize={fontSizes.sm}
                      fontWeight="600"
                      width="fit-content"
                      cursor="pointer"
                    >
                      {item.name}
                    </Text>
                  </Link>
                );
              }

              return null;
            })}
            {isDesktop && (
              <a
                href={TRAINER_LOGIN_PAGE}
                target="_blank"
                rel="noreferrer"
                style={{
                  width: "fit-content",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Text
                  color={appColors.brandSecondary["500"]}
                  fontSize={fontSizes.sm}
                  fontWeight="600"
                  cursor="pointer"
                  _hover={{ textDecoration: "none" }}
                >
                  Trainer Login
                </Text>
                <Image src={linkicon} alt="" w="13px" loading="lazy" />
              </a>
            )}
          </Flex>
        </AppBox>
        <AppBox customStyles={{ textAlign: { base: "center", lg: "start" } }}>
          <Flex
            justifyContent={{ base: "center", lg: "start" }}
            alignItems="center"
            mt={4}
          >
            <Box
              onClick={() => navigate(WEBSITE_DASHBOARD)}
              _hover={{ cursor: "pointer" }}
            >
              <img src={logo} alt="logo" />
            </Box>
          </Flex>
          <StoreComponent />
          <AppText
            customStyles={{
              fontSize: fontSizes.xs,
              fontWeight: fontWeights.sm,
              lineHeight: lineHeights.xs,
            }}
          >
            18/1 Ground Floor, Bhakthavachalam Nagar, Adyar, Chennai - 600020
          </AppText>
          <AppText
            customStyles={{
              fontSize: fontSizes.xs,
              fontWeight: fontWeights.sm,
              lineHeight: lineHeights.xs,
            }}
          >
            Reserved by Pratibha P
          </AppText>
        </AppBox>
      </AppFlex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ outline: "none" }} />
          <DrawerBody>
            <VStack mt="5em" spacing="18px" alignItems="flex-start" pl="1">
              {navData.map((each, i) => (
                <Link to={each.link}>
                  <Text
                    color={appColors.brandSecondary["500"]}
                    fontSize="23px"
                    fontWeight={fontWeights.bold}
                    onClick={() => navigate(each.link)}
                    w="100%"
                    borderBottom={
                      navData.length - 1 === i ? "1px solid #D9D9D9" : ""
                    }
                    pb={navData.length - 1 === i ? "1em" : ""}
                  >
                    {each.name}
                  </Text>
                </Link>
              ))}

              {drawerSecondSection.map((each) => (
                <a href={each.link} target="_blank" rel="noreferrer">
                  <Text
                    color={appColors.brandSecondary["500"]}
                    fontSize="23px"
                    fontWeight={fontWeights.bold}
                    w="100%"
                  >
                    {each.name}
                  </Text>
                </a>
              ))}

              <AppFlex
                customStyles={{
                  alignItems: "center",
                  gap: "1em",
                  pt: "1.5em",
                  borderTop: "1px solid #D9D9D9",
                  w: "100%",
                }}
              >
                <AppText
                  customStyles={{
                    color: appColors.brandSecondary["500"],
                    fontSize: "20px",
                    fontWeight: fontWeights.bold,
                  }}
                >
                  Follow us on
                </AppText>
                <SocialLink />
              </AppFlex>
              <a
                href={
                  currentUserOS === "apple" ? APPLE_STORE_LINK : PLAY_STORE_LINK
                }
                target="_blank"
                rel="noreferrer"
              >
                <AppFlex
                  customStyles={{
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <AppText
                    customStyles={{
                      color: appColors.brandSecondary["500"],
                      fontSize: "20px",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Download the App
                  </AppText>
                  <Image
                    loading="lazy"
                    src={
                      currentUserOS === "apple" ? applestorelogo : playstorelogo
                    }
                    alt="instagram logo"
                  />
                </AppFlex>
              </a>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </AppBox>
  );
}

export default AppLayout;
