import appColors from 'theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';

const AppLinkStyles = {
  sizes: {
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.xl,
      lineHeight: lineHeights.xs,
      color: appColors.brandSecondary['500'],
    },
  },
  variants: {

  },
};

export default AppLinkStyles;
