import { theme as baseTheme } from '@chakra-ui/react';

const appBorderRadius = {
  md: '15px',
  sm: '12px',
  xs: '4px',
};

const appBreakPoints = {
  ...baseTheme.breakpoints,
};
export { appBorderRadius, appBreakPoints };
