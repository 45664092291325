import appColors from 'theme/appColors';

const AppInputStyles = {
  baseStyle: {
    height: '48px',
  },
  variants: {

  },
};

export default AppInputStyles;
