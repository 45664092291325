import AppTextStyles from 'theme/components/AppTextStyles';
import AppInputStyles from 'theme/components/AppInputStyles';
import AppButtonStyles from 'theme/components/AppButtonStyles';
import AppLinkStyles from 'theme/components/AppLinkStyles';
import AppBoxStyles from 'theme/components/AppBoxStyles';
import AppFlexStyles from 'theme/components/AppFlexStyles';
import AppMenuStyles from './AppMenuStyle';
import AppTagStyles from './AppTagStyles';

const components = {
  Text: AppTextStyles,
  Input: AppInputStyles,
  Button: AppButtonStyles,
  Link: AppLinkStyles,
  AppBox: AppBoxStyles,
  AppFlex: AppFlexStyles,
  Menu: AppMenuStyles,
  Tag: AppTagStyles,
};

export default components;
