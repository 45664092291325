import { MAKE_WEBSITE_COURSE_DETAIL } from "navigation/routes";
import { useNavigate } from "react-router-dom";

function useNavigateToCourseDetail() {
  const navigate = useNavigate();

  const navigateTo = (categoryName, identity, courseId) => {
    const regex = /\s:\s/g;

    return navigate(
      MAKE_WEBSITE_COURSE_DETAIL(
        categoryName.split(" ").join("-").toLowerCase(),
        identity.replace(regex, " ").split(" ").join("-").toLowerCase()
      ),
      { state: { id: courseId } }
    );
  };

  return navigateTo;
}

export default useNavigateToCourseDetail;
