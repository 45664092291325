import appColors from "theme/appColors";

const AppTagStyles = {
  variants: {
    blogTag: {
      container: {
        borderRadius: "4px",
        fontSize: "19px",
        fontWeight: 400,
        color: "#404040",
        padding: "4px 8px",
        background: "#FFECE5",
      },
    },
  },
};

export default AppTagStyles;
