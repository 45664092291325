import appColors from "theme/appColors";

const AppMenuStyles = {
  variants: {
    blogMenu: {
      list:{
        boxShadow: "0px 0px 7px -1px rgba(0, 0, 0, 0.25)",
      },
      item: {
        padding: "10px 20px",
        fontSize: "19px",
        fontWeight: 600,
        color: appColors.brandGrey[400],
        _hover: {
          background: "#FFECE5",
          color: "#404040",
        },
        _selected: {
          background: "#FFECE5",
          color: "#404040",
        },
        _focus: {
          background: "#FFECE5",
          color: "#404040",
        },
      },
    },
  },
};

export default AppMenuStyles;
