import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  // BreadcrumbSeparator,
  Box,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { fontSizes, fontWeights } from 'theme/fonts';
import appColors from 'theme/appColors';
import { Link } from 'react-router-dom';

function BreadCrumb({
  Grandparent,
  parent,
  GrandparentLink,
  parentLink,
  currentPage,
  textColor,
  position = "absolute",
}) {
  return (
    <Box
      pos={position}
      left={{ base: '2em', md: '3em' }}
      top={{ base: '1.5em', md: '2em' }}
      zIndex="5"
    >
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color={textColor || '#8B5971'} />}
      >
        {Grandparent && (
          <BreadcrumbItem>
            <Link to={GrandparentLink}>
              <BreadcrumbLink
                fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
                fontWeight={{ base: fontWeights.sm, lg: fontWeights.xl }}
                color={textColor || appColors.brandSecondary['500']}
                _focus={{ outline: 'none' }}
                _hover={{ textDecoration: 'none' }}
              >
                {Grandparent}
              </BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem>
          <Link to={parentLink}>
            <BreadcrumbLink
              fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
              fontWeight={{ base: fontWeights.sm, lg: fontWeights.xl }}
              color={textColor || appColors.brandSecondary['500']}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none' }}
            >
              {parent}
            </BreadcrumbLink>
          </Link>
        </BreadcrumbItem>
        {currentPage && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              href="#"
              fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
              fontWeight={{ base: fontWeights.sm, lg: fontWeights.xl }}
              color={textColor || appColors.brandSecondary['500']}
              _focus={{ outline: 'none' }}
              _hover={{ textDecoration: 'none' }}
            >
              {currentPage}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Box>
  );
}

export default BreadCrumb;
