import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';
import appColors from 'theme/appColors';
import { appBorderRadius, appBreakPoints } from 'theme/sizes';

const AppTextStyles = {
  sizes: {
    xxs: {
      fontSize: fontSizes.xxs,
    },
    md: {
      fontSize: fontSizes.md,
      fontWeight: fontWeights.md,
      lineHeight: lineHeights.md,
    },
    sm: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.semibold,
      lineHeight: lineHeights.sm,
    },
    xs: {
      fontSize: fontSizes.xs,
      fontWeight: fontWeights.sm,
      lineHeight: lineHeights.xs,
    },
    xl: {
      fontSize: fontSizes.xl,
      fontWeight: fontWeights.xl,
    },
  },
  variants: {
    bold: {
      fontWeight: fontWeights.bold,
    },
    extraBold: {
      fontWeight: fontWeights.extrabold,
    },
    viewMoreOrLess: {
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.xl,
      color: appColors.brandSecondary['400'],
    },
    headerOne:{
      color:appColors.brandSecondary['700'],
      fontWeight:{ base: '800', md: '700', lg: '800' },
      fontSize: {base: '32px', md: '39px', lg: '56px'} ,
      lineHeight:{ base: '40px', md: '56px', lg: '64px'} ,
    },
    headerTwo:{
      fontWeight:"800",
      fontSize:{ base: '32px', lg: '48px' } ,
      lineHeight:{ base: '40px', lg: '56px'},
      color:appColors.brandSecondary['700'],
    },
    headerThree:{
      color:"white",
      fontWeight:"800",
      fontSize:{base: '32px', md: '48px' },
      lineHeight:{base: '42px', md: '56px' },
    },
    descriptionText: {
      fontWeight:fontWeights.sm,
      color:appColors.brandGrey['700'],
      fontSize:{ base: fontSizes.sm, lg: fontSizes.md },
      lineHeight:{ base: lineHeights.sm, lg: lineHeights.md },
    },
    subHeader:{
      fontWeight:fontWeights.bold,
      fontSize:{ base: fontSizes.xl, lg: '38px' },
      lineHeight:{ base: '32px', lg: '52px' },
      color: appColors.brandSecondary['700'],
    },
    smallHeader:{
      color:appColors.brandPrimary['300'],
      fontSize:fontSizes.xs,
      fontWeight:fontWeights.extrabold,
    },
    blogHeaderDesciption:{
      fontSize: { base: "19px", lg: "19px"},
      fontWeight: 400,
    }
  },
};

export default AppTextStyles;
