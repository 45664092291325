import React from 'react';
import Platform from 'react-platform-js'

function useGetCurrentUserOs() {
  const [currentUserOS, setCurrentUserOS] = React.useState('apple');

  React.useEffect(() => {
  //   const isAppleDevice = navigator.userAgent.includes('Macintosh');
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   // for mobile devices find the os user is using mac or android
  //   // NOTE : set default to play store
  //   if (
  //     (isAppleDevice && navigator.maxTouchPoints >= 1 ) ||
  //     (navigator.maxTouchPoints >= 1 && isAppleDevice)||
  //     (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
  //     userAgent.includes('iPhone') ||
  //     (userAgent.includes('Mac') && 'ontouchend' in document)
  //   ) {
  //     setCurrentUserOS('apple');
  //   }

  //   // for laptop and pc if the user uses mac os set download link to apple store link
  //   // when testing out the moblie environment using above code comment out below code
  //   if (
  //     navigator?.userAgentData?.platform.indexOf('Mac') > -1 ||
  //     navigator?.platform.indexOf('Mac') > -1
  //   ) {
  //     setCurrentUserOS('apple');
  //   }
const OS_NAME = Platform.OS

//  if(OS_NAME === "iOS" || OS_NAME === 'Mac OS'){
//   setCurrentUserOS("apple")
//  }
console.log(OS_NAME)

if(OS_NAME === 'Android'){
  setCurrentUserOS('android')
}


  }, []);


  return { currentUserOS };
}

export default useGetCurrentUserOs;
