import { VStack, Box, Text } from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';
import { fontSizes, fontWeights } from 'theme/fonts';
// import appColors from 'theme/appColors';
import AppLayout from './AppLayout';
import BreadCrumb from './BreadCrumb';

function WebRelatedPagesWrapper({
  breadCrumbContent,
  pageData,
  children,
  moreHeight,
}) {
  return (
    <AppLayout>
      <VStack
        pos="relative"
        h={moreHeight ? '330px' : '211px'}
        backgroundColor="#4D313D"
        justifyContent="center"
      >
        <BreadCrumb
          parent={breadCrumbContent.parent}
          parentLink={breadCrumbContent.parentLink}
          currentPage={breadCrumbContent.currentPage}
        />
        <Box>
          <Text
            as="h1"
            textAlign="center"
            variant="headerThree"
          >
            {pageData.title}
          </Text>
          {pageData.description && (
            <>
              <Text
                textAlign="center"
                py="1em"
                fontWeight={fontWeights.sm}
                fontSize={fontSizes.sm}
                color={appColors.brandSecondary['100']}
              >
                {pageData.description}
              </Text>
              {/* <Flex
                gap="0.5em"
                alignItems="center"
                mx="auto"
                w="fit-content"
                bg="white"
                border={`1px solid ${appColors.brandSecondary['300']}`}
                color={appColors.brandSecondary['400']}
                p="0.1em 0.5em"
                borderRadius="8px"
              >
                <Image src={pageData.buttonData.img} alt="" />
                <Text fontWeight={fontWeights.xl} fontSize={fontSizes.md}>
                  {pageData.buttonData.name}
                </Text>
              </Flex> */}
            </>
          )}
        </Box>
      </VStack>
      {children}
    </AppLayout>
  );
}

export default WebRelatedPagesWrapper;
