import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  // AccordionIcon,
  Image,
  Text,
  Box,
} from '@chakra-ui/react';
import { dangerouslySetHtml } from 'components/dangerouslySetHtml';
import { fontWeights, fontSizes, lineHeights } from 'theme/fonts';
import appColors from 'theme/appColors';
import arrowdown from '../assets/dashboard/arrow-down.svg';
import arrowright from '../assets/dashboard/arrow-right.svg';

function FaqAccordian({ noTitle, faqdata }) {
  const Arr = faqdata || [1, 2, 3, 4, 5];
console.log(faqdata)
  return (
    <Box>
      {!noTitle && (
        <Text
          as="h1"
          size="xl"
          color={appColors.brandSecondary['700']}
          mb="1em"
        >
          Frequently asked Questions
        </Text>
      )}
      <Accordion allowToggle>
        {Arr.map((each) => (
          <AccordionItem border="0">
            {({ isExpanded }) => (
              <>
                <Box>
                  <AccordionButton
                    display="flex"
                    justifyContent="start"
                    flexDirection="row-reverse"
                    alignItems="flex-start"
                    paddingLeft="0"
                    _focus={{ outline: 'none' }}
                    gap="1em"
                  >
                    <Text
                      fontWeight={{
                        base: fontWeights.semibold,
                        lg: fontWeights.xl,
                      }}
                      fontSize={{
                        base: fontSizes.sm,
                        lg: fontSizes.md,
                      }}
                      color={
                        isExpanded
                          ? appColors.brandGrey['700']
                          : appColors.brandSecondary['500']
                      }
                      textAlign="start"
                      as="h2"
                    >
                      {faqdata
                        ? (each.identity || each.question)
                        : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece'}
                    </Text>
                    {isExpanded ? (
                      <Image src={arrowdown} alt="" mt="0.4em" />
                    ) : (
                      <Image src={arrowright} alt="" mt="0.4em" />
                    )}
                  </AccordionButton>
                </Box>
                {faqdata ? (
                  <AccordionPanel
                    pb={4}
                    style={{
                      fontWeight: fontWeights.sm,
                      lineHeight: lineHeights.sm,
                      color: appColors.brandGrey['700'],
                      fontSize: fontSizes.sm,
                      paddingLeft: '2.6em',
                    }}
                    dangerouslySetInnerHTML={dangerouslySetHtml(each.content || each.answer)}
                  />
                ) : (
                  <AccordionPanel
                    pb={4}
                    style={{
                      fontWeight: fontWeights.sm,
                      lineHeight: lineHeights.sm,
                      color: appColors.brandGrey['700'],
                      fontSize: fontSizes.sm,
                      paddingLeft: '2.6em',
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default FaqAccordian;
