import React, { createRef } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import AppLayout from "components/AppLayout";
import AppBox from "components/chakraLayoutOverrides/AppBox";
import AppText from "components/Typography/AppText";
import appColors from "theme/appColors";
import { getRequestWeb } from "api/utils/getRequestWeb";
import {
  GET_BLOG_CATEGORIES,
  GET_BLOG_LIST,
  GET_SLIDER_VIDEOS,
  GET_WEBSITE_COURSES_LIST,
} from "api/urls";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppLoader from "components/AppLoader/AppLoader";
import { headerBottomBg, playBtn, headerTopBgBlog } from "assets/index";
import MetaData from "components/MetaData";
import SocialLink from "components/SocialLink";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import BlogPreviewCard from "components/Blog/BlogPreviewCard";

function BlogListingPage() {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");

  const [search, setSearch] = React.useState("");

  const [playingVideo, setPlayingVideo] = React.useState({
    id: null,
    isPlaying: false,
  });

  const { category } = useParams();

  // console.log("category", category);

  const { data: blogList, isLoading: blogListisLoading } = useQuery(
    GET_BLOG_LIST(category || ""),
    () => getRequestWeb(GET_BLOG_LIST(category || ""))
  );

  const { data: categoryList, isLoading: categoryListIsLoading } = useQuery(
    GET_BLOG_CATEGORIES,
    () => getRequestWeb(GET_BLOG_CATEGORIES)
  );

  const { data, isLoading } = useQuery(GET_WEBSITE_COURSES_LIST, () =>
    getRequestWeb(GET_WEBSITE_COURSES_LIST)
  );

  const { data: homeData } = useQuery(GET_SLIDER_VIDEOS, () =>
    getRequestWeb(GET_SLIDER_VIDEOS)
  );
  const sliderData = data?.data?.data.courses_data;
  const videoSliderData = homeData?.data?.data.footer_videos;

  const refVals = videoSliderData?.map(() => createRef());

  const videoSlider = videoSliderData
    ? videoSliderData.map((each, i) => ({
        ...each,
        ref: refVals[i],
      }))
    : [];

  // eslint-disable-next-line no-nested-ternary
  const sliderCount = isLargerThan1280 ? 4 : isLargerThan450 ? 2.5 : 1.23;
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: sliderCount,
      spacing: 15,
      initial: 0,
    },
  });

  // function that handle play/pause of the videos in slider
  const handleVideo = (clickedid) => {
    let currentRef;
    videoSlider.map((item) => {
      if (clickedid === item.id) {
        currentRef = item.ref;
      }
      return null;
    });

    if (playingVideo.id === clickedid && playingVideo.isPlaying) {
      currentRef.current.pause();
      currentRef.current.removeAttribute("controls");
      setPlayingVideo({ id: null, isPlaying: false });
    } else if (playingVideo.isPlaying && playingVideo.id !== clickedid) {
      videoSlider.map((item) => {
        if (item.id === playingVideo.id) {
          item.ref.current.pause();
          item.ref.current.removeAttribute("controls");
        }
        return null;
      });
      currentRef.current.play();
      currentRef.current.setAttribute("controls", "");
      setPlayingVideo({ id: clickedid, isPlaying: true });
    } else {
      currentRef.current.play();
      currentRef.current.setAttribute("controls", "");
      setPlayingVideo({ id: clickedid, isPlaying: true });
    }
  };

  const cardDummyData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

  const [cardsToShow, setCardsToShow] = React.useState(12);
  const postLength = cardDummyData?.length;

  const showMore = () => {
    if (postLength - cardsToShow > 3) {
      setCardsToShow((prev) => prev + 6);
    } else {
      setCardsToShow((prev) => prev + postLength - cardsToShow);
    }
  };

  const filteredBlogs = React.useMemo(() => {
    if (!blogList?.data?.data) {
      return [];
    }

    return blogList?.data?.data?.filter((blog) => {
      const { identity, short_description } = blog;
      const searchValue = search?.toLowerCase();

      return (
        identity?.toLowerCase()?.includes(searchValue) ||
        short_description?.toLowerCase()?.includes(searchValue)
      );
    });
  }, [blogList, search]);

  return (
    <AppLayout>
      <MetaData
        metaTitle="Read, learn and explore a wide range of topics on Carnatic Music and Voice Culture"
        metaDescription="Dive into the depths of Carnatic Music and Voice Culture:  Explore, learn, and enrich your knowledge with expert guidance by top Gurus"
      />
      <AppLoader isLoading={isLoading || blogListisLoading}>
        {!isLoading && (
          <>
            <Box
              as="header"
              bg={appColors.brandPrimary["50"]}
              py={8}
              backgroundImage={`url(${headerTopBgBlog}), url(${headerBottomBg})`}
              backgroundRepeat="no-repeat, no-repeat"
              backgroundSize={{
                base: "389px,294px",
                md: "449px, 342px",
                lg: "480px , 712px",
              }}
              backgroundPosition={{
                base: "top -45vw left -214px,bottom -114% right -163px",
                sm: "top -25vw left -120px, bottom 35% right -70px",
                md: "top -22vw left -187px,bottom 111% right -112px",
                lg: "top -10vw left -274px,bottom 106% right -430px",
              }}
            >
              <VStack
                w={{ base: "80%", md: "initial" }}
                mx="auto"
                pos="relative"
              >
                <Flex
                  justifyContents="center"
                  alignItems="center"
                  flexDirection="column"
                  w="60%"
                  my={{ base: "1em", md: "2em" }}
                  height="auto"
                  pt="2em"
                >
                  <Text
                    as="h1"
                    textAlign="center"
                    m="0"
                    minW={{ base: "70vw", md: "515" }}
                    mb={4}
                    variant="headerOne"
                  >
                    VoxGuru Blog
                  </Text>
                  <Text
                    variant="blogHeaderDesciption"
                    textAlign={"center"}
                    maxW={"600px"}
                  >
                    A gateway to explore the latest and thought-provoking
                    content on music. Get expert guidance all the way, from
                    practice to performance.
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent={{ base: "center", lg: "start" }}
                    gap="1em"
                    my="1em"
                  ></Flex>
                </Flex>
              </VStack>
            </Box>

            {/* --- Blog listing body -------- */}
            <Box
              width={"100%"}
              maxW="1300px"
              mx={["auto", "auto"]}
              pt="40px"
              px={["15px", "10px"]}
            >
              {/* <Flex
                justifyContent={"space-between"}
                rowGap={"20px"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <Box>
                  <BlogSearch search={search} setSearch={setSearch} />
                </Box>
                <Box>
                  <SelectCategory categoryList={categoryList?.data?.data} />
                </Box>
              </Flex> */}
              <Box>
                <Flex
                  flexWrap={"wrap"}
                  justifyContent={["center", "flex-start", "flex-start"]}
                  gap={"40px"}
                  mt="40px"
                >
                  {/* {Blog card listing} */}

                  {filteredBlogs.length > 0 ? (
                    <>
                      {filteredBlogs?.map((card) => (
                        <BlogPreviewCard key={card} cardData={card} />
                      ))}
                    </>
                  ) : (
                    <Text
                      width={"100%"}
                      textAlign={"center"}
                      color={"#CDB1C1"}
                      fontSize={"20px"}
                      fontWeight={500}
                    >
                      No Results Found!
                    </Text>
                  )}
                </Flex>
                {/* <Flex justifyContent={"center"} alignItems={"center"} mt="30px">
                {postLength === cardsToShow || postLength <= 6 ? (
                        ""
                    ) : (
                      <Button onClick={()=>showMore()} variant="defaultButton" fontSize={"19px"} padding={"10px 20px"}>Show More</Button>
                    )}
                  
                </Flex> */}
              </Box>
            </Box>

            <AppBox
              customStyles={{
                my: "2em",
                pt: "3em",
              }}
            >
              <VStack w="90%" mx="auto">
                <AppText
                  customStyles={{
                    variant: "subHeader",
                    as: "h2",
                  }}
                >
                  Join the tribe!
                </AppText>
                <AppText
                  customStyles={{
                    variant: "descriptionText",
                    w: "80%",
                    textAlign: "center",
                  }}
                >
                  Explore an extensive catalogue of self-learning courses
                  covering the fundamentals to advanced vocal techniques in a
                  uniqely visual narrative.{" "}
                </AppText>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  gap="1em"
                  pb="2em"
                  pt="0.7em"
                >
                  <SocialLink />
                </Flex>
                {homeData && (
                  <Box ref={sliderRef} className="keen-slider">
                    {videoSlider.map((item, i) => (
                      <Box
                        className={`keen-slider__slide number-slide${i + 1}`}
                      >
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                          width="350"
                          height="564"
                          preload="metadata"
                          style={{ borderRadius: "8px" }}
                          ref={item.ref}
                          onClick={(e) => {
                            e.preventDefault();
                            handleVideo(item.id);
                          }}
                        >
                          <source
                            src={`${item.video}#t=0.001`}
                            type="video/mp4"
                          />
                        </video>
                        {playingVideo.id !== item.id && (
                          <Image
                            src={playBtn}
                            alt="play button"
                            w={{ base: "2.5em", md: "4em" }}
                            h={{ base: "2.5em", md: "4em" }}
                            pos="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            cursor="pointer"
                            onClick={() => handleVideo(item.id)}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </VStack>
            </AppBox>
          </>
        )}
      </AppLoader>
    </AppLayout>
  );
}

export default BlogListingPage;
