export const WHATSAPP_URL =
  "https://api.whatsapp.com/send?phone=919840243715&text=Hello%20VoxGuru,%20I'm%20looking%20for%20online%20music%20lessons!";

export const GOOGLE_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLScrFWjIf6l7cQ6hPLPm-XxLywCVRL_dLGWTGCmEOxIhA5zfjA/viewform';

export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.voxguru&hl=en_IN';

export const APPLE_STORE_LINK =
  'https://apps.apple.com/us/app/voxguru/id1299389665';

export const TRAINER_LOGIN_PAGE = 'https://trainerv2.voxguru.in/trainer/login';

export const INSTAGRAM_LINK = "https://www.instagram.com/voxguruofficial/";

export const FACEBOOK_LINK = "https://www.facebook.com/voxguruofficial";