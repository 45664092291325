import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Flex,
  useBreakpointValue,
  useMediaQuery,
  Img,
} from "@chakra-ui/react";
import AppLayout from "components/AppLayout";
import AppText from "components/Typography/AppText";
import appColors from "theme/appColors";
import { getRequestWeb } from "api/utils/getRequestWeb";
import { GET_BLOG_LIST, GET_DETAILED_BLOG } from "api/urls";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppLoader from "components/AppLoader/AppLoader";
import { headerBottomBg, headerTopBgBlog, BlogQuote } from "assets/index";
import MetaData from "components/MetaData";
import "keen-slider/keen-slider.min.css";
import BlogMetaContainer from "components/Blog/BlogMetaContainer";
import Breadcrumb from "components/BreadCrumb";
import { WEBSITE_BLOG, WEBSITE_DASHBOARD } from "navigation/routes";
import BlogDetailAside from "components/Blog/BlogDetailAside";
import MoreBlogs from "components/Blog/MoreBlogs";
import { WHATSAPP_URL } from "api/urls/URL";
import whatsappLogo from "../../../assets/dashboard/whatsapp-logo.svg";

function DetailedBlog() {
  const { blogId } = useParams();
  const containerRef = React.useRef(null);
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const bgHeroRef = useRef();

  const { data: blog, isLoading: blogisLoading } = useQuery(
    GET_DETAILED_BLOG(blogId),
    () => getRequestWeb(GET_DETAILED_BLOG(blogId)),
    {
      onSuccess: (res) => {
        // console.log(res);
        containerRef.innerHTML = res?.data?.data?.content;
      },
    }
  );

  const { data: blogList, isLoading: blogListisLoading } = useQuery(
    GET_BLOG_LIST(""),
    () => getRequestWeb(GET_BLOG_LIST(""))
  );

  React.useEffect(() => {
    if (
      blog?.data?.data?.content &&
      blogisLoading == false &&
      blogisLoading == false
    ) {
      const container = document.getElementById("blogBody");

      if (container) {
        container.innerHTML = blog?.data?.data?.content;

        const containerNode = containerRef.current;
        const blockquotes = containerNode?.getElementsByTagName("blockquote");
        Array?.from(blockquotes)?.forEach((blockquote) => {
          blockquote.classList.add("blogquote");
          const img = document.createElement("img");
          img.src = BlogQuote;
          img.alt = BlogQuote;
          img.classList.add("blog-quote-img");
          blockquote.appendChild(img);
        });

        if (blog?.data?.data?.content_image) {
          const firstParagraph = container.querySelector("h1");
          firstParagraph?.setAttribute("style", "margin:1em 0 1.5em 0;");
          if (firstParagraph) {
            const div = document.createElement("div");
            const img = document.createElement("img");
            const p = document.createElement("p");
            div.appendChild(img);
            div.appendChild(p);
            div.classList.add("content-img-container");
            img.src = blog?.data?.data?.content_image;
            img.alt = "Image Alt Text";
            img.classList.add("content-img");
            firstParagraph.insertAdjacentElement("afterend", div);
            if (blog?.data?.data?.content_image_text) {
              p.innerHTML = blog?.data?.data?.content_image_text;
            }
          }
        }
      }
    }
  }, [blog, blogisLoading, blogListisLoading]);

  const blogData = blog?.data?.data;

  const mobileStyles = isMobile
    ? {
        py: 8,
        bg: appColors.brandPrimary["50"],
        width: "100%",
        backgroundImage: `url(${headerTopBgBlog}), url(${headerBottomBg})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "260px,218px",
        backgroundPosition: "top 61vw left -195px,bottom 93% right -134px",
      }
    : {};

  return (
    <AppLayout noMargin={true}>
      <MetaData
        metaTitle="Read, learn and explore a wide range of topics on Carnatic Music and Voice Culture"
        metaDescription="Dive into the depths of Carnatic Music and Voice Culture:  Explore, learn, and enrich your knowledge with expert guidance by top Gurus"
      />
      <AppLoader isLoading={blogListisLoading || blogisLoading}>
        {!false && (
          <>
            <Box
              as="header"
              marginBottom="3em"
              paddingTop={8}
              {...mobileStyles}
            >
              {!isMobile && (
                <Box
                  ref={bgHeroRef}
                  height={"732px"}
                  display={"block"}
                  pos="absolute"
                  top="-32px"
                  py={8}
                  pt="40px"
                  bg={appColors.brandPrimary["50"]}
                  width="100%"
                  backgroundImage={`url(${headerTopBgBlog}), url(${headerBottomBg})`}
                  backgroundRepeat="no-repeat, no-repeat"
                  backgroundSize={{
                    base: "260px,218px",
                    md: "449px, 342px",
                    lg: "482px,656px",
                  }}
                  backgroundPosition={{
                    base: "top 61vw left -195px,bottom 93% right -134px",
                    sm: "top 61vw left -195px,bottom 93% right -134px",
                    md: "top -22vw left -187px,bottom 121% right -112px",
                    lg: "top 13vw left -316px,bottom 62% right -395px",
                  }}
                ></Box>
              )}
              <VStack
                mx="auto"
                pos="relative"
                width={"100%"}
                maxW="1300px"
                px={["20px", "10px"]}
                alignItems={"start"}
              >
                <Box>
                  <Breadcrumb
                    Grandparent="Home"
                    GrandparentLink={WEBSITE_DASHBOARD}
                    parent="Blog"
                    parentLink={WEBSITE_BLOG}
                    currentPage={blogData?.category?.identity}
                    textColor={"#404040"}
                    position="static"
                  />
                  <Flex
                    justifyContent="center"
                    alignItems="start"
                    flexDirection="column"
                    w={"100%"}
                    maxW={"1050px"}
                    pt="8px"
                    alig
                  >
                    <Text
                      as="h1"
                      textAlign="left"
                      m="0"
                      mb={4}
                      variant="headerOne"
                    >
                      {blogData?.identity}
                    </Text>
                    <Text
                      variant="blogHeaderDesciption"
                      textAlign={"left"}
                      width={["92%", "77%"]}
                    >
                      {blogData?.short_description}
                    </Text>
                    <Flex
                      alignItems="center"
                      justifyContent={{ base: "center", lg: "start" }}
                      gap="1em"
                      my="1em"
                    ></Flex>
                  </Flex>
                  <Box>
                    <BlogMetaContainer blogData={blogData} />
                    <Box mt="1em">
                      <Image
                        display={["none", "block"]}
                        width={"200%"}
                        maxW={"1300px"}
                        src={blogData?.cover_image}
                        borderRadius={"6px"}
                        h="100%"
                        maxH={"689px"}
                      />
                    </Box>
                  </Box>
                </Box>

                {/* --- Blog body -------- */}
                {!isMobile && (
                  <Box
                    width={"100%"}
                    maxW="1300px"
                    mx={["auto", "auto"]}
                    px={["20px", "10px"]}
                    pb="10px"
                    mt="22%"
                    // border="18px solid blue"
                  >
                    <Box>
                      <Flex
                        gap="40px"
                        mt="50px"
                        flexWrap={["wrap", "wrap", "nowrap"]}
                      >
                        {/* {CMS Data} */}
                        <Box
                          className="blog-cms-body"
                          width={"100%"}
                          maxW={"754px"}
                          ref={containerRef}
                          id={"blogBody"}
                          mt="0"
                        ></Box>

                        {/* {Aside part} */}
                        <Box>
                          <Text
                            mb="15px"
                            fontSize={["24px", "33px"]}
                            fontWeight={700}
                            as={"h2"}
                          >
                            Check out latest courses on offer
                          </Text>
                          <BlogDetailAside />
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                )}
              </VStack>
            </Box>
            {isMobile && (
              <Box
                width={"100%"}
                maxW="1300px"
                mx={["auto", "auto"]}
                px={["20px", "10px"]}
                pb="10px"
                mt="1%"
              >
                <Box>
                  <Flex
                    gap="40px"
                    mt="50px"
                    flexWrap={["wrap", "wrap", "nowrap"]}
                  >
                    {/* {CMS Data} */}
                    <Box
                      className="blog-cms-body"
                      width={"100%"}
                      maxW={"754px"}
                      ref={containerRef}
                      id={"blogBody"}
                      mt="0"
                    ></Box>

                    {/* {Aside part} */}
                    <Box>
                      <Text
                        mb="15px"
                        fontSize={["24px", "33px"]}
                        fontWeight={700}
                        as={"h2"}
                      >
                        Check out latest courses on offer
                      </Text>
                      <BlogDetailAside />
                    </Box>
                  </Flex>
                </Box>
              </Box>
            )}
            {/* </Box> */}
            {isMobile && (
              <Box
                pos="sticky"
                bottom="0%"
                height={"55px"}
                backgroundColor={appColors.brandPrimary["300"]}
                color={appColors.brandWhite["900"]}
                py="1em"
                textAlign={"center"}
                zIndex={2}
              >
                <a href={WHATSAPP_URL} target="_blank" rel="noreferrer">
                  <Flex justifyContent={"center"} gap={"10px"}>
                    <Img src={whatsappLogo} alt="whatsapp-logo" />
                    <Text fontSize="16px" fontWeight={800}>
                      Chat With Us
                    </Text>
                  </Flex>
                </a>
              </Box>
            )}

            <MoreBlogs blogList={blogList} currBlodId={blogData?.id} />
          </>
        )}
      </AppLoader>
    </AppLayout>
  );
}

export default DetailedBlog;
