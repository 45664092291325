import { Box, Flex, Image, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { BlogThumbnail } from "assets";
import { Link } from "react-router-dom";
import AppText from "components/Typography/AppText";

const BlogPreviewCard = ({ cardData }) => {
  const tags = ["Ragas", "Pallavi"];

  const getProperDate = (rawDate) => {
    const date = new Date(rawDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  return (
    <Link to={`/blog/${cardData?.slug}`}>
      <Box
        background={"white"}
        width={["100%", "394px"]}
        // maxW={"394px"}
        border={"2px solid #E87663"}
        padding={"14px"}
        borderRadius={"5px"}
        paddingBottom={"16px"}
        // height={"100%"}
        position={"relative"}
        height={["102%", "512px"]}
      >
        <Box>
          <Image width={"100%"} height={"100%"} maxH={"210px"} src={cardData?.thumbnail_image} />
        </Box>
        <Flex mt="21px" gap={"8px"} aria-label="tags-container">
          {/* {tags?.map((tag) => (
            <Tag variant="blogTag">{tag}</Tag>
          ))} */}
          <Tag variant="blogTag">{cardData?.category?.identity}</Tag>
        </Flex>
        <Box>
          <Text
            noOfLines={[2, 2]}
            mt="9px"
            aria-label="blog-preview-title"
            fontSize="23px"
            fontWeight={700}
          >
            {cardData?.identity}
          </Text>
          <Text
            mb="36px"
            color={"#404040"}
            noOfLines={[3, 3]}
            mt="10px"
            aria-label="blog-preview-desc"
            fontSize="19px"
            fontWeight={400}
          >
            {cardData?.short_description}
          </Text>
          <Flex
            borderTop={"2px solid #E87663B2"}
            pt="15px"
            justifyContent={"space-between"}
            color={"#404040"}
            fontSize={"16px"}
            fontWeight={500}
            position={"absolute"}
            bottom={"15px"}
            left="0px"
            width={"93%"}
            mx="14px"
          >
            <Text>{getProperDate(cardData?.created)}</Text>
            <Flex gap={"6px"}>
              by
              <Text fontWeight={700} fontSize={"16px"}>
                {cardData?.author}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Link>
  );
};

export default BlogPreviewCard;
