import handIcon from "../../assets/dashboard/handIcon.svg";
import liveIcon from "../../assets/dashboard/liveIcon.svg";
import badgeIcon from "../../assets/dashboard/badgeIcon.svg";
import recorderIcon from "../../assets/dashboard/recorderIcon.svg";
import certifications from "../../assets/dashboard/certifications.svg";
import clearGoals from "../../assets/dashboard/clear-goals.svg";
import smallBatches from "../../assets/dashboard/small-batches.svg";
import byMasters from "../../assets/dashboard/by-masters.svg";
import {
  WEBSITE_COURSES_PAGE,
  WEBSITE_LIVE_CLASS_PAGE,
  WEBSITE_WORKSHOP_PAGE,
} from "navigation/routes";
import { voxpro, liveclasses, flexivox } from "assets/index";

export const attributessection = [
  {
    img: liveclasses,
    title: "Live Classes",
    description: "Get personal attention from the best professional Gurus.",
    link: WEBSITE_LIVE_CLASS_PAGE,
    linkText: "Book a live class",
  },
  {
    img: flexivox,
    title: "Self Learning",
    description: "Learn at your own pace with our engaging app based courses.",
    link: WEBSITE_COURSES_PAGE,
    linkText: "Explore courses",
  },
  {
    img: voxpro,
    title: "Workshops",
    description: "Attend workshops by celebrity artists and master trainers.",
    link: WEBSITE_WORKSHOP_PAGE,
    linkText: "View workshops",
  },
];

export const advantages = [
  {
    img: byMasters,
    title: "Designed by maestros, driven by masters",
    description: "Learn with the confidence that you are in the best hands",
  },
  {
    img: clearGoals,
    title: "Modular programs with clear goals",
    description:
      "Measure your prgoress with clearly defined goals and objectives",
  },
  {
    img: smallBatches,
    title: "Peer learning in small batches",
    description:
      "Motivate and inspire each other in a collaborative learning experience",
  },
  {
    img: certifications,
    title: "Grades and certifications",
    description:
      "Get recognized for your talent with our  premiere grades and certification ",
  },
];

export const TabelData = [
  {
    firstHead: "Conventional classes",
    firstDescription: "Learning driven by individual teachers",
    secondHead: "VOXLIVE ADVANTAGE",
    secondDescription:
      "Curriculum designed by maestros through extensive research including innovative elements to make learning most effective.",
  },
  {
    firstHead: "Conventional classes",
    firstDescription:
      "Absence of well defined levels and assessment of progress",
    secondHead: "VOXLIVE ADVANTAGE",
    secondDescription:
      "A graded curriculum with clearly defined levels and a detailed assessment at the end of each grade.",
  },
  {
    firstHead: "Conventional classes",
    firstDescription:
      "Course content shared informally and difficult to access ",
    secondHead: "VOXLIVE ADVANTAGE",
    secondDescription:
      "Modular course content in the form of premium e-books and audio references, easily accessible on the VoxGuru app.",
  },
  {
    firstHead: "Conventional classes",
    firstDescription: "Singular learning journey",
    secondHead: "VOXLIVE ADVANTAGE",
    secondDescription:
      "Cohort based learning and opportunities to participate in projects commissioned by VoxGuru",
  },
];

export const features = [
  {
    title: "Live Zoom Sessions",
    img: liveIcon,
  },
  {
    title: "Interactive Participation",
    img: handIcon,
  },
  {
    title: "Participation Certificate",
    img: badgeIcon,
  },
  {
    title: "Session Recordings",
    img: recorderIcon,
  },
];

export const headerData = [
  {
    title: "Visual learning",
    img: "visual-learning.svg",
    description: "Beautiful infographics that  explain complex concepts.",
    mobileText: "Visual learning",
  },
  {
    title: "Modular content",
    img: "modular-content.svg",
    mobileText: "Modular content",
    description: "Lets you choose the pace of learning that suites you.",
  },

  {
    title: "Confidence tracking",
    img: "thumbs-up.svg",
    mobileText: "Confidence tracking",
    description: "To help you reflect on your strengths and weaknesses.",
  },
  {
    title: "Practice modules",
    img: "practical-guides.svg",
    mobileText: "Practice modules",
    description: "Because practice makes everything perfect!",
  },
];
export const workshopDetailHighlights = [
  {
    asideList: [
      "Overview",
      "What you will learn",
      "Highlights",
      "Your Instructor",
      "Value Bundles",
    ],
    highlights: [
      {
        img: "../images/live.svg",
        title: "Live Zoom Session",
        description:
          "Expereince the workshop online from anywhere in the world.",
      },
      {
        img: "../images/participation.svg",
        title: "Interactive Participation",
        description:
          "Interact with the artist and even get a chance to sing along when called!",
      },
      {
        img: "../images/certificates.svg",
        title: "Certificates",
        description:
          "You will get a certificate for your participation in the workshop.",
      },
      {
        img: "../images/recording.svg",
        title: "Recordings",
        description:
          "Access the recordings of the workshop sessions whenever needed. ",
      },
    ],
  },
];

export const CourseDetailData = [
  {
    asideList: [
      "Overview",
      "What you will learn",
      "Highlights",
      "Your Instructor",
      "Value Bundles",
    ],

    highlights: [
      {
        img: "../images/modular-content.svg",
        title: "Daily workbook",
        description: "For a guided, personalised learning experience. ",
      },
      {
        img: "../images/thumbs-up.svg",
        title: "Confidence tracking",
        description: "To help you reflect on your strengths and weaknesses.",
      },
      {
        img: "../images/practical-guides.svg",
        title: "Practice modules",
        description: "Because practice makes everything perfect!",
      },
      {
        img: "../images/visual-learning.svg",
        title: "Visual learning",
        description: "Beautiful infographics that  explain complex concepts.",
      },
    ],
  },
];

export const liveClassModalData = [
  {
    courseName: "Carnatic Classes",
    description:
      "Get closer to your roots with an authentic Carnatic experience designed for singers of all levels. Build a strong foundation and master your skills through voice, ear and Raga training.",
    grade: [
      {
        id: "214crj6",
        gradeTitle: "Carnatic Grade 1",
        duration: "3 months",
        lists: [
          "Learning the basics of ‘Shruti’ and ‘Tala’",
          "Mastering the 7 swaras of Mayamalavagoulai",
          "Developing stability and strength in the voice",
          "Clarity in pronunciation of various syllables",
          "Training the ear to recognise notes",
          "Learning a simple composition (Nottuswaram) to develop musical skills",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "No prerequisites for Grade 1.",
          "Anyone who loves music can join!",
        ],
        objectives: [
          "Learning the basics of ‘Shruti’ and ‘Tala’",
          "Mastering the 7 swaras of Mayamalavagoulai",
          "Developing stability and strength in the voice",
          "Clarity in pronunciation of various syllables",
          "Training the ear to recognise notes",
          "Learning a simple composition (Nottuswaram) to develop musical skills",
        ],
        detailedPlan: {
          theory: [
            "Introduction to Carnatic Music",
            "Basics of pitch and rhythm",
            "Tanpura app settings for daily practice",
            "Understanding the Swara & Tala notation",
            "Concepts of Ear Training",
            "Introduction to Akaram",
          ],
          practical: [
            "Sa-Pa-Sa warmup exercise",
            "Developing breath control and stability in voice",
            "Basic Ear Training & Note recognition",
            "Sarali Varisais : 1-7",
            "Mastering the 3 speeds of Adi Tala",
            "Singing the varisias into Akaram for voice and ear training",
            "Nottuswaram - a simple composition by Dikshitar",
          ],
        },
      },
      {
        id: "fwafe43422",
        gradeTitle: "Carnatic Grade 2",
        duration: "3 months",
        lists: [
          "Learning non-linear exercises using the 7 swaras",
          "Opening up the voice through Akaram training",
          "Learning nottuswaram by Dikshitar",
          "Ear Training to identify random patterns",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Fluency with Sarali varisais 1-7",
          "Understanding of the notes of Mayamalavagoula",
        ],
        objectives: [
          "Learning non-linear exercises using the 7 swaras",
          "Opening up the voice through Akaram training",
          "Learning nottuswaram by Dikshitar",
          "Ear Training to identify random patterns",
        ],
        detailedPlan: {
          theory: ["Basics of Ear Training", "Do’s and don’ts of Akaram"],
          practical: [
            "Madhya Sthayi varisais 1-7",
            "Akaram training for voice culture",
            "Nottuswaram by Dikshitar",
            "Mastering 3 speeds in Tala",
            "Exercises to identify random swara patterns",
          ],
        },
      },
      {
        id: "gew334",
        gradeTitle: "Carnatic Grade 3",
        duration: "3 months",
        lists: [
          "Techniques to develop vocal flexibility and agility",
          "Developing strength and emphasis on notes",
          "Learning Nottuswaram by Dikshitar",
          "Learning to build patterns from a basic phrase",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Basic understanding of Shruti and Adi Tala",
          "Fluency with Sarali and Madhya Sthayi varisai",
        ],
        objectives: [
          "Techniques to develop vocal flexibility and agility",
          "Developing strength and emphasis on notes",
          "Learning Nottuswaram by Dikshitar",
          "Learning to build patterns from a basic phrase",
        ],
        detailedPlan: {
          theory: [
            " Basic techniques of singing double notes",
            " Benefits of Dhattu varisai",
            " How to visualise zigzag patterns",
          ],
          practical: [
            "Janta Varisais - learning to emphasize on notes",
            "Dhattu Varisais - developing vocal flexibility",
            "Nottuswaram - composition by Dikshitar",
            "Mastering double beat Adi Talam",
            "Practising 3 speeds in Jantai and Dhattu varisai",
          ],
        },
      },
      {
        id: "435gerg",
        gradeTitle: "Carnatic Grade 4",
        duration: "3 months",
        lists: [
          "Developing vocal flexibility, agility and power through akaram training",
          "Learning how to open up the voice and avoid the nasal tone",
          "Introduction to lower and upper Octaves",
          "Improving visualization of notes and patterns",
          "A melodious Bhajan in Raga Charukesi",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Fluency with jantai and dhattu varisais",
          "Good alignment with shruti",
          "Adi Tala in 3 speeds",
        ],
        objectives: [
          "Developing vocal flexibility, agility and power through akaram training",
          "Learning how to open up the voice and avoid the nasal tone",
          "Introduction to lower and upper Octaves",
          "Improving visualization of notes and patterns",
          "A melodious Bhajan in Raga Charukesi",
        ],
        detailedPlan: {
          theory: [
            "Basics of Akaram training",
            "Introduction to Octaves",
            "Basics of vocal registers - head voice and chest voice",
            "Basics of Vowel training",
          ],
          practical: [
            "Exercise to translate any swara patterns into Akaram",
            "Jantai and Dhattu varisais in Akaram with correct vocal technique and swarasthanas",
            "Sharpening shruti alignment through dhattu visualization",
            "Mastering the 3 speeds in Akaram for vocal flexibility and agility",
            "Exercises to build resonance in lower and upper octaves",
            "Bhajan - Prabho Ganapathe in Raga Charukesi",
          ],
        },
      },
      {
        id: "ertgw43t",
        gradeTitle: "Carnatic Grade 5",
        duration: "4 months",
        lists: [
          "Learning to sing Alankarams in different Ragas",
          "Ear training to understand the 12 swarasthanas",
          "Introduction to the world of Gamakas",
          "Understanding Gamaka movement through unique exercises",
          "Upper and lower octaves training to expand vocal range",
          "Learning simple Geethams in Ragas",
          "Mohanam and Shuddha Dhanyasi",
          "Learning a unique bhajan in Raga Thilang",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Fluency with all varisais",
          "Fluency with Adi Tala in all 3 speeds",
        ],
        objectives: [
          "Learning to sing Alankarams in different Ragas",
          "Ear training to understand the 12 swarasthanas",
          "Introduction to the world of Gamakas",
          "Understanding Gamaka movement through unique exercises",
          "Upper and lower octaves training to expand vocal range",
          "Learning simple Geethams in Ragas",
          "Mohanam and Shuddha Dhanyasi",
          "Learning a unique bhajan in Raga Thilang",
        ],
        detailedPlan: {
          theory: [
            "Concept of 12 swarasthanas",
            "Introduction to Ragas Shankarabharanam, Mohanam, Shuddha Dhanyasi, Thilang",
            "Components of a Tala",
            "Basics of Gamaka",
          ],
          practical: [
            "Eka Talam & Rupaka Talam in Raga Shankarabharanam",
            "Exercises to compare Raga Mayamalavagoulai and Shankarabharanam",
            "Multi Raga alankaras in Raga Mohanam and Shuddha Dhanyasi",
            "Expanding alankaras into upper and lower octaves for vocal range development",
            "Gamakam exercises to develop flexibility and visualization",
            "Mohanam Geetham - Vara Veena",
            "Shuddha Dhanyasi Geetham - Atulita Vaibhava",
            "A melodious Bhajan in Raga Thilang",
          ],
        },
      },
      {
        id: "452wshf",
        gradeTitle: "Carnatic Grade 6",
        duration: "4 months",
        lists: [
          "Learning to sing new Alankarams in Raga Kalyani",
          "Exploring upper and lower octaves in detail and extending Akaram practice to these ranges",
          "Learning basic voice techniques for strain free singing",
          "Mastering complex talas to improve rhythm control",
          "Learning Gamakas in detail and visualising movements",
          "Learning a Geetham in Raga Kalyani and Raga Malahari",
          "Learning a melodious Bhajan in Raga Durbari Kanada",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Ability to sing basic alankaras in Raga Shankarabharanam",
          "Understanding of basic gamakas",
        ],
        objectives: [
          "Learning to sing new Alankarams in Raga Kalyani",
          "Exploring upper and lower octaves in detail and extending Akaram practice to these ranges",
          "Learning basic voice techniques for strain free singing",
          "Mastering complex talas to improve rhythm control",
          "Learning Gamakas in detail and visualising movements",
          "Learning a Geetham in Raga Kalyani and Raga Malahari",
          "Learning a melodious Bhajan in Raga Durbari Kanada",
        ],
        detailedPlan: {
          theory: [
            "Understanding Tala Angas",
            "Basics of Raga Kalyani, Malahari and Durbari Kanada",
            "Concept of octaves",
          ],
          practical: [
            "Alankaras in Triputa Talam and Matya Talam in Raga Kalyani",
            "Mastering the 3 speeds for odd numbered and even numbered Tala",
            "Kalyani Geetham - Kamala Jadala",
            "Malahari Geetham - Sri Gananatha",
            "Gamaka exercises for flexibility and Raga understanding Bhajan in Raga Durbari Kanada",
          ],
        },
      },
      {
        id: "34gwerg",
        gradeTitle: "Carnatic Grade 7",
        duration: "4 months",
        lists: [
          "Introduction to Raga Kharaharapriya",
          "Ear Training in Shankarabharanam and Kharaharapriya",
          "Exploring a complex Tala Alankaram in Dhruva Talam",
          "Mastering 3 speeds in longer Talas by improving coordination and focus",
          "Learning Geethams in Raga Mayamalavagoulai and Kamboji",
          "Gamaka exercises for flexibility and Raga understanding",
          "Learning a melodious Bhajan in Raga Kapi",
        ],
        minAge: "6 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Fluency with Alankaras 1-5",
          "Fluency with all varisais",
        ],
        objectives: [
          "Introduction to Raga Kharaharapriya",
          "Ear Training in Shankarabharanam and Kharaharapriya",
          "Exploring a complex Tala Alankaram in Dhruva Talam",
          "Mastering 3 speeds in longer Talas by improving coordination and focus",
          "Learning Geethams in Raga Mayamalavagoulai and Kamboji",
          "Gamaka exercises for flexibility and Raga understanding",
          "Learning a melodious Bhajan in Raga Kapi",
        ],
        detailedPlan: {
          theory: [
            "Introduction to Raga Kharaharapriya",
            "Introduction to Dhruva Tala angas",
            "Introduction to Raga Mayamalavagoulai and Kamboji",
          ],
          practical: [
            "Alankaram in Dhruva Talam in Raga Kharaharapriya",
            "Developing 3 speed coordination and increased focus",
            "Ear Training exercise in Shankarabharanam and Kharaharapriya",
            "Gamaka exercises in Mayamalavagoulai and Kamboji",
            "Ganapathiye tunai - Geetham in Raga Mayamalavagoulai",
            "Mandara Dhara re - Geetham in Raga Kamboji",
            "Bhajan in Raga Kapi",
          ],
        },
      },
      {
        id: "3245vtcwe",
        gradeTitle: "Carnatic Grade 8",
        duration: "6 months",
        lists: [
          "Exploring Swarajathis - Bilahari and Khamas",
          "Gamaka detailing and understanding Raga nuances",
          "Mastering 2 complex Talas - Ata & Jhumpa",
          "Multi Raga Alankaras to master the 12 swarasthanas",
          "Learning a melodious bhajan",
          "Ear Training to recognize the 12 swaras by ear",
        ],
        minAge: "8 years",
        fees: {
          inr: "13000",
          usd: "400",
        },
        prerequisites: [
          "Fluency with Multi Raga Alankarams",
          "Fluency with Geethams and basic Gamakas",
        ],
        objectives: [
          "Exploring Swarajathis - Bilahari and Khamas",
          "Gamaka detailing and understanding Raga nuances",
          "Mastering 2 complex Talas - Ata & Jhumpa",
          "Multi Raga Alankaras to master the 12 swarasthanas",
          "Learning a melodious bhajan",
          "Ear Training to recognize the 12 swaras by ear",
        ],
        detailedPlan: {
          theory: [
            "Basics of Swarajathis - Structure & Components",
            "Introduction to Raga Bilahari and Khamas",
            "Basics of Ata talam and Jhumpa Talam",
            "Visualizing Raga Chakravakam and Shanmukhapriya on a 12 note scale",
          ],
          practical: [
            "Rara Venugopa bala - Bilahari Swarajathi",
            "Samba Sivayanave - Khamas Swarajathi",
            "Alankaram - Ata talam and Jhumpa Talam",
            "Multi Raga training in Chakravakam and Shanmukhapriya",
            "Ear training exercises to recognize notes",
            "A melodious bhajan in a light Raga",
          ],
        },
      },
      {
        id: "54325ctt",
        gradeTitle: "Carnatic Grade 9",
        duration: "6 months",
        lists: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Introduction to Manodharmam",
        ],
        minAge: "8 years",
        fees: {
          inr: "13000",
          usd: "400",
        },
        prerequisites: [
          "Fluency with Multi Raga Alankarams",
          "Fluency with Geethams and Swarajathis",
        ],
        objectives: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Introduction to Manodharmam",
        ],
        detailedPlan: {
          theory: [
            "Basics of 4 Ragas - Mohanam, Hindolam, Hamsadhwani and Chakravakam",
            "Understanding different Raga structures",
          ],
          practical: [
            "Ninnukori - Mohanam Varnam",
            "Sakala kala vani - Hindolam Varnam",
            "Krithi - Parvati Patim - Raga Hamsadhwani",
            "Krithi - Gajananayutham - Raga Chakravakam",
            "Gamaka exercises in various ragas",
            "Learning to translate Krithis into swaras",
            "Manodharmam exercises in various Ragas",
          ],
        },
      },
      {
        id: "3553n4",
        gradeTitle: "Carnatic Grade 10",
        duration: "6 months",
        lists: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Exploring Manodharmam in various Ragas",
        ],
        minAge: "8 years",
        fees: {
          inr: "20000",
          usd: "600",
        },
        prerequisites: [
          "Fluency with Multi Raga Alankarams",
          "Fluency with Geethams and Swarajathis",
        ],
        objectives: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Exploring Manodharmam in various Ragas",
        ],
        detailedPlan: {
          theory: [
            "Basics of 4 Ragas - Hamsadhwani, Vasantha Arabhi and Khamas",
            "Understanding different Raga structures",
          ],
          practical: [
            "Jalajakshi - Hamsadhwani Varnam",
            "Ninnekori - Vasantha Varnam",
            "Krithi - Sree Saraswati - Raga Arabhi",
            "Krithi - Sreesha Padmanabha - Raga Khamas",
            "Gamaka exercises in various ragas",
            "Learning to translate Krithis into swaras",
            'Manodharmam exercises in various Ragas'
          ],
        },
      },{
        id: "3553n11",
        gradeTitle: "Carnatic Grade 11",
        duration: "6 months",
        lists: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Manodharmam exercises in various Ragas",
        ],
        minAge: "8 years",
        fees: {
          inr: "20000",
          usd: "600",
        },
        prerequisites: [
          "Fluency with Multi Raga Alankarams",
          "Fluency with Geethams and Swarajathis",
        ],
        objectives: [
          "Learn and master 2 new Varnams",
          "Master the 2 kalai Adi Talam",
          "Learn 2 new krithis in asymmetrical Ragas",
          "Learn to translate Krithis into swaras",
          "Gamaka exercises to improve vocal flexibility",
          "Manodharmam exercises in various Ragas",
        ],
        detailedPlan: {
          theory: [
            "Basics of 4 Ragas - Abhogi, Mayamalavagoulai, Nalinakanthi and Hindolam",
            "Understanding different Raga structures",
          ],
          practical: [
            "Evvari Bodhana - Abhogi Varnam",
            "Sarasijanabha - Mayamalavagoulai Varnam",
            "Krithi - Natajana Palini - Raga Nalinakanthi",
            "Krithi - Padmanabha Pahi - Raga Hindolam",
            "Gamaka exercises in various ragas",
            "Learning to translate Krithis into swaras",
            "Manodharmam exercises in various Ragas",
          ],
        },
      },
    ],
  },

  {
    courseName: "Voice Culture",
    description:
      "Transform your singing voice with an all round experience of breath training, vocal techniques and daily warmups. Overcome your challenge areas and get practical guidance to find your singing voice.",
    grade: [
      {
        id: "45425",
        gradeTitle: "Voice Culture Integrated Course",
        duration: "6 months",
        lists: [
          "Breathing techniques to improve performance",
          "Developing vocal stability and pitch perfection",
          "Opening up the voice through vowel training",
          "Techniques to sing higher notes without strain",
          "Developing strength and power in the voice",
          "Vocal gymnastics to develop flexibility",
          "Gliding techniques to smoothen transitions of voice",
        ],
        minAge: "8 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Basic knowledge of the 7 notes and a good sense of pitch.",
        ],
        objectives: [
          "Breathing techniques to improve performance",
          "Developing vocal stability and pitch perfection",
          "Opening up the voice through vowel training",
          "Techniques to sing higher notes without strain",
          "Developing strength and power in the voice",
          "Vocal gymnastics to develop flexibility",
          "Gliding techniques to smoothen transitions of voice",
        ],
        detailedPlan: {
          theory: [
            " Difference between normal Breathing and breathing while singing",
            " Important traits of a good singing voice",
            " Do’s and dont’s of singing posture",
            " Basics of phonation and articulation",
          ],
          practical: [
            "Exercises to observe correct breathing technique and posture",
            "Pyramid routines for pitch accuracy and breath control",
            "Vowel training to develop open throated singing",
            "Gliding techniques to smoothen the voice transitions",
            "Exercises to develop strength and weight in the voice",
            "Techniques to use the head voice comfortably for higher notes",
            "Gymnastic exercises to develop vocal flexibility",
            "Sustained notes training for stability and control",
            "Exercises to develop vocal range across lower and upper octaves",
            "Developing a daily practice checklist",
          ],
        },
      },
      {
        id: "45426",
        gradeTitle: "Advanced Voice Culture & Ear Training",
        duration: "6 months",
        lists: [
          "Techniques to enhance breath control",
          "Voice culture exercises in different Ragas/Scales",
          "Mastering all the 12 notes of music and identifying them by ear",
          "Vowel training to develop vocal flexibility",
          "Speed training to improve vocal stamina and agility",
          "Mastering the vocal transitions between chest voice and head voice",
        ],
        minAge: "8 years",
        fees: {
          inr: "9000",
          usd: "275",
        },
        prerequisites: [
          "Completion of Voice culture Level 1 and 2",
          "Good understanding of the 7 natural notes",
        ],
        objectives: [
          "Techniques to enhance breath control",
          "Voice culture exercises in different Ragas/Scales",
          "Mastering all the 12 notes of music and identifying them by ear",
          "Vowel training to develop vocal flexibility",
          "Speed training to to improve vocal stamina and agility",
          "Mastering the vocal transitions between chest voice and head voice",
        ],
        detailedPlan: {
          theory: [
            " Ear training basics",
            " Understanding variants of the natural notes",
            " Techniques of multi raga voice training",
            " Vowel training concepts ",
          ],
          practical: [
            "Breath control exercises using sustaining notes and scales",
            "Multi Raga voice training to master all the 12 notes",
            "Warmup exercises to build vocal resonance",
            "Siren exercises to smoothen vocal transitions",
            "Ear training to identify and differentiate the 12 notes",
            "Vowel training exercises for vocal flexibility",
            "Exploring faster speeds for vocal agility",
          ],
        },
      },
    ],
  },
];
