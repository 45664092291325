import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import appColors from 'theme/appColors';
import { fontWeights, fontSizes, lineHeights } from 'theme/fonts';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
// import { GOOGLE_FORM, WHATSAPP_URL } from 'api/urls/URL';
import AppText from './Typography/AppText';
import purpleArrow from '../assets/dashboard/purple-arrow.svg';
import blackArrow from '../assets/dashboard/arrow-black-left.svg';
import audioEqualizer from '../assets/dashboard/audio-equalizer.svg';
import target from '../assets/dashboard/target.svg';
import teaglass from '../assets/dashboard/tea-glass.svg';
import openBook from '../assets/dashboard/open-book.svg';
import lapTop from '../assets/dashboard/laptop.svg';
import certificationIcon from '../assets/dashboard/certification-icon.svg';
// import whatsappLogo from '../assets/dashboard/whatsapp-logo.svg';
import WebButton from './WebButton';
import useCountry from 'hooks/useCountry';

function ProgramDetailsModal({ isOpen, onClose, bgImage, data }) {
  const [secondPage, setSecondPage] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const modalSize = useBreakpointValue({ base: 'full', sm: 'xl' });
  const [currentId, setCurrentId] = React.useState('null');
  const scrollRef = React.useRef();

  const inIndia = useCountry();

console.log(data)
  return (
    <Modal
      isOpen={isOpen}
      size={modalSize}
      onClose={() => {
        setSecondPage(false);
        onClose();
      }}
      isLazy
    >
      <ModalOverlay bg="none" backdropFilter="blur(4px)" />
      <ModalContent borderRadius={{ md: '10px' }}>
        <ModalHeader p={0} ref={scrollRef}>
          {secondPage ? (
            data.grade.map((each) => {
              if (each.id === currentId) {
                return (
                  <Box padding="2em">
                    <Text
                      onClick={() => setSecondPage(false)}
                      width="fit-content"
                      cursor="pointer"
                      display="flex"
                      gap="0.5em"
                      alignItems="center"
                    >
                      <Image src={blackArrow} alt="laptop Icon" loading="lazy" />
                      Back
                    </Text>
                    <Text
                      as="h2"
                      color={appColors.brandPrimary['300']}
                      size="xl"
                      mt="0.65em"
                    >
                      {each.gradeTitle}
                    </Text>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Flex
                        gap="1.7em"
                        justifyContent="space-evenly"
                        mt="0.7em"
                      >
                        <Box>
                          <Text size="xs" color={appColors.brandGrey['500']}>
                            Minimum Age
                          </Text>
                          <Text
                            fontWeight={fontWeights.sm}
                            fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                            color={appColors.brandGrey['700']}
                          >
                            {each.minAge}
                          </Text>
                        </Box>
                        <Box>
                          <Text size="xs" color={appColors.brandGrey['500']}>
                            Duration
                          </Text>
                          <Text
                            fontWeight={fontWeights.sm}
                            fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                            color={appColors.brandGrey['700']}
                          >
                            {each.duration}
                          </Text>
                        </Box>
                        {/* {!isMobile && (
                          <Box>
                            <Text size="xs" color={appColors.brandGrey['500']}>
                              Course Fees
                            </Text>
                            <Text
                              fontWeight={fontWeights.sm}
                              fontSize={{
                                base: fontSizes.sm,
                                lg: fontSizes.md,
                              }}
                              color={appColors.brandGrey['700']}
                            >
                             {inIndia ? `₹ ${each.fees.inr}` : `$ ${each.fees.usd}`} 
                            </Text>
                          </Box>
                        )} */}
                      </Flex>
                      {!isMobile && (
                        // <Link
                        //   href={GOOGLE_FORM}
                        //   isExternal
                        //   _hover={{ underline: 'none' }}
                        // >
                        //   <Button
                        //     variant="defaultButton"
                        //     fontWeight={fontWeights.bold}
                        //     fontSize={fontSizes.sm}
                        //     _focus={{ outline: 'none' }}
                        //     display="flex"
                        //     alignItems="center"
                        //     gap="0.4em"
                        //   >
                        //     Book a class
                        //   </Button>
                        // </Link>
                        <WebButton  content='Enroll Now' onMobile='Enroll Now' />
                      )}
                    </Flex>
                  </Box>
                );
              }
              return null;
            })
          ) : (
            <Box
              h={{ base: '120px', sm: '160px', md: '150px' }}
              backgroundImage={`url(${bgImage})`}
              backgroundRepeat="no-repeat"
              backgroundPosition="top"
              borderTopRightRadius="10px"
              borderTopLeftRadius="10px"
              backgroundSize={{ base: 'contain', sm: '100%', md: 'contain' }}
            >
              <Text
                color={appColors.brandWhite['900']}
                fontWeight={fontWeights.xl}
                fontSize={{ base: fontSizes.xl, lg: fontSizes.xxl }}
                h={{base:"80%", md: "100%"}}
                display="flex"
                alignItems="flex-end"
                pb="0.25em"
                pl="0.7em"
                as="h2"
              >
                {data.courseName}
              </Text>
            </Box>
          )}
        </ModalHeader>
        <ModalCloseButton
          bg="white"
          borderRadius="50%"
          _focus={{ outline: 'none' }}
          top="2.65em"
          right="1em"
          fontSize="0.6em"
          onClick={() => setSecondPage(false)}
        />
        <ModalBody>
          {secondPage ? (
            <Tabs isLazy>
              <TabList>
                <Tab
                  _focus={{ outline: 'none' }}
                  _selected={{
                    color: appColors.brandGrey['900'],
                    borderBottom: `2px solid ${appColors.brandSecondary['400']}`,
                  }}
                  color={appColors.brandGrey['300']}
                  fontWeight={fontWeights.xl}
                  fontSize={fontSizes.sm}
                >
                  Overview
                </Tab>
                <Tab
                  _focus={{ outline: 'none' }}
                  _selected={{
                    color: appColors.brandGrey['900'],
                    borderBottom: `2px solid ${appColors.brandSecondary['400']}`,
                  }}
                  color={appColors.brandGrey['300']}
                  fontWeight={fontWeights.xl}
                  fontSize={fontSizes.sm}
                >
                  Detailed Plan
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {data.grade.map((eachObj) => {
                    if (eachObj.id === currentId) {
                      return (
                        <Box>
                          <Box>
                            <Text
                              fontWeight={fontWeights.xl}
                              fontSize={fontSizes.sm}
                              color={appColors.brandGrey['500']}
                              mb="0.5em"
                            >
                              Pre requisites
                            </Text>
                            <ul>
                              {eachObj.prerequisites.map((eachItem) => (
                                <li
                                  style={{
                                    fontWeight: fontWeights.sm,
                                    lineHeight: lineHeights.sm,
                                    color: appColors.brandGrey['700'],
                                    fontSize: fontSizes.sm,
                                  }}
                                >
                                  {eachItem}
                                </li>
                              ))}
                            </ul>
                          </Box>
                          <Box my="1em">
                            <Text
                              fontWeight={fontWeights.xl}
                              fontSize={fontSizes.sm}
                              color={appColors.brandGrey['500']}
                              mb="0.5em"
                            >
                              Course Objectives
                            </Text>
                            <ul>
                              {eachObj.objectives.map((list) => (
                                <li
                                  style={{
                                    fontWeight: fontWeights.sm,
                                    lineHeight: lineHeights.sm,
                                    color: appColors.brandGrey['700'],
                                    fontSize: fontSizes.sm,
                                  }}
                                >
                                  {list}
                                </li>
                              ))}
                            </ul>
                          </Box>
                          <Box
                            backgroundColor="rgba(255, 236, 229, 0.4)"
                            borderRadius="8px"
                            border={`1px solid ${appColors.brandPrimary['100']}`}
                            padding="1em"
                            mt="1.5em"
                          >
                            <Text
                              textTransform="uppercase"
                              fontSize={fontSizes.xs}
                              fontWeight={fontWeights.xl}
                              color={appColors.brandPrimary['300']}
                              mb="1em"
                            >
                              FORMAT & HIGHLIGHTS
                            </Text>
                            <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              mt="0.4em"
                              fontWeight={fontWeights.sm}
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={lapTop}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>Weekly live classes</strong> - weekly
                                once, in a batch of upto 5 students
                              </span>
                            </Text>
                            <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              mt="0.4em"
                              fontWeight={fontWeights.sm}
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={openBook}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>Premium eBook</strong> with detailed
                                notes, activities and challenges.
                              </span>
                            </Text>
                            <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              fontWeight={fontWeights.sm}
                              mt="0.4em"
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={audioEqualizer}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>Audiobooks</strong> for each lesson with
                                professionally recorded content
                              </span>
                            </Text>
                            {/* <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              fontWeight={fontWeights.sm}
                              mt="0.4em"
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={teaglass}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>
                                  Complimentary self-learning course -
                                </strong>{' '}
                                Universal Voice Culture by Pratibha Sarathy
                              </span>
                            </Text> */}
                            <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              fontWeight={fontWeights.sm}
                              mt="0.4em"
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={target}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>
                                  Periodic assignments and challenges
                                </strong>{' '}
                                to push your limits
                              </span>
                            </Text>
                            <Text
                              fontSize={fontSizes.sm}
                              lineHeights="26px"
                              fontWeight={fontWeights.sm}
                              mt="0.4em"
                              color={appColors.brandGrey['700']}
                              display="flex"
                              gap="0.85em"
                              alignItems="start"
                            >
                              <Image
                                src={certificationIcon}
                                alt="laptop Icon"
                                w={4}
                                mt="4px"
                                loading="lazy"
                              />
                              <span>
                                <strong>Assessment & Certification</strong> at
                                the end of the grade
                              </span>
                            </Text>
                          </Box>
                        </Box>
                      );
                    }
                    return null;
                  })}
                </TabPanel>
                <TabPanel>
                  {data.grade.map((eachObj) => {
                    if (eachObj.id === currentId) {
                      return (
                        <>
                          <Box>
                            <Text
                              fontWeight={fontWeights.xl}
                              fontSize={fontSizes.sm}
                              color={appColors.brandGrey['500']}
                              mb="0.5em"
                            >
                              Theory
                            </Text>
                            <ul>
                              {eachObj.detailedPlan.theory.map((item) => (
                                <li
                                  style={{
                                    fontWeight: fontWeights.sm,
                                    lineHeight: lineHeights.sm,
                                    color: appColors.brandGrey['700'],
                                    fontSize: fontSizes.sm,
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </Box>
                          <Box my="1em">
                            <Text
                              fontWeight={fontWeights.xl}
                              fontSize={fontSizes.sm}
                              color={appColors.brandGrey['500']}
                              mb="0.5em"
                            >
                              Practical
                            </Text>
                            <ul>
                              {eachObj.detailedPlan.practical.map((item) => (
                                <li
                                  style={{
                                    fontWeight: fontWeights.sm,
                                    lineHeight: lineHeights.sm,
                                    color: appColors.brandGrey['700'],
                                    fontSize: fontSizes.sm,
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </>
                      );
                    }
                    return null;
                  })}
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <>
              {' '}
              <AppText
                customStyles={{
                  fontSize: fontSizes.sm,
                  fontWeight: fontWeights.sm,
                  lineHeight: lineHeights.sm,
                  color: appColors.brandGrey['700'],
                  pb: '1em',
                  pt: { md: '1em' },
                }}
              >
                {data.description}
              </AppText>
              <Accordion allowToggle>
                {data.grade.map((item) => (
                  <AccordionItem>
                    {({ isExpanded }) => (
                      <>
                          <AccordionButton
                            display="flex"
                            justifyContent="space-between"
                            paddingLeft="0"
                            _focus={{ outline: 'none' }}
                          >
                            <Box>
                              <Text
                                as="h3"
                                fontWeight={{
                                  base: fontWeights.semibold,
                                  lg: fontWeights.xl,
                                }}
                                fontSize={{
                                  base: fontSizes.sm,
                                  lg: fontSizes.md,
                                }}
                                color={
                                  isExpanded
                                    ? appColors.brandGrey['700']
                                    : appColors.brandSecondary['500']
                                }
                              >
                                {item.gradeTitle}
                              </Text>
                              <Text
                                color={
                                  isExpanded
                                    ? appColors.brandGrey['700']
                                    : appColors.brandGrey['400']
                                }
                                fontWeight={fontWeights.sm}
                                fontSize={{
                                  base: fontSizes.xs,
                                  lg: fontSizes.sm,
                                }}
                                textAlign="start"
                              >
                                Duration: {item.duration}
                              </Text>
                            </Box>
                            {isExpanded ? (
                              <MinusIcon
                                fontSize="12px"
                                color={appColors.brandPrimary['300']}
                              />
                            ) : (
                              <AddIcon
                                fontSize="12px"
                                color={appColors.brandPrimary['300']}
                              />
                            )}
                          </AccordionButton>
                        <AccordionPanel>
                          <ul>
                            {item.lists.map((each) => (
                              <li
                                style={{
                                  fontWeight: fontWeights.sm,
                                  fontSize: fontSizes.xs,
                                  lineHeight: lineHeights.xs,
                                  color: appColors.brandGrey['700'],
                                }}
                              >
                                {each}
                              </li>
                            ))}
                          </ul>
                          <Text
                            color={appColors.brandSecondary['400']}
                            onClick={() => {
                              setSecondPage(true);
                              setCurrentId(item.id);
                              scrollRef.current.scrollIntoView();
                            }}
                            cursor="pointer"
                            fontWeight={fontWeights.xl}
                            fontSize={fontSizes.sm}
                            display="flex"
                            alignItems="center"
                            gap="0.5em"
                            mt="0.5em"
                            width="fit-content"
                            pos="relative"
                            right="1em"
                          >
                            All Features 
                            {/* All features & pricing */}
                            <Image
                              src={purpleArrow}
                              alt="arrow"
                              w={{ base: '0.4em', md: '0.45em' }}
                              loading="lazy"
                            />
                          </Text>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          )}
        </ModalBody>
        {!secondPage && !isMobile && (
          <Flex
            alignItems="center"
            px="2em"
            pb="1em"
            pt="0.5em"
            justifyContent="space-between"
          >
            <Text
              fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
              fontWeight={fontWeights.xl}
              color={appColors.brandGrey['700']}
            >
              Need help choosing the right program?
            </Text>
            {/* <Link
              href={GOOGLE_FORM}
              isExternal
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                variant="defaultButton"
                ml="2em"
                _focus={{ outline: 'none' }}
                display="flex"
                alignItems="center"
                gap="0.5em"
              >
                Talk to us
              </Button>
            </Link> */}
            <WebButton />
          </Flex>
        )}
        {isMobile && (
          <Flex
            px="2em"
            py="1em"
            justifyContent="flex-end"
            alignItems="center"
            boxShadow="inset 0px 1px 0px #D9D9D9"
            position="sticky"
            bottom="0"
            bg="white"
          >
            {secondPage ? (
              <>
                {/* <Box>
                  <Text size="xs" color={appColors.brandGrey['500']}>
                    Course Fees
                  </Text>

                  {data.grade.map((each) => {


                    if (each.id === currentId) {
                      return (
                        <Text
                          fontWeight={fontWeights.sm}
                          fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                          color={appColors.brandGrey['700']}
                        >
                          {inIndia ? `₹ ${each.fees.inr}` : `$ ${each.fees.usd}`} 
                        </Text>
                      );
                    }
                    return null;
                  })}
                </Box> */}
                {/* <Link
                  href={WHATSAPP_URL}
                  isExternal
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ outline: 'none' }}
                >
                  <AppButton
                    variant="defaultButton"
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.sm}
                    customStyles={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.4em',
                      _focus: { outline: 'none' },
                    }}
                  >
                    <Image src={whatsappLogo} alt="whatsapp logo" loading="lazy"/>
                    Book a class
                  </AppButton>{' '}
                </Link> */}
                <WebButton content='Enroll Now' onMobile='Enroll Now' />
              </>
            ) : (
              <>
                <Text
                  fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                  fontWeight={fontWeights.xl}
                  color={appColors.brandGrey['700']}
                  flexBasis="50%"
                >
                  Need help choosing?
                </Text>
                {/* <Link
                  href={WHATSAPP_URL}
                  isExternal
                  _hover={{ textDecoration: 'none' }}
                  _focus={{ outline: 'none' }}
                >
                  <Button
                    variant="defaultButton"
                    ml="1em"
                    flexBasis="40%"
                    display="flex"
                    alignItems="center"
                    gap="0.4em"
                    px="1em"
                    width="fit-content"
                    maxWidth="133px"
                    _focus={{ outline: 'none' }}
                  >
                    <Image src={whatsappLogo} alt="whatsapp logo" loading="lazy"/>
                    Talk to us
                  </Button>
                </Link> */}
                <WebButton />
              </>
            )}
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ProgramDetailsModal;
