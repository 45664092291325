import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';
import appColors from 'theme/appColors';
import useNavigateToCourseDetail from 'hooks/useNavigateToCourseDetail';

function CoursePageCard({ prop }) {
  const navigateTo = useNavigateToCourseDetail();
  return (
    <Box
      w={{ base: '40vw', sm: '30vw', md: '20vw', lg: '22.05vw' }}
      onClick={() =>
        navigateTo(prop.categories_data.identity , prop.identity, prop.id )
      }
      cursor="pointer"
    >
      <Box
        borderRadius="8px"
        h={{ base: '35vw', sm: '25vw', md: '17.5vw', lg: '15vw' }}
      >
        <Image
          src={prop.recommended_thumbnail_image}
          alt="course preview image"
          w="100%"
          h="100%"
          borderRadius="8px"
          objectFit="cover"
          loading="lazy"
        />
      </Box>
      <Box>
        <Text
          fontWeight={fontWeights.xl}
          fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
          lineHeight={{ base: lineHeights.xs, lg: '28px' }}
          color={appColors.brandGrey['900']}
          pt="0.75em"
          as="h3"
        >
          {prop.recommended_short_title}
        </Text>
        <Text
          fontWeight={fontWeights.sm}
          fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
          lineHeight={{ base: lineHeights.xs, lg: '24px' }}
          color={appColors.brandGrey['700']}
        >
          {prop.modules_count} modules. 
          {/* {prop.recommended_short_description} */}
        </Text>
      </Box>
    </Box>
  );
}

export default CoursePageCard;
