import React from 'react';

function useViewMoreLess() {
  const [viewMore, setViewMore] = React.useState(false);
  const handleViewMore = () => {
    setViewMore((pre) => !pre);
  };
  return { viewMore, handleViewMore };
}

export default useViewMoreLess;
