import { Box, Flex, Text } from "@chakra-ui/react";
import { headerBottomBg, headerTopBgBlog } from "assets";
import React from "react";
import appColors from "theme/appColors";
import BlogPreviewCard from "./BlogPreviewCard";

const MoreBlogs = ({ blogList, currBlodId }) => {
  const shuffleArray = (array) => {
    // create a copy of the array so that the original array is not mutated
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const convertAllBlogsToFewBlogs = (lists) => {
    const arrToShow = lists?.data;
    // remove current course
    const arrWithoutCurrentCourse = arrToShow.filter(
      (card, ind) => card?.id !== currBlodId
    );

    // shuffle the remaining
    const shuffledArr = shuffleArray(arrWithoutCurrentCourse);

    return shuffledArr.slice(0, 3);
  };

  return (
    <Box
      display={["none", "none", "block"]}
      bg={appColors.brandPrimary["50"]}
      py={"70px"}
      backgroundImage={`url(${headerTopBgBlog}), url(${headerBottomBg})`}
      backgroundRepeat="no-repeat, no-repeat"
      backgroundSize={{
        base: "389px,294px",
        md: "437px,645px",
        lg: "437px,645px",
      }}
      backgroundPosition={{
        base: "top -45vw left -214px,bottom -114% right -163px",
        sm: "top -25vw left -120px, bottom 35% right -70px",
        md: "top -9vw left -259px,bottom 38% right -430px",
        lg: "top -9vw left -259px,bottom 38% right -430px",
      }}
    >
      <Box
        width={"100%"}
        maxW="1300px"
        mx={["auto", "auto"]}
        px={["15px", "10px"]}
      >
        <Text
          color="#4D313D"
          mb="30px"
          fontSize={"38px"}
          fontWeight={700}
          textAlign={"center"}
        >
          Explore more blogs
        </Text>
        <Box>
          <Flex
            flexWrap={"wrap"}
            justifyContent={["center", "flex-start", "flex-start"]}
            gap={"40px"}
            mt="40px"
          >
            {convertAllBlogsToFewBlogs(blogList?.data)?.map((card) => (
              <BlogPreviewCard key={card?.id} cardData={card} />
            ))}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default MoreBlogs;
