import React from 'react';
import { Avatar, Box, Stack, Flex, Image, Text } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';
import useViewMoreLess from 'hooks/useViewMoreLess';
import AppText from './Typography/AppText';
import AppFlex from './chakraLayoutOverrides/AppFlex';
import apposh from '../assets/dashboard/apposh.svg';
import { dangerouslySetHtml } from './dangerouslySetHtml';

function AppTestimonial({ testimonialData }) {
  const [currentPersonId, setCurrentPersonId] = React.useState('');
  const { viewMore, handleViewMore } = useViewMoreLess();

  const arrayOftestimonialData =
    testimonialData?.data?.data.testimonial_data ||
    testimonialData?.data?.data.testimonials_data  || 
    testimonialData?.data?.data ;

  React.useEffect(() => {
    if(arrayOftestimonialData.length >=1 ){
      setCurrentPersonId(arrayOftestimonialData[0].id);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p="2em" mx={{ md: '5em', lg: '8em' }}>
      {/* {arrayOftestimonialData && ( */}
      <Flex flexDirection={{ base: 'column', md: 'row' }} gap="1.5em">
        <Stack
          direction={{ base: 'row', md: 'column' }}
          gap="0.5em"
          justifyContent={{ base: 'center', sm: 'start' }}
        >
          {arrayOftestimonialData.map((avatar) => (
            <Avatar
              name={avatar.identity}
              src={avatar.thumbnail_image}
              key={avatar.id}
              w="56px"
              h="56px"
              onClick={() => setCurrentPersonId(avatar.id)}
              cursor="pointer"
              boxShadow={
                avatar.id === currentPersonId
                  ? '0px 0px 0px 4px #F5CD54, 0px 0px 0px 8px #FDEFB1'
                  : ''
              }
            />
          ))}
        </Stack>
        {arrayOftestimonialData.map((each) => {
          if (currentPersonId === each.id) {
            return (
              <Box
                px="1.25em"
                py="1.5em"
                border="2px"
                borderStyle="solid"
                pos="relative"
                borderColor={appColors.brandPrimary['300']}
                boxShadow={`8px 8px 0px 1px ${appColors.brandPrimary['100']}`}
                w={{ md: '100%', lg: '80vw' }}
                backgroundImage={{ base: '', md: `url(${apposh})` }}
                backgroundRepeat="no-repeat"
                backgroundPosition="left 7% top 45%"
              >
                <Text
                  mb={2}
                  fontSize={{ base: fontSizes.sm, lg: '23px' }}
                  fontWeight={fontWeights.xl}
                  ml={{ md: '2em', lg: '1.2em' }}
                >
                  {each.identity}
                </Text>
                <AppFlex customStyles={{ gap: '0.5em', ml: { md: '2em' } }}>
                <AppFlex customStyles={{gap:"0.5em"}}>
                    <Flex alignItems="center" gap="0.5em">
                      <Image src={each.country_flag} alt="" w={{base:"21px",md:"28px"}} h={{base:"15px",md:"20px"}} borderRadius="3px" />
                      <AppText
                      customStyles={{
                        fontSize: { base: fontSizes.xs, lg: fontSizes.sm },
                        fontWeight: fontWeights.sm,
                        color: appColors.brandGrey['400'],
                      }}
                    >
                      {each.location}
                    </AppText>
                    </Flex>
                    <AppText
                      customStyles={{
                        fontSize: { base: fontSizes.xs, lg: fontSizes.sm },
                        fontWeight: fontWeights.sm,
                        color: appColors.brandGrey['400'],
                      }}
                    >
                      {each.short_description}
                    </AppText>
                  </AppFlex>
                </AppFlex>
                {/* {ishome ? ( */}
                <Text
                  my={2}
                  fontSize={{ base: fontSizes.xs, lg: fontSizes.md }}
                  fontWeight={fontWeights.sm}
                  lineHeight={{ base: lineHeights.xs, lg: lineHeights.md }}
                  padding={{ md: '0em 3em', lg: '0em 3em' }}
                  // padding={{ md: '1.5em 3em', lg: '1.5em 3em' }}
                  ml={{ md: '4em' }}
                  maxH={
                    viewMore
                      ? 'auto'
                      : { base: '103px', md: '80px', lg: 'initial' }
                  }
                  overflow={viewMore ? 'visible' : 'hidden'}
                  dangerouslySetInnerHTML={dangerouslySetHtml(each.description)}
                />
                {/* ) : (
                  <Text
                    my={2}
                    fontSize={{ base: fontSizes.xs, lg: fontSizes.md }}
                    fontWeight={fontWeights.sm}
                    lineHeight={{ base: lineHeights.xs, lg: lineHeights.md }}
                    padding={{ md: '0em 3em', lg: '0em 3em' }}
                    ml={{ md: '4em' }}
                    maxH={
                      viewMore
                        ? 'auto'
                        : { base: '110px', md: '60px', lg: 'initial' }
                    }
                    overflow={viewMore ? 'visible' : 'hidden'}
                  >
                    {each.description}
                  </Text>
                )} */}
                <AppText
                  customStyles={{
                    cursor: 'pointer',
                    width: 'fit-content',
                    fontWeight: fontWeights.xl,
                    fontSize: fontSizes.xs,
                    color: appColors.brandSecondary['400'],
                    ml: { md: '7em', lg: '10em' },
                    display: { lg: 'none' },
                    minW: 'fit-content',
                  }}
                  onClick={() => handleViewMore((pre) => !pre)}
                >
                  {viewMore ? 'Show Less' : 'Read More'}
                </AppText>
              </Box>
            );
          }
          return null;
        })}
      </Flex>
      {/* )} */}
    </Box>
  );
}

export default AppTestimonial;
