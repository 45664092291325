import { Box } from "@chakra-ui/react";
import { GET_WEBSITE_ABOUT_FAQ_TERMS } from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
import FaqAccordian from "components/FaqAccordian";
import WebRelatedPagesWrapper from "components/WebRelatedPagesWrapper";
import { WEBSITE_DASHBOARD } from "navigation/routes";
import React from "react";
import { useQuery } from "react-query";

function FAQ() {
  const breadcrumbdata = {
    parent: "Home",
    parentLink: WEBSITE_DASHBOARD,
    currentPage: "FAQ",
  };
  const pagedata = {
    title: "Frequently asked questions",
  };
  const { data } = useQuery(GET_WEBSITE_ABOUT_FAQ_TERMS, () =>
    getRequestWeb(GET_WEBSITE_ABOUT_FAQ_TERMS)
  );

  const faqData = data?.data?.data.faq_data;

  return (
    <WebRelatedPagesWrapper
      breadCrumbContent={breadcrumbdata}
      pageData={pagedata}
    >
      <Box
        py="2em"
        w="fit-content"
        mx="auto"
        maxW={{ md: "70vw", lg: "800px" }}
        px={{ base: "2em", md: "0" }}
      >
        {data && <FaqAccordian noTitle faqdata={faqData} />}
      </Box>
    </WebRelatedPagesWrapper>
  );
}

export default FAQ;
