import { useBreakpointValue, Link, Image } from '@chakra-ui/react';
import { GOOGLE_FORM, WHATSAPP_URL } from 'api/urls/URL';
import React from 'react';
import { fontSizes, fontWeights } from 'theme/fonts';
import AppButton from './Typography/AppButton';
import whatsappLogo from '../assets/dashboard/whatsapp-logo.svg';

function WebButton({ content = 'Talk to us', onMobile = 'Chat with us' }) {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Link
      href={isMobile ? WHATSAPP_URL : GOOGLE_FORM}
      _focus={{ outline: 'none' }}
      _hover={{ textDecoration: 'no-underline' }}
      isExternal
    >
      <AppButton
        variant="defaultButton"
        fontWeight={fontWeights.bold}
        fontSize={fontSizes.sm}
        display="flex"
        alignItems="center"
        // eslint-disable-next-line no-undef
        onClick={() => gtag_report_conversion()}
      >
        {isMobile && <Image src={whatsappLogo} alt="whatsapp logo" pr="0.35em" loading="lazy" />}
        {isMobile ? onMobile : content}
      </AppButton>
    </Link>
  );
}

export default WebButton;
