import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import appColors from 'theme/appColors';
import { fontSizes, fontWeights } from 'theme/fonts';

function PreviewWorkshopVideo({ isOpen, onClose, modalData }) {
  const size = useBreakpointValue({ base: 'full', md: 'xl' });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          zIndex="10"
          backgroundColor="#fff"
          borderRadius="50%"
        />
        <ModalBody p="0">
          <Box borderRadius="8px">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video width="" height="" style={{ borderRadius: '6px' }} autoPlay>
              <source src={modalData.workshop_clip_video} type="video/mp4" />
            </video>
          </Box>
          <Flex
            justifyContent="space-between"
            direction={{ base: 'column', md: 'row' }}
            p="0.5em"
          >
            <Box>
              <Text
                fontWeight={fontWeights.xl}
                fontSize={fontSizes.sm}
                color={appColors.brandSecondary['800']}
              >
                {modalData.identity}
              </Text>
              <Flex
                fontWeight={fontWeights.sm}
                fontSize={fontSizes.xs}
                color={appColors.brandGrey['400']}
                gap="0.5em"
              >
                <Text>By {modalData.guru_name}.</Text>
                <Text>Held on {modalData.start_date}</Text>
              </Flex>
            </Box>
            <Button mr={3} onClick={onClose} variant="defaultButton">
              View upcoming workshops
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PreviewWorkshopVideo;
