import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { fontSizes, fontWeights } from 'theme/fonts';
import appColors from 'theme/appColors';

function HightlightCard({ image, title, description }) {
  return (
    <Box
      border={`1px solid ${appColors.brandGrey['100']}`}
      borderRadius="15px"
      p="1em"
      textAlign={{ base: 'center', lg: 'initial' }}
      display={{
        base: `${title === 'Lifetime access' ? 'none' : 'initial'}`,
        lg: 'initial',
      }}
    >
      <Image
        src={`../../${image}`}
        alt=""
        mx={{ base: 'auto', lg: 'initial' }}
        mb="0.3em"
        loading="lazy"
      />
      <Text
        fontWeight={fontWeights.xl}
        fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
        color={appColors.brandGrey['800']}
      >
        {title}
      </Text>
      <Text
        color={appColors.brandGrey['500']}
        fontWeight={fontWeights.sm}
        fontSize={fontSizes.sm}
        display={{ base: 'none', lg: 'initial' }}
      >
        {description}
      </Text>
    </Box>
  );
}
export default HightlightCard;
