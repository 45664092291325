import React, { useMemo } from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  Grid,
  GridItem,
  VStack,
  Button,
  // Link,
  useDisclosure,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import AppLayout from "components/AppLayout";
import AppTestimonial from "components/AppTestimonial";
import FeaturedOn from "components/FeaturedOn";
import PreviewWorkshopVideo from "components/PreviewWorkshopVideo";
import appColors from "theme/appColors";
import AppText from "components/Typography/AppText";
import SmallSlider from "components/Slider";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import BreadCrumb from "components/BreadCrumb";
import CardComponent from "components/CardComponent";
import StoreComponent from "components/storeComponent";
import { useQuery } from "react-query";
import { GET_WEBSITE_WORKSHOP_LIST } from "api/urls";
import { getRequestWeb } from "api/utils/getRequestWeb";
import AppLoader from "components/AppLoader/AppLoader";
import workshopBg from "../../assets/dashboard/workshop-bg.svg";
import number2022 from "../../assets/dashboard/2022.svg";
import { features } from "./courseData";
import { WEBSITE_DASHBOARD } from "navigation/routes";
import MetaData from "components/MetaData";

function WorkshopPage() {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const [modalData, setModalData] = React.useState({});
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data: workshopListing, isLoading } = useQuery(
    GET_WEBSITE_WORKSHOP_LIST,
    () => getRequestWeb(GET_WEBSITE_WORKSHOP_LIST)
  );
  const sliderData = workshopListing?.data?.data.previous_workshops;

  const upcomingWorkshopCount =
    workshopListing?.data?.data?.upcoming_workshops.length;

  const festDetail = workshopListing?.data?.data?.fest_data;

  const getMonthAndDay = (param) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const getTime = param?.split("T")[0];
    const date = new Date(getTime);
    return param ? `${date.getDate()} ${monthNames[date.getMonth()]}` : "";
  };

  const startDate = useMemo(
    () => getMonthAndDay(festDetail?.workshop_fest_start_date),
    [festDetail?.workshop_fest_start_date]
  );
  const endDate = useMemo(
    () => getMonthAndDay(festDetail?.workshop_fest_end_date),
    [festDetail?.workshop_fest_end_date]
  );

  const handleOpen = (id) => {
    sliderData.map((each) => {
      if (id === each.id) {
        setModalData(each);
      }

      return null;
    });
    onOpen();
  };

  const totalRows = (totalCard) => {
    let totalRowCount;

    switch (totalCard) {
      case 1:
      case 2: {
        totalRowCount = "1";
        break;
      }
      case 3:
      case 4: {
        totalRowCount = "2";
        break;
      }
      default: {
        totalRowCount = "3";
        break;
      }
    }

    return totalRowCount;
  };

  return (
    <AppLayout>
      <MetaData
        metaTitle="Workshops"
        metaDescription="Learn music directly from the biggest maestros of music."
      />
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <>
            <Box
              backgroundImage={`url(${workshopBg})`}
              backgroundRepeat="no-repeat"
              backgroundColor={appColors.brandPrimary["50"]}
              backgroundSize={{ base: "200%", md: "100%" }}
              pos="relative"
            >
              <BreadCrumb
                parent="Home"
                parentLink={WEBSITE_DASHBOARD}
                currentPage="Workshop"
              />
              <VStack py="4.5em">
                {upcomingWorkshopCount >= 1 && (
                  <Box
                    color={appColors.brandWhite["900"]}
                    fontWeight={fontWeights.xl}
                    fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
                    p="4px 10px"
                    borderRadius="8px"
                    bg={appColors.brandPrimary["300"]}
                  >
                    {`${startDate} - ${endDate}`}
                  </Box>
                )}
                <Text
                  as="h1"
                  variant="headerOne"
                  color="black"
                  w={{ base: "70%", sm: "30%" }}
                  textAlign="center"
                  pos="relative"
                  _after={
                    upcomingWorkshopCount >= 1
                      ? {
                          // content: "''",
                          // backgroundImage: `url(${number2022})`,
                          // backgroundRepeat: 'no-repeat',
                          // backgroundSize: { base: '50%', md: 'contain' },
                          // pos: 'absolute',
                          // top: { base: '85%', md: '40%' },
                          // left: { base: '50%', md: '75%' },
                          // width: { base: '90px', md: '120px' },
                          // height: '40px',
                        }
                      : {}
                  }
                >
                  {upcomingWorkshopCount >= 1
                    ? "Margazhi Workshops"
                    : "Workshops"}

                  {upcomingWorkshopCount >= 1 ? (
                    <Text
                      as="span"
                      fontStyle="italic"
                      fontSize={{ base: "20px", md: "28px" }}
                      pos="absolute"
                      top={{ base: "20%", md: "25%" }}
                      right={{ base: "-10px", md: "5px" }}
                      transform="rotate(-10.87deg)"
                      // bgGradient='linear(to-l, #7928CA, #FF0080)'
                      // bgClip='text'
                      color={appColors.brandPrimary[300]}
                      w="120px"
                    >
                      {" "}
                      2023
                    </Text>
                  ) : null}
                </Text>

                <Text
                  fontWeight={fontWeights.sm}
                  fontSize={{ base: fontSizes.sm, lg: "23px" }}
                  lineHeight={{ base: lineHeights.sm, lg: "32px" }}
                  color={appColors.brandGrey["700"]}
                  textAlign="center"
                  w={{
                    base: "85%",
                    md: upcomingWorkshopCount >= 1 ? "initial" : "62%",
                  }}
                >
                  {upcomingWorkshopCount >= 1
                    ? festDetail.workshop_fest_detail_sub_title
                    : "Voxguru team is currently working on our next series of workshops. Please come back to this page in a bit to see the line up or download the Voxguru App to get notified."}
                </Text>
                <Flex
                  pt="2.5em"
                  justifyContent={{ base: "space-evenly", sm: "space-between" }}
                  alignItems="center"
                  w={{ base: "80%", md: "70%", lg: "60%" }}
                  flexWrap={{ base: "wrap", sm: "nowrap" }}
                  gap={{ base: "0.75em", sm: "0" }}
                >
                  {features.map((each) => (
                    <Box w={{ base: "130px", sm: "initial" }}>
                      <Image src={each.img} alt="" mx="auto" loading="lazy" />
                      <Text
                        fontWeight={{ base: "600", lg: fontWeights.sm }}
                        fontSize={{ base: "11px", lg: fontSizes.sm }}
                        color={appColors.brandGrey["900"]}
                        textAlign="center"
                      >
                        {each.title}
                      </Text>
                    </Box>
                  ))}
                </Flex>
              </VStack>
              {/* ----- card section -------- */}
              {upcomingWorkshopCount >= 1 && (
                <Grid
                  templateAreas={
                    !isLargerThan600
                      ? `"card1"
                          "card2"
                          "card3"
                          "card4"
                          "card5"
                          "card6"
                          "cta"
                          "cta"`
                      : ` "card1 card2"
                          "card3 card4"
                          "card5 card6" 
                          "cta cta"`
                  }
                  gridTemplateRows={{
                    // base: 'repeat(6, 1fr)',
                    // sm: '1fr 1fr 1fr 1fr',
                    base: `repeat(${parseInt(upcomingWorkshopCount, 10)}, 1fr)`,
                    sm: `repeat(${totalRows(upcomingWorkshopCount)}, 1fr)`,
                  }}
                  gridTemplateColumns={{ sm: "0.35fr 0.35fr" }}
                  p={5}
                  justifyContent="center"
                  alignContent="center"
                  gap="1.5em"
                  pb="4em"
                >
                  {workshopListing.data.data.upcoming_workshops.map(
                    (item, i) => (
                      <GridItem area={`card${i + 1}`} maxW="500px">
                        <CardComponent cardDetail={item} />
                      </GridItem>
                    )
                  )}
                  <GridItem
                    area="cta"
                    alignSelf="center"
                    my={{ base: "0px", sm: "2em" }}
                  >
                    <VStack spacing={2}>
                      <Text
                        fontWeight={fontWeights.xl}
                        fontSize={fontSizes.xl}
                        lineHeight="32px"
                        color={appColors.brandSecondary["700"]}
                        textAlign="center"
                      >
                        Attending more than one workshop?
                      </Text>
                      <Text
                        fontWeight={fontWeights.sm}
                        fontSize={{ base: fontSizes.xs, lg: fontSizes.md }}
                        lineHeight={{
                          base: lineHeights.xs,
                          lg: lineHeights.md,
                        }}
                        color={appColors.brandGrey["700"]}
                        textAlign="center"
                        w={{ base: "90%", md: "initial" }}
                      >
                        Save more by choosing a Season Ticket
                      </Text>
                      <a
                        href="https://forms.gle/FMpULJsk2jgznmPM6"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          variant="defaultButton"
                          fontWeight={fontWeights.bold}
                          fontSize={fontSizes.sm}
                          padding="1.5em"
                        >
                          Book your Seat
                        </Button>
                      </a>
                    </VStack>
                  </GridItem>
                </Grid>
              )}
            </Box>
            {/* ----- slider block ------- */}
            <Box
              bgColor={appColors.brandSecondary["700"]}
              p={{ base: "3em 2em", md: "5em" }}
              mb="3em"
            >
              <Box pos="relative" w="95%" mx="auto">
                <AppText
                  customStyles={{
                    color: appColors.brandWhite["900"],
                    textAlign: "center",
                    pb: { base: "0.5em", md: "2em" },
                    fontWeight: fontWeights.xl,
                    fontSize: fontSizes.xl,
                    as: "h2",
                  }}
                >
                  Previous Workshops
                </AppText>
                {workshopListing && (
                  <SmallSlider
                    sliderData={sliderData}
                    isWorkshop="isWorkshop"
                    handleOpen={handleOpen}
                  />
                )}
              </Box>
            </Box>
            {workshopListing && (
              <AppTestimonial testimonialData={workshopListing} />
            )}
            <FeaturedOn />
          </>
        )}
      </AppLoader>
      <PreviewWorkshopVideo
        isOpen={isOpen}
        onClose={onClose}
        modalData={modalData}
      />
    </AppLayout>
  );
}

export default WorkshopPage;
