import { Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

const AppText = forwardRef(
  ({ title, size, variant, children, color, customStyles, onClick }, ref) => (
    <Text
      size={size}
      ref={ref}
      variant={variant}
      color={color}
      onClick={onClick}
      {...customStyles}
    >
      {title || children}
    </Text>
  ),
);

export default AppText;
