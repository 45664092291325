import { theme as baseTheme } from '@chakra-ui/react';

const fonts = {
  heading: 'Nunito',
  body: 'Nunito',
};

const fontSizes = {
  ...baseTheme.fontSizes,
  xxl: 33,
  xl: 27,
  md: 19,
  sm: 16,
  xs: 13,
  xxs: 11,
};

const fontWeights = {
  ...baseTheme.fontWeights,
  xl: 700,
  md: 500,
  sm: 400,
};

const lineHeights = {
  ...baseTheme.lineHeights,
  xxl: '45px',
  xl: '40px',
  md: '28px',
  sm: '24px',
  xs: '20px',
  xxs: '16px',
};

export { fontSizes, fontWeights, lineHeights, fonts };
