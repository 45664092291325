import { Box, Flex, Text } from '@chakra-ui/react'
import AppLayout from 'components/AppLayout'
import React from 'react'
import appColors from 'theme/appColors'
import { fontSizes, fontWeights } from 'theme/fonts'

function PageNotFound() {
  return (
    <AppLayout>
      <Flex alignItems="center" justifyContent="center" h="60vh" >
        <Box>
          <Text fontSize={{ base: "8xl", md: "10em" }} textAlign="center" fontWeight={fontWeights.xl} color={appColors.brandPrimary['100']} m="0" p="0">404</Text>
          <Text fontSize={{ base: fontSizes.md, md: fontSizes.xl }} textAlign="center" color={appColors.brandPrimary['300']} w={{ base: "60%", md: "initial" }} mx="auto" pos="relative" bottom="1em">The page you are looking for could not be found.</Text>
        </Box>
      </Flex>
    </AppLayout>
  )
}

export default PageNotFound