import {
  Box,
  Flex,
  VStack,
  Text,
  Image,
  Button,
  useBreakpointValue,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import sliderBtn from "assets/dashboard/sliderButton.svg";
import CardComponent from "components/CardComponent";
import AppLayout from "components/AppLayout";
import StoreComponent from "components/storeComponent";
import { APPLE_STORE_LINK, PLAY_STORE_LINK } from "api/urls/URL";
import BreadCrumb from "components/BreadCrumb";
import React, { useRef, createRef } from "react";
import appColors from "theme/appColors";
import { getRequestWeb } from "api/utils/getRequestWeb";
import HightlightCard from "components/highlightCardComponent";
import { fontSizes, fontWeights, lineHeights } from "theme/fonts";
import AppLoader from "components/AppLoader/AppLoader";
import FaqAccordian from "components/FaqAccordian";
import { useQuery } from "react-query";
import { GET_WEBISE_WORKSHOP_DETAIL } from "api/urls";
import { dangerouslySetHtml } from "components/dangerouslySetHtml";
import FeaturedOn from "components/FeaturedOn";
import { useKeenSlider } from "keen-slider/react";
import { useParams } from "react-router-dom";
import { workshopDetailHighlights } from "./courseData";
import tick from "../../assets/dashboard/circle-orange-tick.svg";
// import pattern from '../../assets/dashboard/bg-pattern.svg';
import "keen-slider/keen-slider.min.css";
import mobileIcon from "../../assets/dashboard/mobile-icon.svg";
import overviewicon from "../../assets/dashboard/overview.svg";
import bookmarkicon from "../../assets/dashboard/bookmark.svg";
import whatYouLearnIcon from "../../assets/dashboard/what-you-learn.svg";
import faqicon from "../../assets/dashboard/question-circle.svg";
import { WEBSITE_DASHBOARD, WEBSITE_WORKSHOP_PAGE } from "navigation/routes";

function WorkshopDetail() {
  const [data] = workshopDetailHighlights;
  const elementRef = useRef(data.asideList.map(() => createRef()));
  const [selectedSection, setSelectedSection] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  // const sliderCount = useBreakpointValue({ base: 1, md: 3 });
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const { workshopDetailId } = useParams();
  const { data: workshopDetailData, isLoading } = useQuery(
    GET_WEBISE_WORKSHOP_DETAIL(workshopDetailId),
    () => getRequestWeb(GET_WEBISE_WORKSHOP_DETAIL(workshopDetailId))
  );
  const pageData = workshopDetailData?.data?.data;
  function scrollToSection(refElement) {
    refElement.current.scrollIntoView({ behavior: "smooth" });
  }
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: isLargerThan800 ? 2.25 : 1,
      spacing: 20,
      initial: 0,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const asideList = [
    {
      icon: overviewicon,
      name: "Overview",
    },
    // {
    //   icon: bookmarkicon,
    //   name: 'About the artist',
    // },
    {
      icon: whatYouLearnIcon,
      name: "What you will learn",
    },
    {
      icon: bookmarkicon,
      name: "Highlights",
    },
    {
      icon: faqicon,
      name: "FAQs",
    },
  ];
  console.log(workshopDetailData);
  console.log(
    "currentSlide",
    currentSlide,
    pageData?.more_concept_to_hop?.length
  );

  return (
    <AppLayout>
      <AppLoader isLoading={isLoading}>
        {!isLoading && (
          <Box pos="relative">
            <BreadCrumb
              Grandparent="Home"
              GrandparentLink={WEBSITE_DASHBOARD}
              parent="Workshop"
              parentLink={WEBSITE_WORKSHOP_PAGE}
              currentPage="Workshop Detail"
            />
            <Flex
              as="header"
              pt={{ base: "4em", md: "7em" }}
              pb={{ base: "2em", md: "5em" }}
              backgroundColor={appColors.brandPrimary["50"]}
              //   backgroundImage={`url(${pattern})`}
              //   backgroundRepeat="no-repeat"
              //   backgroundPosition={{ base: 'bottom 0 right 0', md: 'right' }}
              //   direction={{ base: 'column', sm: 'row' }}
              //   backgroundSize={{
              //     base: '75vw',
              //     sm: '50vw',
              //     md: '50vw',
              //     lg: 'initial',
              //   }}
              h="600px"
            >
              <VStack
                flexBasis={{ base: "100%", md: "50%" }}
                px="2em"
                alignItems="flex-start"
                gap="1em"
              >
                <Text
                  as="h1"
                  variant="headerTwo"
                  w={{ base: "100%", lg: "80%" }}
                >
                  {pageData?.workshop_data?.identity}
                </Text>
                <Text
                  fontWeight="700"
                  fontSize={fontSizes.md}
                  color={appColors.brandSecondary["700"]}
                >
                  By {pageData?.workshop_data?.guru_name}
                </Text>
                <Box>
                  <Flex gap="1em">
                    <Box
                      bg="#A99206"
                      color={appColors.brandWhite["900"]}
                      fontSize={fontSizes.sm}
                      fontWeight={fontWeights.xl}
                      borderRadius="8px"
                      p="0.25em 0.5em"
                    >
                      {pageData?.workshop_data?.start_date}
                    </Box>
                    <Text
                      color={appColors.brandPrimary["300"]}
                      fontWeight={fontWeights.xl}
                      fontSize={fontSizes.md}
                    >
                      {pageData?.workshop_data?.hour_duration_line}
                    </Text>
                  </Flex>
                </Box>
                <Flex flexDirection={{ base: "column", md: "row" }} gap="1em">
                  {/* <Text
                    color={appColors.brandGrey["900"]}
                    fontWeight={fontWeights.xl}
                    fontSize={{ base: "23px", lg: "27px" }}
                  >
                    ₹ {pageData?.workshop_data?.price}
                    <span
                      style={{
                        fontWeight: fontWeights.sm,
                        fontSize: fontSizes.md,
                      }}
                    >
                      / seat
                    </span>
                  </Text> */}
                  <a
                    href="https://forms.gle/FMpULJsk2jgznmPM6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="defaultButton"
                      fontWeight={fontWeights.bold}
                      fontSize={fontSizes.sm}
                      padding="1.5em"
                    >
                      Book your Seat
                    </Button>
                  </a>
                </Flex>
                {/* <Flex gap="0.5em">
                  <Box flexBasis={{ base: "10%", lg: "5%" }}>
                    <Image src={mobileIcon} alt="" loading="lazy" />
                  </Box>
                  <Text
                    flexBasis={{ base: "100%", lg: "90%" }}
                    display="block"
                    fontSize={{ base: "11px", md: fontSizes.sm }}
                    fontWeight={fontWeights.sm}
                    color={appColors.brandGrey["700"]}
                    lineHeight={{ base: "16px", md: "24px" }}
                  >
                    {" "}
                    Download the Voxguru App for
                    <Link
                      href={APPLE_STORE_LINK}
                      _hover={{ textDecoration: "none" }}
                      _focus={{ outline: "none" }}
                    >
                      <span
                        style={{
                          color: appColors.brandSecondary["500"],
                          paddingInline: "0.25em",
                          textDecoration: "underline",
                        }}
                      >
                        iOS
                      </span>
                    </Link>
                    or{" "}
                    <Link
                      href={PLAY_STORE_LINK}
                      _hover={{ textDecoration: "none" }}
                      _focus={{ outline: "none" }}
                    >
                      <span
                        style={{
                          color: appColors.brandSecondary["500"],
                          paddingInline: "0.25em",
                          textDecoration: "underline",
                        }}
                      >
                        Android
                      </span>
                    </Link>
                    to get a season pass and make the most of our
                    <Link
                      href="/home/workshop"
                      _hover={{ textDecoration: "none" }}
                      _focus={{ outline: "none" }}
                    >
                      <span
                        style={{
                          color: appColors.brandSecondary["500"],
                          paddingInline: "0.25em",
                        }}
                      >
                        current workshops.
                      </span>
                    </Link>
                  </Text>
                </Flex> */}
              </VStack>
              {/* <Box flexBasis="50%" mt={{ base: '2em', md: '0' }}>
            <Box
              w={{ base: '70%', lg: '80%' }}
              maxW="660px"
              h={{ base: '180px', lg: '403px' }}
              bg="lightpink"
              borderRadius="12px"
              mx="auto"
            />
          </Box> */}
            </Flex>
            <Flex px="2em">
              <Box
                as="aside"
                flexBasis="20%"
                borderRight="1px solid rgba(0, 0, 0, 0.08)"
                display={{ base: "none", lg: "initial" }}
                minW="20vw"
              >
                <Box pos="sticky" top="1em" pt="5em" pb="4em">
                  {asideList.map((each, i) => (
                    <Flex
                      gap="0.5em"
                      mb="0.5em"
                      borderRight={
                        selectedSection === i
                          ? `3px solid ${appColors.brandPrimary["300"]}`
                          : ""
                      }
                    >
                      <Image src={each.icon} alt="" loading="lazy" />
                      <Text
                        cursor="pointer"
                        fontWeight={
                          selectedSection === i
                            ? fontWeights.xl
                            : fontWeights.sm
                        }
                        fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
                        color={
                          selectedSection === i
                            ? appColors.brandSecondary["700"]
                            : appColors.brandGrey["400"]
                        }
                        onClick={() => {
                          scrollToSection(elementRef.current[i]);
                          setSelectedSection(i);
                        }}
                      >
                        {each.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              </Box>
              <Box
                as="main"
                flexBasis={{ base: "100vw", lg: "80vw" }}
                pt="5em"
                maxW="100vw"
              >
                <VStack
                  gap="4em"
                  w={{ base: "100%", md: "85%", lg: "65%" }}
                  mx="auto"
                  alignItems="flex-start"
                >
                  <Box ref={elementRef.current[0]}>
                    <Text
                      variant="descriptionText"
                      dangerouslySetInnerHTML={dangerouslySetHtml(
                        pageData?.workshop_data?.content
                      )}
                    />
                  </Box>
                  {/* <Box>
                    <Flex
                      alignItems="center"
                      gap="1em"
                      ref={elementRef.current[1]}
                    >
                      <Image src={pageData.workshop_data.guru_thumbnail_image} alt="" loading="lazy" w={{  base:"72px", sm:"120px"}} h={{ base:"72px", sm:"120px"}} borderRadius="50%" />
                      <Box>
                        <Text size="xl" color={appColors.brandSecondary['700']}>
                          About your artist
                        </Text>
                        <Text
                          fontWeight={fontWeights.xl}
                          fontSize={fontSizes.sm}
                          color={appColors.brandPrimary['300']}
                        >
                          {pageData?.workshop_data?.guru_name}
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                       variant='descriptionText'
                      color={appColors.brandGrey['700']}
                      mt="1em"
                      pb="4em"
                    >
                      {pageData?.workshop_data?.guru_short_description}
                    </Text>
                  </Box> */}
                  <Box ref={elementRef.current[1]}>
                    <Text
                      size="xl"
                      color={appColors.brandSecondary["700"]}
                      mb="0.5em"
                    >
                      What you will learn
                    </Text>
                    {pageData.workshop_data?.what_you_will_learn
                      .split(";")
                      .map((each) => (
                        <Flex alignItems="center" gap="0.5em" mt="0.5em">
                          <Image src={tick} alt="tick" loading="lazy" />
                          <Text
                            color={appColors.brandGrey["700"]}
                            fontWeight={fontWeights.sm}
                            fontSize={fontSizes.sm}
                          >
                            {each}
                          </Text>
                        </Flex>
                      ))}
                  </Box>
                  <Box
                    w={{ base: "100%", lg: "initial" }}
                    ref={elementRef.current[2]}
                  >
                    <Text size="xl" color={appColors.brandSecondary["700"]}>
                      Workshop Highlights
                    </Text>
                    <Box
                      display="grid"
                      gridTemplateColumns={{
                        base: "1fr 1fr",
                        md: "1fr 1fr 1fr 1fr",
                        lg: "1fr 1fr",
                      }}
                      gap={{ base: "0.5em", lg: "1em" }}
                      my="1em"
                    >
                      {data?.highlights.map((each) => (
                        <HightlightCard
                          image={each.img}
                          title={each.title}
                          description={each.description}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box
                    bg={appColors.brandPrimary["50"]}
                    w={{ base: "115%", md: "120%" }}
                    borderRadius="12px"
                    pos="relative"
                    // right={{ md: '5em' }}
                    right="2em"
                    p="2em"
                  >
                    {pageData?.more_concept_to_hop?.length >= 1 && (
                      <>
                        <>
                          <Box
                            pos="absolute"
                            right={{ base: "0.5em", md: "-1.75em" }}
                            top={{ base: "43%", md: "40%" }}
                            zIndex="2"
                            cursor="pointer"
                            onClick={(e) =>
                              e.stopPropagation() || instanceRef.current?.next()
                            }
                            borderRadius="55%"
                            backgroundColor={appColors.brandPrimary["50"]}
                            w={{ base: "2em", sm: "2.5em", lg: "initial" }}
                          >
                            <Image
                              src={sliderBtn}
                              alt="slider-btn"
                              opacity={{
                                base:
                                  currentSlide ===
                                  pageData?.more_concept_to_hop?.length - 1
                                    ? "0.4"
                                    : "",
                                md:
                                  currentSlide ===
                                  pageData?.more_concept_to_hop?.length - 2
                                    ? "0.4"
                                    : "",
                                lg:
                                  currentSlide ===
                                  pageData?.more_concept_to_hop?.length - 5
                                    ? "0.4"
                                    : "",
                              }}
                              w="100%"
                              loading="lazy"
                            />
                          </Box>
                          <Box
                            pos="absolute"
                            left={{ base: "0.5em", md: "-1.75em" }}
                            top={{ base: "43%", md: "40%" }}
                            zIndex="2"
                            cursor="pointer"
                            onClick={(e) =>
                              e.stopPropagation() || instanceRef.current?.prev()
                            }
                            borderRadius="55%"
                            backgroundColor={appColors.brandPrimary["50"]}
                            w={{ base: "2em", sm: "2.5em", lg: "initial" }}
                          >
                            <Image
                              src={sliderBtn}
                              alt="slider-btn"
                              style={{ transform: "rotate(180deg)" }}
                              opacity={currentSlide === 0 ? "0.4" : ""}
                              w="100%"
                              loading="lazy"
                            />
                          </Box>
                        </>
                        <Text
                          mb="1em"
                          size="xl"
                          color={appColors.brandSecondary["700"]}
                          textAlign="center"
                        >
                          More workshops to hop!
                        </Text>
                        <Box
                          ref={sliderRef}
                          className="keen-slider"
                          w="120%"
                          maxW="70vw"
                          margin="auto"
                        >
                          {pageData?.more_concept_to_hop?.map((each, i) => (
                            <Box
                              className={`keen-slider__slide number-slide${
                                i + 1
                              }`}
                            >
                              <CardComponent cardDetail={each} noPrice />
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                    <Text mt="1em" variant="descriptionText" textAlign="center">
                      Save more by choosing a Season Ticket
                    </Text>
                    <Box mx="auto" mt="5px" w="fit-content">
                      {/* <StoreComponent /> */}
                      <a
                        href="https://forms.gle/FMpULJsk2jgznmPM6"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          variant="defaultButton"
                          fontWeight={fontWeights.bold}
                          fontSize={fontSizes.sm}
                          padding="1.5em"
                        >
                          Book your Seat
                        </Button>
                      </a>
                    </Box>
                  </Box>
                  <Box ref={elementRef.current[3]}>
                    {workshopDetailData && (
                      <FaqAccordian
                        faqdata={
                          pageData.workshop_data.frequently_asked_questions
                        }
                      />
                    )}
                  </Box>
                </VStack>
              </Box>
            </Flex>
            <FeaturedOn />
          </Box>
        )}
      </AppLoader>
    </AppLayout>
  );
}

export default WorkshopDetail;
