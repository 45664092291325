import { Flex, useStyleConfig } from '@chakra-ui/react';

function AppFlex({ size, variant, customStyles, onClick, children }) {
  const styles = useStyleConfig('AppFlex', { size, variant });
  return (
    <Flex sx={styles} onClick={onClick} {...customStyles}>
      {children}
    </Flex>
  );
}
export default AppFlex;
