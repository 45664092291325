// -------------- Courses ------------

// Get courses list
export const GET_WEBSITE_COURSES_LIST = '/api/website/course/all/';
// indivdual course detail
export const GET_WEBSTE_COURSE_DETAIL = (slug) =>
  `/api/website/course/${slug}/view-in-detail/`;
// get course list based on categories
export const GET_WEBSITE_COURSES_CATEGORY_LIST = (slug) =>
  // `/api/website/course/all/?categories=${slug}`;
  `/api/website/course-categories/${slug}/all/`;
// get videos for home page
export const GET_SLIDER_VIDEOS = '/api/website/home-page/footer/videos/all/';
// make enrollment
export const POST_ENROLLMENT_DETAIL = '/api/website/course-enrollment/';
// get Faq, about , privacy terms data
export const GET_WEBSITE_ABOUT_FAQ_TERMS = '/api/website/about-faq-terms/all/';
// send email
export const POST_WEBSITE_SEND_MAIL = '/api/website/contact-us/';
// get workshop page list
export const GET_WEBSITE_WORKSHOP_LIST = '/api/website/workshop/all/';
// get workshop detail page data
export const GET_WEBISE_WORKSHOP_DETAIL = (slug) =>
  `/api/website/workshop/${slug}/view-in-detail/`;
//get live class data
export const GET_WEBSITE_LIVECLASS_DATA = `/api/website/live-class/all/`;
export const GET_BLOG_LIST = (category) => `/api/blogs/?category__slug=${category}`;
export const GET_BLOG_CATEGORIES = `/api/blogs/categories/`;
export const GET_DETAILED_BLOG = (slug) => `/api/blogs/${slug}/view-in-detail/`;
