import {
  Box,
  FormLabel,
  Input,
  Text,
  Flex,
  Button,
  Image,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { POST_WEBSITE_SEND_MAIL } from "api/urls";
import { postRequestWeb } from "api/utils/postRequestWeb";
import WebRelatedPagesWrapper from "components/WebRelatedPagesWrapper";
import { WEBSITE_DASHBOARD } from "navigation/routes";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import appColors from "theme/appColors";
import { fontSizes, fontWeights } from "theme/fonts";
import mapIcon from "../../assets/dashboard/map-icon.svg";
import purpleArrow from "../../assets/dashboard/purple-arrow.svg";

function Contact() {
  const [response, setResponse] = React.useState({});
  const [values, setValues] = React.useState({
    identity: "",
    email: "",
    subject: "",
    message: "",
  });
  const breadcrumbdata = {
    parent: "Home",
    parentLink: WEBSITE_DASHBOARD,
    currentPage: "Contact",
  };
  const pagedata = {
    title: "Contact Us",
    description:
      "18/1 Ground Floor, Bhakthavachalam Nagar, Adyar, Chennai - 600020",
    buttonData: {
      name: "View on Google Map",
      img: mapIcon,
    },
  };
  const navigate = useNavigate();
  const { mutate } = useMutation(
    POST_WEBSITE_SEND_MAIL,
    (body) => postRequestWeb(POST_WEBSITE_SEND_MAIL, body),
    {
      onSuccess: (data) => {
        setResponse(data.data);
      },
      onError: (data) => {
        setResponse(data.response.data.data);
      },
    }
  );

  const handleInputChange = (e) => {
    setResponse({});
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const postObj = {
    identity: values.identity,
    email: values.email,
    subject: values.subject,
    your_message: values.message,
  };

  const isSuccess = Object.prototype.hasOwnProperty.call(response, "data");

  return (
    <WebRelatedPagesWrapper
      breadCrumbContent={breadcrumbdata}
      pageData={pagedata}
      moreHeight
    >
      {!isSuccess ? (
        <Box
          maxW={{ md: "70vw", lg: "800px" }}
          px={{ base: "2em", md: "0" }}
          mx="auto"
          py="2.5em"
        >
          <Text
            fontSize={fontSizes.xl}
            color={appColors.brandGrey["700"]}
            fontWeight={fontWeights.xl}
          >
            Of course, we are good listners!
          </Text>
          <Text
            fontSize={fontSizes.sm}
            color={appColors.brandGrey["700"]}
            fontWeight={fontWeights.sm}
          >
            Please share your feedbacks, questions to us using the foem below.
            We will ghet back shortly.
          </Text>
          <Flex gap="1em" pt="1em">
            <Box
              flexBasis="50%"
              fontSize={fontSizes.xs}
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.xl}
            >
              <FormControl isInvalid={response?.identity}>
                <FormLabel>Name</FormLabel>
                <Input
                  onChange={handleInputChange}
                  type="text"
                  name="identity"
                  label="identity"
                  value={values.identity}
                />
                <FormErrorMessage>{response?.identity}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box
              flexBasis="50%"
              fontSize={fontSizes.xs}
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.xl}
            >
              <FormControl isInvalid={response?.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  label="email"
                  value={values.email}
                />
                <FormErrorMessage>{response?.email}</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
          <Box
            pt="1em"
            fontSize={fontSizes.xs}
            color={appColors.brandGrey["700"]}
            fontWeight={fontWeights.xl}
          >
            <FormControl isInvalid={response?.subject}>
              <FormLabel>Subject</FormLabel>

              <Input
                onChange={handleInputChange}
                type="text"
                name="subject"
                label="subject"
                value={values.subject}
              />
              <FormErrorMessage>{response?.subject}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            pt="1em"
            fontSize={fontSizes.xs}
            color={appColors.brandGrey["700"]}
            fontWeight={fontWeights.xl}
          >
            <FormControl isInvalid={response?.your_message}>
              <Text>Your Message</Text>
              <textarea
                onChange={handleInputChange}
                rows="6"
                name="message"
                label="message"
                value={values.your_message}
                style={{
                  border: response.your_message
                    ? "2px solid #E53E3E"
                    : `1px solid ${appColors.brandSecondary["200"]}`,
                  borderRadius: "8px",
                  width: "100%",
                }}
              />
              <FormErrorMessage>{response?.your_message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Button
            variant="defaultButton"
            mt="1em"
            onClick={() => mutate(postObj)}
          >
            Send Message
          </Button>
        </Box>
      ) : (
        <Box>
          <Box
            maxW={{ md: "70vw", lg: "800px" }}
            px={{ base: "2em", md: "0" }}
            mx="auto"
            py="2.5em"
          >
            <Text
              fontSize={fontSizes.xl}
              color={appColors.brandGrey["700"]}
              fontWeight={fontWeights.xl}
            >
              Of course, we are good listners!
            </Text>
            <Box backgroundColor=" #FDEFB1" padding="1em" borderRadius="10px">
              <Text
                fontWeight={fontWeights.sm}
                fontSize={fontSizes.sm}
                color={appColors.brandGrey["700"]}
              >
                Thank you for your message. We will get back to you as soon as
                possible.
              </Text>
            </Box>

            <Flex gap="1em" mt="1.5em">
              <Button
                variant="exploreButton"
                style={{
                  display: "flex",
                  gap: "0.8em",
                  alignItems: "center",
                }}
                _focus={{ outline: "none", textDecoration: "none" }}
                onClick={() => navigate("/home")}
              >
                <Image
                  src={purpleArrow}
                  alt="arrow"
                  w={{ base: "0.4em", md: "0.45em" }}
                  style={{ transform: "rotate(180deg)" }}
                />
                Back to home
              </Button>
              <Button
                variant="exploreButton"
                onClick={() => {
                  setResponse({});
                  setValues({
                    identity: "",
                    email: "",
                    subject: "",
                    message: "",
                  });
                }}
              >
                Post another message
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </WebRelatedPagesWrapper>
  );
}
export default Contact;
