import React from 'react';
import { Text, Box, Flex, Spacer, Image } from '@chakra-ui/react';
import { fontSizes, fontWeights, lineHeights } from 'theme/fonts';
import appColors from 'theme/appColors';
import { useNavigate } from 'react-router-dom';
import { MAKE_WEBSITE_WORKSHOP_DETAIL } from 'navigation/routes';
import badgeMasterclass from '../assets/dashboard/badge-masterclass.svg';
import { getPrice } from 'utils/helperFunctions';
import useCountry from 'hooks/useCountry';

function CardComponent({ cardDetail, noPrice }) {
  const navigate = useNavigate();
  const { isIndia } = useCountry();
  
  return (
    <Box
      bg="white"
      w="100%"
      h="100%"
      borderRadius="16px"
      pos="relative"
      cursor="pointer"
      onClick={() => navigate(MAKE_WEBSITE_WORKSHOP_DETAIL(cardDetail.id))}
    >
      <Image
        src={cardDetail.up_coming_workshop_thumbnail_image}
        alt="course preview banner"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        loading="lazy"
      />
      <Box p="1em 1.5em" pos="relative">
        <Box py="0.5em">
          <Text
            fontWeight={fontWeights.xl}
            color={appColors.brandGrey['900']}
            fontSize={{ base: fontSizes.md, lg: '23px' }}
            lineHeight={{ base: lineHeights.md, lg: '32px' }}
          >
            {cardDetail.identity}
          </Text>
          <Text
            fontWeight={fontWeights.sm}
            color={appColors.brandGrey['700']}
            fontSize={{ base: fontSizes.sm, lg: fontSizes.md }}
          >
            By {cardDetail.guru_name}
          </Text>
        </Box>
        <Flex pt="0.5em">
          <Box>
            <Text
              fontWeight={fontWeights.xl}
              color={appColors.brandGrey['900']}
              fontSize={{ base: fontSizes.xs, lg: fontSizes.sm }}
            >
              {cardDetail.hour_duration_line}
            </Text>
            <Text
              fontWeight={{ base: fontWeights.xl, lg: fontWeights.sm }}
              color={appColors.brandGrey['900']}
              fontSize={fontSizes.xs}
            >
              {cardDetail.session_info_line}
            </Text>
          </Box>
          <Spacer />
          {/* --- temporarly hide price --- */}
          {/* {!noPrice && (
            <Box>
              <Text
                fontWeight={fontWeights.sm}
                fontSize={fontSizes.xs}
                color={appColors.brandGrey['400']}
              >
                Price / seat
              </Text>
              <Text
                fontWeight={fontWeights.xl}
                color={appColors.brandGrey['900']}
                fontSize={{ base: fontSizes.sm, lg: '23px' }}
              >
                {getPrice(isIndia, cardDetail.price, "")}
              </Text>
            </Box>
          )} */}
        </Flex>
        <Box
          pos="absolute"
          top="-4"
          bg="#A99206"
          color={appColors.brandWhite['900']}
          fontSize={fontSizes.sm}
          fontWeight={fontWeights.xl}
          borderRadius="8px"
          p="0.25em 0.5em"
        >
          {cardDetail.start_date}
        </Box>
      </Box>
      {cardDetail.isMasterClass && (
        <Box
          pos="absolute"
          top="2.5em"
          left="2.5em"
          bg={appColors.brandWhite['900']}
          textTransform="uppercase"
          color={appColors.brandGrey['900']}
          fontWeight="800"
          fontSize="calc(0.1vw + 0.5em)"
          letterSpacing="0.05em"
          borderRadius="3px"
          padding="0.7em"
          display={{ base: 'none', md: 'flex' }}
          alignItems="center"
          justifyContent="center"
          gap="0.8em"
          w="fit-content"
        >
          <Image src={badgeMasterclass} alt="badge" />
          MASTERCLASS
        </Box>
      )}
    </Box>
  );
}

export default CardComponent;
