import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { GoPrimitiveDot } from "react-icons/go";

const BlogMetaContainer = ({ blogData }) => {
  const getProperDate = (rawDate) => {
    const date = new Date(rawDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  return (
    <Flex gap={"14px"}>
      <Avatar
        src={blogData?.author_profile_image}
        height={"48px"}
        width="48px"
      />
      <Box fontWeight={500}>
        <Flex gap={"6px"} color={"#0D0D0D"}>
          by
          <Text fontWeight={700} fontSize={"16px"}>
            {blogData?.author}
          </Text>{" "}
          in
          <Text fontWeight={700} fontSize={"16px"}>
            {blogData?.category?.identity}
          </Text>
        </Flex>
        <Flex color={"#404040"}>
          <Text>{getProperDate(blogData?.created)}</Text>
          <Flex justifyContent={"center"} alignItems={"center"} mx="10px">
            <Box>
              <GoPrimitiveDot style={{ fontSize: "12px" }} />
            </Box>
          </Flex>
          <Text
            textTransform={"lowercase"}
          >{`${blogData?.reading_time} read`}</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default BlogMetaContainer;
