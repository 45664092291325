import appColors from 'theme/appColors';
import { appBorderRadius } from 'theme/sizes';

const AppFlexStyles = {
  variants: {

  },
};

export default AppFlexStyles;
