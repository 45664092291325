import React from 'react';
import appColors from 'theme/appColors';
import { Flex, Link, Image, Text } from '@chakra-ui/react';
import { APPLE_STORE_LINK, PLAY_STORE_LINK } from 'api/urls/URL';
import appleicon from '../assets/dashboard/appleicon.svg';
import playstoreicon from '../assets/dashboard/playstoreicon.svg';

function Linktostore({ orangeColor }) {
  return (
    <Flex gap={{ base: '0em', md: '1em' }}>
      <Flex
        gap={{ base: '0.5em', lg: '1em' }}
        flexBasis={{ base: '20%', lg: '10%' }}
      >
        <Image src={appleicon} alt="apple icon" w="24px" loading="lazy" />
        <Image src={playstoreicon} alt="playstore icon" w="24px" loading="lazy" />
      </Flex>

      <Text flexBasis={{ base: '80%', lg: '90%' }} display="block">
        {' '}
        Or, download the Voxguru App on your
        <Link
          href={APPLE_STORE_LINK}
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
          isExternal
        >
          <span
            style={{
              color: orangeColor
                ? appColors.brandPrimary['300']
                : appColors.brandSecondary['500'],
              paddingInline: '0.25em',
              textDecoration: 'underline',
            }}
          >
            iOS
          </span>
        </Link>
        or{' '}
        <Link
          href={PLAY_STORE_LINK}
          _hover={{ textDecoration: 'none' }}
          _focus={{ outline: 'none' }}
          isExternal
        >
          <span
            style={{
              color: orangeColor
                ? appColors.brandPrimary['300']
                : appColors.brandSecondary['500'],
              paddingInline: '0.25em',
              textDecoration: 'underline',
            }}
          >
            Android
          </span>
        </Link>
        device.
      </Text>
    </Flex>
  );
}

export default Linktostore;
