import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import appColors from 'theme/appColors';
import { fontSizes, fonts, lineHeights, fontWeights } from 'theme/fonts';
import components from 'theme/components';

const theme = extendTheme({
  colors: appColors,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  baseTheme,
  components,
});

export default theme;
